import React from 'react';

import { InfoTip } from 'workmotion-design-system';

import { en } from 'localization';

import {
  MainSection,
  ProfileBodyContainer,
  SideSection,
} from './styled-components';
import { VirtualAccountInfoCard } from './virtual-accountInfo-card';
import WalletBalanceCard from './wallet-balance-card';
import { FeedYourWalletBanner } from '../payment-banner/feed-your-wallet-banner';

const {
  contractor: {
    paymentProfile: {
      customerPaymentProfile: {
        infoTipSection: { title, text },
      },
    },
  },
} = en;

export const CustomerPaymentProfile = () => (
  <ProfileBodyContainer data-testid="customer-payment-profile">
    <MainSection>
      <WalletBalanceCard />

      <FeedYourWalletBanner />

      <InfoTip tipType="info" title={title} text={text} />
    </MainSection>

    <SideSection>
      <VirtualAccountInfoCard />
    </SideSection>
  </ProfileBodyContainer>
);
