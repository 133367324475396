import { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const TableHeaderContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5rem',
  background: palette.secondary.white,
  borderRadius: '0.5rem 0.5rem 0 0',
  zIndex: 1,
  '&[data-with-contractors-search="false"]': {
    justifyContent: 'flex-end',
  },
});

export const FiltersAndActions = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const avatarStyles: SxProps = {
  color: palette.semantic.persianGreen,
  height: '2rem',
  width: '2rem',
  fontSize: '0.75rem',
  lineHeight: '0.875rem',
  backgroundColor: palette.secondary.softMint,
};

export const ContractorListField = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const SideMenuContainer = styled.div({
  padding: '0 2rem 2rem 2rem',
});

export const StyledInfoBox = styled.div({
  padding: '1rem 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  fontSize: '.875rem',
  lineHeight: '1.375rem',
  color: palette.greyscale.UCLABlue,
  borderBottom: `1px solid ${[palette.greyscale.antiFlashWhite]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
  'p, ol': {
    margin: '0px !important',
  },
  ol: {
    paddingLeft: '1rem !important',
  },
});

export const HeaderCellWithIcon = styled.div({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
});

export const PreviewPaymentTypeLabel = styled.b({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '0.875rem',
});

export const filtersSelectStyles: SxProps = {
  lineHeight: '0.875rem',
  fontSize: '0.75rem',
  fontFamily: 'Work Sans',
  color: palette.greyscale.persianBlue,
  padding: '0.5rem 0 0 1rem',
  borderRadius: '0.75rem',
};

export const actionMenuItemStyles: SxProps = {
  fontSize: '0.75rem',
  fontFamily: 'Work Sans',
  color: palette.greyscale.UCLABlue,
  padding: '.68rem 1rem',
};

export const actionMenuItemIconStyles = {
  marginInlineEnd: '0.5rem',
  color: palette.greyscale.UCLABlue,
  fontSize: '1rem',
};

export const FormContainer = styled.div({
  marginTop: '2rem',
});

export const TwoFieldInOneRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',

  '& > *': {
    width: '48%',
  },
});

export const radioGroupLabelStyle: SxProps = {
  '& .MuiFormControlLabel-root': {
    width: '48.4%',
    marginBottom: '0 !important',

    '@media (max-width: 480px) ': {
      width: '100%',
      marginBottom: '0.75rem !important',
    },
  },
};

export const MilestoneHeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
});

export const MilestoneHeader = styled.h3({
  fontSize: '0.875rem',
  fontWeight: 600,
  fontFamily: 'Work Sans',
  color: palette.primary.genericViridian,
});

export const DeleteMilestone = styled.div({
  height: '2.5rem',
  width: '2.5rem',
  borderRadius: '.5rem',
  backgroundColor: palette.semantic.fairPink,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

export const AddMilestoneButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: palette.greyscale.UCLABlue,
  cursor: 'pointer',
  marginBottom: '2rem',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: 500,
});

export const ContractorCardContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  borderRadius: '0.5rem',
  width: '100%',
  backgroundColor: palette.greyscale.ghostWhite,
  border: `1px solid ${palette.greyscale.antiFlashWhite}`,
  marginTop: '2rem',

  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
  },
});

export const ContractorInfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  h2: {
    fontSize: '1rem',
    fontWeight: 600,
    color: palette.primary.genericViridian,
    marginBottom: '0.5rem',
  },
  p: {
    fontSize: '0.75rem',
    color: palette.greyscale.UCLABlue,
  },
});

export const DetailsModalField = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&[data-status-field="true"]': {
    marginTop: '2rem',
    borderBottom: `1px solid ${palette.greyscale.antiFlashWhite}`,
    paddingBottom: '2rem',
  },
  '&[data-is-per-milestone="true"]': {
    marginTop: '2rem',
  },
  marginBottom: '2rem',
  ':last-child': {
    marginBottom: 0,
  },
});

export const DetailsModalLabel = styled.b({
  color: palette.greyscale.UCLABlue,
  fontWeight: 400,
  fontSize: '0.875rem',
});

export const DetailsModalValue = styled.p({
  color: palette.greyscale.persianBlue,
  fontSize: '0.875rem',
  fontWeight: 500,
  '&[data-is-amount="true"]': {
    color: palette.primary.genericViridian,
  },
});

export const DetailsModalMilestoneContainer = styled.div({
  paddingTop: '2rem',
  borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
  paddingBottom: '2rem',
  ':last-child': {
    paddingBottom: 0,
  },
});

export const ModalSpinnerWrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '6rem 0',
});

export const ModalHeaderText = styled.h2({
  fontSize: '1.5rem',
  color: palette.greyscale.persianBlue,
  fontWeight: 500,
  lineHeight: '2rem',
  marginBottom: '1rem',
  marginTop: '2rem',
});

export const ModalDescriptionText = styled.p({
  fontSize: '0.875rem',
  color: palette.greyscale.UCLABlue,
  fontWeight: 400,
  lineHeight: '1.375rem',
  marginBottom: '2rem',
});

export const PerMilestoneAmountContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});

export const NoMileStonesAreReady = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2rem',

  b: {
    fontSize: '1rem',
    color: palette.greyscale.UCLABlue,
    fontWeight: 500,
    marginInlineStart: '0.5rem',
  },
});
