import React from 'react';

import { UsePaginationReturn } from 'helpers/use-pagination';
import Filters from 'invoice-submission/filters';
import { QueryParameters } from 'types';
import {
  FiltersAndActions,
  TableHeaderContainer,
} from 'work-statement/styled-components';

import InvoiceNumberSearch from './invoice-number-search';

interface TableHeaderProps {
  handleChangeFilters: UsePaginationReturn['handleChangeFilters'];
  removeFilters: UsePaginationReturn['removeFilters'];
  requestParams: QueryParameters;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  handleChangeFilters,
  removeFilters,
  requestParams,
}) => (
  <TableHeaderContainer
    data-testid="table-header-section"
    data-with-contractors-search
  >
    <InvoiceNumberSearch
      handleChangeFilters={handleChangeFilters}
      removeFilters={removeFilters}
    />

    <FiltersAndActions>
      <Filters
        handleChangeFilters={handleChangeFilters}
        removeFilters={removeFilters}
        requestParams={requestParams}
      />
    </FiltersAndActions>
  </TableHeaderContainer>
);

export default TableHeader;
