import { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const FeedYourWalletBannerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '1rem',
  backgroundColor: palette.tertiary.aquaHaze,
  padding: '1.5rem',
  width: '100%',
});

export const FeedYourWalletBannerContent = styled.div({
  width: 'fit-content',
});

export const BannerTitle: SxProps = {
  fontSize: '1rem',
  fontFamily: 'Work Sans',
  fontWeight: 600,
  color: palette.primary.genericViridian,
  lineHeight: '1.5rem',
  marginBottom: '0.75rem',
};

export const BannerTextField: SxProps = {
  fontSize: '0.875rem',
  fontWeight: 400,
  color: palette.greyscale.persianBlue,
  lineHeight: '1.375rem',
  fontFamily: 'Work Sans',
};

export const FeedYourWalletBannerIcon = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});
