import React from 'react';

import { en } from 'localization';
import { InvoiceSubmissionStatus } from 'types/invoice-submission';
import { StyledInfoBox } from 'work-statement/styled-components';

import { PreviewStatusLabel } from './status-label';

const {
  invoiceSubmission: {
    table: { statusLegend },
  },
} = en;

const InvoiceSubmissionStatusInformation: React.FC = () => {
  const availableInvoiceSubmissionStatuses = [
    InvoiceSubmissionStatus.DRAFT,
    InvoiceSubmissionStatus.PENDING,
    InvoiceSubmissionStatus.APPROVED,
    InvoiceSubmissionStatus.REJECTED,
  ];

  return (
    <>
      {availableInvoiceSubmissionStatuses.map(status => (
        <StyledInfoBox key={status}>
          <PreviewStatusLabel status={status} />

          {statusLegend[status.toLocaleLowerCase()]}
        </StyledInfoBox>
      ))}
    </>
  );
};

export { InvoiceSubmissionStatusInformation };
