import React from 'react';

import { List, ListItem, ListItemText, Typography } from '@mui/material';

import { en } from 'localization';

import CurrenciesIcon from './currencies-icon.svg';
import {
  BannerTextField,
  BannerTitle,
  FeedYourWalletBannerContainer,
  FeedYourWalletBannerContent,
  FeedYourWalletBannerIcon,
} from './styled-components';

const {
  contractor: {
    paymentProfile: {
      customerPaymentProfile: {
        feedYourWalletBanner: { title, beginStepsText, endStepsText },
      },
    },
  },
} = en;

const steps = [
  {
    title: 'Initiate a Bank Transfer:',
    content:
      'Transfer the desired amount from your business bank account to your assigned virtual account number.',
  },
  {
    title: 'Use Preferred Currency:',
    content:
      'Ensure the transfer is made in the preferred currency of your virtual account to avoid conversion fees and processing delays.',
  },
  {
    title: 'Verify Transfer Details:',
    content:
      'Double-check that all beneficiary details, including your virtual account number, are entered correctly to prevent any issues.',
  },
  {
    title: 'Allow Processing Time:',
    content:
      'Depending on your location and banking provider, funds may take 1-5 business days to reflect in your balance.',
  },
];

export const FeedYourWalletBanner = () => (
  <FeedYourWalletBannerContainer>
    <FeedYourWalletBannerContent>
      <Typography sx={BannerTitle}>{title}</Typography>

      <Typography sx={BannerTextField}>{beginStepsText}</Typography>

      <List sx={{ padding: 0 }}>
        {steps.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  component="span"
                  sx={{
                    display: 'block',
                    textIndent: '-1.2em',
                    paddingLeft: '.2em',
                    ...BannerTextField,
                  }}
                >
                  <strong
                    style={{ fontWeight: '600', marginInlineEnd: '0.5rem' }}
                  >{`${index + 1}. ${item.title}`}</strong>

                  {item.content}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>

      <Typography sx={BannerTextField}>{endStepsText}</Typography>
    </FeedYourWalletBannerContent>

    <FeedYourWalletBannerIcon>
      <CurrenciesIcon />
    </FeedYourWalletBannerIcon>
  </FeedYourWalletBannerContainer>
);
