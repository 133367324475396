import React, { useCallback } from 'react';

import { MenuItem, applyStatics } from '@szhsin/react-menu';
import styled from 'styled-components';
import { Avatar } from 'workmotion-design-system';

import { ContractorBaseResponse } from 'types/contractor';

import { menuItemStyles } from './search-results-menu';

const itemTextStyles: React.CSSProperties = {
  fontFamily: 'Work Sans',
  fontSize: 12,
  fontWeight: 400,
};

const FullName = styled.span({
  ...itemTextStyles,
  overflow: 'hidden',
  marginLeft: '0.5rem',
  maxWidth: 200,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: '1rem',
});

interface EmployeeMenuItemProps {
  contractor: ContractorBaseResponse;
  itemRef?: React.Ref<any>;
  handleSelectContractor: (employee: ContractorBaseResponse) => void;
}

const ContractorMenuItem: React.FC<EmployeeMenuItemProps> = ({
  contractor,
  itemRef,
  handleSelectContractor,
  ...rest
}) => {
  const { id, firstName, lastName } = contractor;

  const fullName = `${firstName} ${lastName}`;

  const onClick = useCallback(() => {
    handleSelectContractor(contractor);
  }, [handleSelectContractor, contractor]);

  return (
    <MenuItem
      data-testid={`contractor-menu-item-${id}`}
      onClick={onClick}
      ref={itemRef}
      styles={menuItemStyles}
      {...rest}
    >
      <Avatar text={{ placeholder: fullName }} size="small" />

      <FullName>{fullName}</FullName>
    </MenuItem>
  );
};

applyStatics(MenuItem)(ContractorMenuItem);

export { ContractorMenuItem, itemTextStyles };
