import React from 'react';

import { Skeleton } from '@mui/material';

import {
  HeaderCell,
  Table,
  TableCell,
  TableHeadRow,
  TableRow,
} from 'shared-components/table-components/table-components';
import { ContractorListField } from 'work-statement/styled-components';

const TableSkeletonLoader = ({
  rows = 10,
  dataTestid = 'table-skeleton-loader',
}) => (
  <Table data-testid={dataTestid}>
    <thead>
      <TableHeadRow>
        <HeaderCell>
          <Skeleton width={100} height={20} />
        </HeaderCell>

        <HeaderCell>
          <Skeleton width={100} height={20} />
        </HeaderCell>

        <HeaderCell>
          <Skeleton width={100} height={20} />
        </HeaderCell>

        <HeaderCell>
          <Skeleton width={100} height={20} />
        </HeaderCell>

        <HeaderCell>
          <Skeleton width={100} height={20} />
        </HeaderCell>

        <HeaderCell />
      </TableHeadRow>
    </thead>

    <tbody>
      {Array.from({ length: rows }).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton width={100} height={20} />
          </TableCell>

          <TableCell>
            <Skeleton width={120} height={20} />
          </TableCell>

          <TableCell>
            <Skeleton width={120} height={20} />
          </TableCell>

          <TableCell>
            <ContractorListField>
              <Skeleton
                variant="circular"
                width={30}
                height={30}
                style={{ marginRight: 8 }}
              />

              <Skeleton width={90} height={20} />
            </ContractorListField>
          </TableCell>

          <TableCell>
            <Skeleton width={80} height={20} />
          </TableCell>

          <TableCell>
            <Skeleton width={10} height={50} />
          </TableCell>
        </TableRow>
      ))}
    </tbody>
  </Table>
);

export default TableSkeletonLoader;
