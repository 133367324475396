import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const SectionTitle = styled.div({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '1rem',
  marginBottom: '1rem',
  paddingTop: '2rem',

  ':not(:first-child)': {
    borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
  },
});

export const SectionSubtitle = styled.div({
  color: palette.greyscale.UCLABlue,
  fontWeight: 400,
  fontSize: '0.875rem',
  marginBottom: '2rem',
});
