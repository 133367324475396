import { format, parse } from 'date-fns';

type FormatOption = 'MONTH' | 'DATE';

const formatOptions = new Map<FormatOption, Record<string, string>>([
  ['MONTH', { timeZone: 'UTC', month: 'short', year: 'numeric' }],
  [
    'DATE',
    { timeZone: 'UTC', month: 'short', year: 'numeric', day: '2-digit' },
  ],
]);

const parseFormat = (formatOpt: FormatOption): Record<string, string> =>
  formatOptions.get(formatOpt) || {};

export const formatDateInUTC = (
  dateIso: string,
  viewType: FormatOption = 'DATE'
): string => {
  if (!dateIso) return null;
  const date = new Date(Date.parse(dateIso));

  const options = parseFormat(viewType);

  const formatter = new Intl.DateTimeFormat('en-GB', options);

  return formatter.format(date).toUpperCase();
};

export const getDateFromIso = (date: string): Date => {
  if (date) {
    return parse(date, 'yyyy-MM-dd', new Date());
  }

  return null;
};

export const getFormattedIsoDate = (date: Date): string => {
  if (date && !Number.isNaN(Number(date))) {
    return format(date, 'yyyy-MM-dd');
  }

  return null;
};

export const mapDateToMonthYear = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
  };

  return date?.toLocaleDateString('en-US', options)?.toUpperCase();
};

export const isDateInThePast = (date: string): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date && new Date(date) < today;
};

export const formatHoursAndMinutes = (hours, minutes) => {
  const hourLabel = hours === 1 ? 'Hour' : 'Hours';
  const minuteLabel = minutes === 1 ? 'Minute' : 'Minutes';

  if (hours && minutes) {
    return `${hours} ${hourLabel} ${minutes} ${minuteLabel}`;
  }

  if (hours) {
    return `${hours} ${hourLabel}`;
  }

  if (minutes) {
    return `${minutes} ${minuteLabel}`;
  }

  return '';
};
