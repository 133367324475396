import React, { useCallback } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalButtonsFooter,
  palette,
  Spinner,
} from 'workmotion-design-system';
import { string as yupString, object as yupObject } from 'yup';

import { en } from 'localization';
import { useUpdateInvoiceStatus } from 'networking/contractor-api/contractor-mutations';
import { InvoiceSubmissionStatus } from 'types/invoice-submission';
import {
  ModalDescriptionText,
  ModalHeaderText,
} from 'work-statement/styled-components';

import { RejectionReasonField } from './rejection-reason-field';

const {
  invoiceSubmission: {
    rejectionReasonModal: {
      modalTitle,
      modalHeader,
      submitButtonLabel,
      cancelButtonLabel,
      modalDescription,
      rejectedReasonField,
      successMessage,
    },
  },
} = en;

type ModalProps = {
  isRejectionReasonModalOpen: boolean;
  setIsRejectionReasonModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceSubmissionId: string;
  setSelectedInvoiceSubmissionId: React.Dispatch<React.SetStateAction<string>>;
};

const RejectionReasonModal: React.FC<ModalProps> = ({
  isRejectionReasonModalOpen,
  setIsRejectionReasonModalOpen,
  setSelectedInvoiceSubmissionId,
  selectedInvoiceSubmissionId,
}) => {
  const { isLoading, mutate } = useUpdateInvoiceStatus(
    selectedInvoiceSubmissionId
  );

  const handelCloseModal = useCallback(() => {
    setIsRejectionReasonModalOpen(false);
    setSelectedInvoiceSubmissionId(null);
  }, [setIsRejectionReasonModalOpen, setSelectedInvoiceSubmissionId]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      mutate(
        {
          ...values,
          newStatus: InvoiceSubmissionStatus.REJECTED,
        },
        {
          onSuccess: () => {
            toast(successMessage, {
              type: 'success',
            });

            handelCloseModal();
            setSubmitting(false);
          },
          onError: () => {
            setSubmitting(false);
          },
        }
      );
    },
    [handelCloseModal, mutate]
  );

  const validationSchema = yupObject().shape({
    [rejectedReasonField.fieldName]: yupString().required(
      rejectedReasonField.errorMessage
    ),
  });

  return (
    <Modal
      isOpen={isRejectionReasonModalOpen}
      closeModal={handelCloseModal}
      data-testid="rejection-reason-modal"
      header={modalTitle}
      dataCy={{ modalHeader: 'rejection-reason-modal-title' }}
    >
      <ModalHeaderText>{modalHeader}</ModalHeaderText>

      <ModalDescriptionText>{modalDescription}</ModalDescriptionText>

      <Formik
        initialValues={{ [rejectedReasonField.fieldName]: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <RejectionReasonField />

            <ModalButtonsFooter
              customStyle={{
                padding: '2rem 0 0',
                borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
              }}
              buttonsInfo={[
                {
                  onClick: handelCloseModal,
                  btnType: 'secondaryGrey',
                  text: cancelButtonLabel,
                  dataCy: 'rejection-reasons-cancel-action',
                },
                {
                  text: (
                    <>
                      {isSubmitting || isLoading ? (
                        <Spinner size={30} />
                      ) : (
                        submitButtonLabel
                      )}
                    </>
                  ),
                  type: 'submit',
                  btnType: 'error',
                  disabled: isSubmitting || isLoading,
                  'data-testid': 'rejection-reasons-submit-action',
                  dataCy: 'rejection-reasons-submit-action',
                },
              ]}
              placement="space-between"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RejectionReasonModal;
