import * as ContractorApiClientTypes from 'networking/contractor-api/contractor-client-types';

export enum InvoiceSubmissionStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type InvoicesResponse =
  ContractorApiClientTypes.Components.Schemas.InvoicesResponse;
