import React from 'react';

import { useField } from 'formik';
import { FormField, Textarea } from 'workmotion-design-system';

import { en } from 'localization';

const {
  invoiceSubmission: {
    rejectionReasonModal: {
      rejectedReasonField: { label, fieldName, placeholder },
    },
  },
} = en;

const RejectionReasonField: React.FC = () => {
  const [field, meta, helpers] = useField(fieldName);

  return (
    <FormField
      label={label}
      showError={!!(meta.touched && meta.error)}
      errorMessage={meta.error}
    >
      <Textarea
        {...field}
        placeholder={placeholder}
        value={field.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(event.target.value)
        }
        data-testid="invoice-submission-rejection-reason-textarea"
        data-cy="invoice-submission-rejection-reason-textarea"
        maxLength={254}
      />
    </FormField>
  );
};

export { RejectionReasonField };
