import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

import { RequestError } from 'types';

export const handleRequestError = (
  err: RequestError,
  backupErrorMessage: string,
  priorErrorMessage?: string
): void => {
  Sentry.captureException(err);

  const apiMessage = err.response?.data?.message || err.response?.data?.detail;

  const message = priorErrorMessage || apiMessage || backupErrorMessage;

  toast(message, {
    type: 'error',
  });
};
