export const en = {
  generic: {
    pageNotFoundScreen: {
      title: 'Page not found',
      subTitle1: 'The page you were looking for could not be found.',
      subTitle2:
        'It might have been removed, renamed, or did not exist in the first place.',
    },
    genricError:
      'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.',
    designSystem: {
      pagination: {
        dropdownPrefix: 'Show',
        getPagesRangeText: (
          fromPage: number,
          toPage: number,
          total: number,
          entity: string
        ): string => `${fromPage} - ${toPage} of ${total} ${entity}`,
      },
      datePicker: {
        actionBar: {
          acceptButton: 'Ok',
          cancelButton: 'Cancel',
        },
      },
    },
    sharedComponents: {
      errorFallback: {
        somethingWentWrong: 'Hmm.. Something went wrong.',
        weHaveBeenNotified:
          'We have been notified with the problem and we will be investigating this as fast as we can',
        retry: 'Retry',
      },
      filtersMenu: {
        applyButton: 'APPLY',
        resetButton: 'RESET',
      },
      contractorSearch: {
        placeHolder: 'Search by contractor name',
        failureMessage: 'Failed to get contractor!',
        noContractorFound: 'No contractor found',
      },
    },
  },
  workStatement: {
    table: {
      headers: {
        projectName: 'Project Name',
        paymentType: 'Payment Type',
        frequency: 'Frequency',
        rate: 'Rate/Amount',
        contractor: 'Contractor',
        status: 'Status',
        actions: '',
      },
      actionItems: {
        preview: 'Preview',
        assignContractor: 'Assign contractor',
        edit: 'Edit',
        finishWork: 'Finish work',
        cancelWork: 'Cancel work',
      },
      statusLegend: {
        legendTitle: 'Status information',
        new: 'This invoice has been partially paid. Please settle the remaining balance or contact your CX representative if you believe this is an error. For details on the payments made, check the payment history.',
        not_assigned:
          'This invoice not been assigned to a contractor yet. Please assign a contractor to this invoice to proceed.',
        in_progress:
          'Your invoice is due for payment. Please ensure it is paid before the due date to avoid any complications.',
        completed:
          'Your invoice has exceeded the payment terms. To avoid late payment fees, please make the payment as soon as possible.',
        canceled:
          'This invoice has been fully paid, either through a payment or a credit note. For more details, please check the payment history.',
      },
      paymentTypeLegend: {
        legendTitle: 'Payment type explanations',
        fixed_term:
          'This invoice has been fully paid, either through a payment or a credit note. For more details, please check the payment history.',
        hourly_based:
          'Your invoice has exceeded the payment terms. To avoid late payment fees, please make the payment as soon as possible.',
        project_based: `Your invoice is due for payment. Please ensure it is paid before the due date to avoid any complications.
1. Please review all the comments added by the talent to be reviewed.
2. Click on **Edit Contract** to go to the onboarding, and edit all necessary fields.`,
      },
      noWorkStatementTitleMessage: 'No work statement added yet',
      noWorkStatementMessage:
        'You will find the list of Work statements here once we start creating statements',
      paginationLabel: 'work statements',
      createStatement: 'Create statement',
      paymentTypesFilter: {
        options: {
          allPaymentsTypes: 'All payment types',
          fixedTermPayments: 'Fixed-term',
          hourlyBasedPayments: 'Hourly-based',
          projectBasedPayments: 'Project-based',
        },
        placeHolder: 'Select payment type',
      },
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          newLabel: 'New',
          notAssigned: 'Not assigned',
          inProgress: 'In progress',
          completed: 'Completed',
          canceled: 'Canceled',
        },
        placeHolder: 'Select status',
      },
    },
  },
  contractor: {
    table: {
      headers: {
        contractorName: 'Contractor',
        country: 'Country',
        jobTitle: 'Job Title',
        startDate: 'Start Date',
        endDate: 'End Date',
        onboardingtimeLine: 'Onboarding Timeline',
        actions: '',
      },
      actionItems: {
        viewProfile: 'View Profile',
        viewTimeline: 'View Timeline',
        continueAction: 'Continue',
        deleteAction: 'Delete',
      },
      activeContractors: {
        noActiveContractorsTitleMessage: 'No active contractors yet',
        noActiveContractorsMessage:
          'You will find the list of active contractors here once we finish onboarding',
      },
      onboardingContractors: {
        noOnboardingContractorsTitleMessage: 'No onboarding contractors yet',
        noOnboardingContractorsMessage:
          'You will find the list of onboarding contractors here once we start onboarding',
      },
      paginationLabel: 'records',
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          onboardingStarted: 'Onboarding started',
          contractorInvited: 'Contractor invited',
          contractorSignup: 'Contractor signup',
          dataCollection: 'Data collection',
          contarctorSigned: 'Contract signed',
        },
        placeHolder: 'Select status',
      },
    },
  },
  dashboard: {
    employeeListScreen: {
      liveTalentsTab: 'Active',
      onboardingTab: 'Onboardings',
    },
  },
};
