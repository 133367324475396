import React from 'react';

import { Avatar } from 'workmotion-design-system';

import { formatDateInUTC } from 'helpers/get-date-utils';
import { formatNumber } from 'helpers/number-formatting';
import { ItemActionsDropdown } from 'shared-components/table-components/item-actions-dropdown';
import {
  ActionItemsCellContainer,
  TableCell,
  TableRow,
} from 'shared-components/table-components/table-components';
import {
  InvoicesResponse,
  InvoiceSubmissionStatus,
} from 'types/invoice-submission';
import {
  avatarStyles,
  ContractorListField,
  PerMilestoneAmountContainer,
} from 'work-statement/styled-components';

import ActionMenuItems from './action-menu-items';
import { PreviewStatusLabel } from './status-label';

const InvoiceSubmissionListItem: React.FC<{
  invoice: InvoicesResponse;
}> = ({ invoice }) => {
  const {
    id,
    requestDate,
    dueDate,
    totalAmount,
    invoiceNumber,
    currency,
    contractorName,
    status,
  } = invoice;

  return (
    <TableRow data-is-clickable data-cy={`invoice-submission-record-of-${id}`}>
      <TableCell>{invoiceNumber}</TableCell>

      <TableCell>{formatDateInUTC(requestDate, 'DATE')}</TableCell>

      <TableCell>{formatDateInUTC(dueDate, 'DATE')}</TableCell>

      <TableCell>
        <PerMilestoneAmountContainer>
          <span>{`${formatNumber(totalAmount)} ${currency || ''}`}</span>
        </PerMilestoneAmountContainer>
      </TableCell>

      <TableCell>
        <ContractorListField>
          <Avatar
            avatarProps={{
              sx: avatarStyles,
            }}
            text={{ placeholder: contractorName }}
            size="small"
          />

          {contractorName}
        </ContractorListField>
      </TableCell>

      <TableCell>
        <PreviewStatusLabel status={status as InvoiceSubmissionStatus} />
      </TableCell>

      <TableCell>
        <ActionItemsCellContainer>
          <ItemActionsDropdown
            buttonDataTestId="invoice-submission-more-option-dropdown"
            menu={<ActionMenuItems />}
          />
        </ActionItemsCellContainer>
      </TableCell>
    </TableRow>
  );
};

export { InvoiceSubmissionListItem };
