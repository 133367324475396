import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

interface UseElementOnScreenReturn<T extends HTMLElement> {
  elementRef: RefObject<T> | null;
  isVisible: boolean;
}

function useElementOnScreen<T extends HTMLElement>(
  options?: IntersectionObserverInit
): UseElementOnScreenReturn<T> {
  const elementRef = useRef<T>(null);
  const [isVisible, setIsVisible] = useState(false);

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
    },
    [setIsVisible]
  );

  useEffect(() => {
    const currentElem = elementRef.current;
    const observer = new IntersectionObserver(observerCallback, options);

    if (currentElem) {
      observer.observe(currentElem);
    }

    return () => {
      if (currentElem) {
        observer.unobserve(currentElem);
      }
    };
  }, [options, observerCallback]);

  return { elementRef, isVisible };
}

export { useElementOnScreen };
