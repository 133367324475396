import React from 'react';

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import styled from 'styled-components';
import { Button, Menu, MenuProps, palette } from 'workmotion-design-system';

import { en } from 'localization';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${palette.greyscale.antiFlashWhite};
  padding: 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-bottom: 1px solid ${palette.greyscale.antiFlashWhite};
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  width: 18.5rem;
`;

interface FiltersMenuProps extends Omit<MenuProps, 'Button'> {
  onReset?: () => void;
  onApply?: () => void;
}

const FiltersMenu: React.FC<FiltersMenuProps> = ({
  children,
  isOpen,
  onClose,
  setIsMenuOpen,
  onReset,
  onApply,
}) => {
  const {
    generic: {
      sharedComponents: {
        filtersMenu: { applyButton, resetButton },
      },
    },
  } = en;

  return (
    <Menu
      isOpen={isOpen}
      Button={{
        icon: <FilterAltRoundedIcon />,
        tooltipText: 'Filters',
        dataTestId: 'filters-menu-button',
      }}
      onClose={onClose}
      setIsMenuOpen={setIsMenuOpen}
      menuContainerDataCy="menu-component-wrapper"
    >
      <div />

      <InputContainer data-testid="filters-menu-inputs-container">
        {children}
      </InputContainer>

      <ButtonsContainer>
        <Button
          data-testid="filters-reset-button"
          btnType="secondary"
          style={{
            width: 116,
            height: 40,
            border: `1px solid ${palette.greyscale.UCLABlue}`,
            borderRadius: '.75rem',
          }}
          onClick={onReset}
        >
          {resetButton}
        </Button>

        <Button
          data-testid="filters-apply-button"
          style={{ width: 116, height: 40, borderRadius: '.75rem' }}
          onClick={onApply}
        >
          {applyButton}
        </Button>
      </ButtonsContainer>
    </Menu>
  );
};

export { FiltersMenu };
