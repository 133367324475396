import React from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';

import NoItems from 'assets/empty-state-illustration.png';
import { en } from 'localization';
import { InfoSideMenu } from 'shared-components/info-side-menu';
import {
  StyledSectionWrapper,
  StyledSubTitle,
  StyledTitle,
} from 'shared-components/table';
import {
  HeaderCell,
  Table,
  TableHeadRow,
} from 'shared-components/table-components/table-components';
import TableSkeletonLoader from 'shared-components/table-skeleton-loader';
import { InvoicesResponse } from 'types/invoice-submission';
import { HeaderCellWithIcon } from 'work-statement/styled-components';

import { InvoiceSubmissionListItem } from './invoice-submission-list-item';
import { InvoiceSubmissionStatusInformation } from './invoice-submission-status-info';

const {
  invoiceSubmission: {
    table: {
      headers: {
        invoiceId,
        requestDate,
        dueDate,
        contractor,
        totalAmount,
        status,
        actions,
      },
      statusLegend: { legendTitle },
      noInvoiceSubmissionTitleMessage,
      noInvoiceSubmissionMessage,
    },
  },
} = en;

const InvoiceSubmissionList: React.FC<{
  isLoading: boolean;
  invoiceSubmissions: InvoicesResponse[];
  setSelectedInvoiceSubmissionId: React.Dispatch<React.SetStateAction<string>>;
  setIsDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAdjustInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTransferHistoryDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReadyForReviewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTransferPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  isLoading,
  invoiceSubmissions,
  setSelectedInvoiceSubmissionId,
  setIsDetailsModalOpen,
  setIsAdjustInvoiceModalOpen,
  setIsTransferHistoryDrawerOpen,
  setIsReadyForReviewModalOpen,
  setIsTransferPaymentModalOpen,
}) => {
  const {
    userIdentityInfo: { isContractor },
  } = useUserIdentity();

  if (isLoading) {
    return <TableSkeletonLoader dataTestid="invoice-submission-loading" />;
  }

  if (invoiceSubmissions?.length) {
    return (
      <Table
        data-testid="invoice-submission-table"
        data-cy="invoice-submission-table"
      >
        <thead>
          <TableHeadRow>
            <HeaderCell>{invoiceId}</HeaderCell>

            <HeaderCell>{requestDate}</HeaderCell>

            <HeaderCell>{dueDate}</HeaderCell>

            {!isContractor && <HeaderCell>{contractor}</HeaderCell>}

            <HeaderCell>{totalAmount}</HeaderCell>

            <HeaderCell>
              <HeaderCellWithIcon>
                {status}

                <InfoSideMenu
                  sideMenuTitle={legendTitle}
                  dataTestId="status-info"
                >
                  <InvoiceSubmissionStatusInformation />
                </InfoSideMenu>
              </HeaderCellWithIcon>
            </HeaderCell>

            <HeaderCell>{actions}</HeaderCell>
          </TableHeadRow>
        </thead>

        <tbody>
          {invoiceSubmissions?.map(invoice => (
            <InvoiceSubmissionListItem
              key={invoice.id}
              invoice={invoice}
              setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
              setIsDetailsModalOpen={setIsDetailsModalOpen}
              setIsAdjustInvoiceModalOpen={setIsAdjustInvoiceModalOpen}
              setIsTransferHistoryDrawerOpen={setIsTransferHistoryDrawerOpen}
              setIsReadyForReviewModalOpen={setIsReadyForReviewModalOpen}
              setIsTransferPaymentModalOpen={setIsTransferPaymentModalOpen}
            />
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <StyledSectionWrapper>
      <img src={NoItems} alt="No invoice submission Illustration" />

      <StyledTitle>{noInvoiceSubmissionTitleMessage}</StyledTitle>

      <StyledSubTitle>{noInvoiceSubmissionMessage}</StyledSubTitle>
    </StyledSectionWrapper>
  );
};

export { InvoiceSubmissionList };
