import React, { useCallback } from 'react';

import { CurrencyFlag, Modal } from 'workmotion-design-system';

import { replaceUnderscoresWithSpace } from 'helpers/string-helpers';
import { en } from 'localization';
import { VirtualAccountResponse } from 'types/invoice-submission';
import {
  DetailsModalField,
  DetailsModalLabel,
  DetailsModalValue,
  FieldsSeparator,
} from 'work-statement/styled-components';

import { CurrencyFieldAtDetailsModal, SectionTitle } from './styled-components';

const {
  contractor: {
    paymentProfile: {
      customerPaymentProfile: {
        virtualAccountDetailsModal: {
          title,
          modalFields: {
            currency,
            virtualNumber,
            accountName,
            accountType,
            bankAddress,
            bankName,
            routingCodeTitle,
          },
        },
      },
    },
  },
} = en;

type ModalProps = {
  isDetailsModalOpen: boolean;
  setIsDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: VirtualAccountResponse;
};

const VirtualAccountDetailsModal: React.FC<ModalProps> = ({
  data,
  isDetailsModalOpen,
  setIsDetailsModalOpen,
}) => {
  const handelCloseModal = useCallback(() => {
    setIsDetailsModalOpen(false);
  }, [setIsDetailsModalOpen]);

  return (
    <Modal
      isOpen={isDetailsModalOpen}
      closeModal={handelCloseModal}
      data-testid="virtual-account-details-modal"
      header={title}
      dataCy={{ modalHeader: 'virtual-account-details-modal-title' }}
    >
      <DetailsModalField style={{ marginTop: '2rem' }}>
        <DetailsModalLabel>{currency}</DetailsModalLabel>

        <CurrencyFieldAtDetailsModal>
          <CurrencyFlag currency={data?.currency} size={22} />

          <span>{data?.currency}</span>
        </CurrencyFieldAtDetailsModal>
      </DetailsModalField>

      <DetailsModalField>
        <DetailsModalLabel>{virtualNumber}</DetailsModalLabel>

        <DetailsModalValue>{data?.accountNumber}</DetailsModalValue>
      </DetailsModalField>

      <FieldsSeparator />

      <DetailsModalField>
        <DetailsModalLabel>{accountName}</DetailsModalLabel>

        <DetailsModalValue>{data?.accountName}</DetailsModalValue>
      </DetailsModalField>

      <DetailsModalField>
        <DetailsModalLabel>{accountType}</DetailsModalLabel>

        <DetailsModalValue>
          {replaceUnderscoresWithSpace(data?.accountType)}
        </DetailsModalValue>
      </DetailsModalField>

      <DetailsModalField style={{ alignItems: 'flex-start' }}>
        <DetailsModalLabel>{bankName}</DetailsModalLabel>

        <DetailsModalValue style={{ maxWidth: '17rem' }}>
          {replaceUnderscoresWithSpace(data?.fullBankName)}
        </DetailsModalValue>
      </DetailsModalField>

      <DetailsModalField style={{ alignItems: 'flex-start' }}>
        <DetailsModalLabel>{bankAddress}</DetailsModalLabel>

        <DetailsModalValue style={{ maxWidth: '17rem' }}>
          {data?.bankAddress}
        </DetailsModalValue>
      </DetailsModalField>

      <FieldsSeparator />

      <SectionTitle>{routingCodeTitle}</SectionTitle>

      {!!data?.routingCodeType1 && !!data?.routingCodeValue1 && (
        <DetailsModalField>
          <DetailsModalLabel>{data?.routingCodeType1}</DetailsModalLabel>

          <DetailsModalValue>{data?.routingCodeValue1}</DetailsModalValue>
        </DetailsModalField>
      )}

      {!!data?.routingCodeType2 && !!data?.routingCodeValue2 && (
        <DetailsModalField>
          <DetailsModalLabel>{data?.routingCodeType2}</DetailsModalLabel>

          <DetailsModalValue>{data?.routingCodeValue2}</DetailsModalValue>
        </DetailsModalField>
      )}
    </Modal>
  );
};

export default VirtualAccountDetailsModal;
