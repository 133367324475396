import { useQuery } from '@tanstack/react-query';

import { useAccessToken } from 'helpers/use-access-token';
import { QueryParameters } from 'types';
import { GetBeneficiarySchemaQueryParameters } from 'types/contractor-payment';

import {
  getAdditionalInformationStepClient,
  getApplicantDetailsClient,
  getBeneficiarySchemaClient,
  getBusinessDetailsClient,
  getDocumentDetailsForCustomerOnboardingClient,
  getKycUrlClient,
  getPaymentProfileStatusClient,
  getStakeHoldersDetailsClient,
  getSummaryReviewStepClient,
  getSupportedCurrenciesClient,
} from './contractor-payment-client';

export const useGetBeneficiarySchema = () => {
  const { accessToken } = useAccessToken();

  const getBeneficiarySchema = async (
    params: GetBeneficiarySchemaQueryParameters
  ) => getBeneficiarySchemaClient(accessToken?.accessToken, params);

  return { getBeneficiarySchema };
};

export const useGetSupportedCurrencies = (params?: QueryParameters) => {
  const { accessToken } = useAccessToken();

  const getSupportedCurrencies = async () =>
    getSupportedCurrenciesClient(accessToken?.accessToken, params);

  const { data, isLoading } = useQuery({
    queryKey: ['getSupportedCurrencies', params],
    queryFn: getSupportedCurrencies,
  });

  return { data, isLoading };
};

export const useGetBusinessDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getBusinessDetails = async () =>
    getBusinessDetailsClient(accessToken?.accessToken, companyId);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getBusinessDetails', companyId],
    queryFn: getBusinessDetails,
    staleTime: 0,
  });

  return { data, isLoading, isFetching };
};

export const useGetApplicantDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getApplicantDetails = async () =>
    getApplicantDetailsClient(accessToken?.accessToken, companyId);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getApplicantDetails', companyId],
    queryFn: getApplicantDetails,
    staleTime: 0,
  });

  return { data, isLoading, isFetching };
};

export const useGetStakeHoldersDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getStakeHoldersDetails = async () =>
    getStakeHoldersDetailsClient(accessToken?.accessToken, companyId);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getStakeHoldersDetails', companyId],
    queryFn: getStakeHoldersDetails,
    staleTime: 0,
  });

  return { data, isLoading, isFetching };
};

export const useGetAdditionalInformationDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getAdditionalInformationDetails = async () =>
    getAdditionalInformationStepClient(accessToken?.accessToken, companyId);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getAdditionalInformationDetails', companyId],
    queryFn: getAdditionalInformationDetails,
    staleTime: 0,
  });

  return { data, isLoading, isFetching };
};

export const useGetDocumentsInformationDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getDocumentsInformationDetails = async () =>
    getDocumentDetailsForCustomerOnboardingClient(
      accessToken?.accessToken,
      companyId
    );

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getDocumentsInformationDetails', companyId],
    queryFn: getDocumentsInformationDetails,
    staleTime: 0,
  });

  return { data, isLoading, isFetching };
};

export const useGetSummaryReviewDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getSummaryReviewDetails = async () =>
    getSummaryReviewStepClient(accessToken?.accessToken, companyId);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getSummaryReviewDetails', companyId],
    queryFn: getSummaryReviewDetails,
    staleTime: 0,
  });

  return { data, isLoading, isFetching };
};

export const useGetKycUrl = () => {
  const { accessToken } = useAccessToken();

  const getKycUrl = async (companyId: string) =>
    getKycUrlClient(accessToken?.accessToken, companyId);

  return { getKycUrl };
};

export const useGetPaymentProfileStatus = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getPaymentProfileStatus = async () =>
    getPaymentProfileStatusClient(accessToken?.accessToken, companyId);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getPaymentProfileStatus'],
    queryFn: getPaymentProfileStatus,
    staleTime: 0,
  });

  return { data, isLoading, isFetching };
};
