import React from 'react';

import { en } from 'localization';
import { PaymentProfileStatus } from 'types/contractor-payment';

import { StatusLabel } from './status-label';
import {
  StyledTitleContainer,
  StyledItemTitleWrapper,
  StyledItemTitle,
  StyledItemDescription,
  StyledPaymentProfileStatusBannerContainer,
} from './styled-components';

const {
  contractor: {
    paymentProfile: {
      customerPaymentProfile: {
        paymentProfileStatusBanner: { title, reviewSubTitle, rejectedSubTitle },
      },
    },
  },
} = en;

interface PaymentProfileStatusBannerProps {
  status: PaymentProfileStatus;
}

const PaymentProfileStatusBanner: React.FC<PaymentProfileStatusBannerProps> = ({
  status,
}) => (
  <StyledPaymentProfileStatusBannerContainer>
    <StyledTitleContainer>
      <StyledItemTitleWrapper>
        <StyledItemTitle>{title}</StyledItemTitle>
      </StyledItemTitleWrapper>

      {status ? <StatusLabel status={status} /> : '-'}
    </StyledTitleContainer>

    <StyledItemDescription>
      {status === 'IN_REVIEW' && reviewSubTitle}

      {status === 'REJECTED' && rejectedSubTitle}
    </StyledItemDescription>
  </StyledPaymentProfileStatusBannerContainer>
);

export { PaymentProfileStatusBanner };
