import React, { Suspense } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { FeatureFlagsWrapper } from '@workmotion/feature-flags-library';
import {
  AuthenticationWrapper,
  useUserIdentity,
} from '@workmotion/frontend-auth-library';
import { BrowserRouter as Router } from 'react-router-dom';

import { NetworkContext } from 'networking/network-context';
import { StyledToastContainer } from 'shared-components/styled-toast-container';

import { AppRoutes } from './app-routes';
import { PageLoadingSpinner } from './shared-components/page-loading-spinner';

const AppWrapper: React.FC = () => {
  const { userIdentityInfo } = useUserIdentity();

  return (
    <FeatureFlagsWrapper userInfo={userIdentityInfo?.userInfo}>
      <AppRoutes />
    </FeatureFlagsWrapper>
  );
};

const WrappedRootComponent: React.FC = () => (
  <Suspense
    fallback={
      <PageLoadingSpinner
        data-testid="contractor-app-loading"
        style={{ height: 500 }}
      />
    }
  >
    <Router>
      <AuthenticationWrapper>
        <NetworkContext>
          <AppWrapper />
        </NetworkContext>
      </AuthenticationWrapper>
    </Router>

    <StyledToastContainer />
  </Suspense>
);

export default WrappedRootComponent;
