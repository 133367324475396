import React, { useCallback, useState } from 'react';

import { useFlags } from '@workmotion/feature-flags-library';
import {
  reAuthenticate,
  ReAuthEntityTypeEnum,
} from '@workmotion/frontend-auth-library';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalButtonsFooter,
  palette,
  Spinner,
} from 'workmotion-design-system';

import { en } from 'localization';
import { usePayoutInvoice } from 'networking/contractor-api/contractor-mutations';
import { useGetInvoicePaymentDetails } from 'networking/contractor-api/contractor-network-requests';
import ModalSkeletonLoader from 'shared-components/modal-skeleton-loader';

import DetailsSection from './details-section';

const {
  invoiceSubmission: {
    transferPaymentModal: {
      modalTitle,
      successMessage,
      modalFooter: {
        actionButtons: { cancel, transfer },
      },
    },
  },
} = en;

type ModalProps = {
  isTransferPaymentModalOpen: boolean;
  setIsTransferPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceSubmissionId: string;
  setSelectedInvoiceSubmissionId: React.Dispatch<React.SetStateAction<string>>;
};

const TransferPaymentModal: React.FC<ModalProps> = ({
  isTransferPaymentModalOpen,
  setIsTransferPaymentModalOpen,
  setSelectedInvoiceSubmissionId,
  selectedInvoiceSubmissionId,
}) => {
  const { data, isLoading } = useGetInvoicePaymentDetails(
    selectedInvoiceSubmissionId,
    isTransferPaymentModalOpen
  );

  const { isLoading: payoutInvoiceIsLoading, mutate: payoutInvoiceMutate } =
    usePayoutInvoice(selectedInvoiceSubmissionId);

  const { avengers3015TransferMoneyFromCustomerToContractor } = useFlags();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handelCloseModal = useCallback(() => {
    setIsTransferPaymentModalOpen(false);
    setSelectedInvoiceSubmissionId(null);
  }, [setIsTransferPaymentModalOpen, setSelectedInvoiceSubmissionId]);

  const handlePayoutInvoice = useCallback(async () => {
    setIsSubmitting(true);

    await reAuthenticate({
      entityType: ReAuthEntityTypeEnum.INVOICE,
      entityId: selectedInvoiceSubmissionId,
      enabled: avengers3015TransferMoneyFromCustomerToContractor,
      onError: () => setIsSubmitting(false),
      callback: res => {
        payoutInvoiceMutate(res?.tokens?.idToken?.idToken, {
          onSuccess: () => {
            toast(successMessage, {
              type: 'success',
            });

            handelCloseModal();
            setIsSubmitting(false);
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      },
    });
  }, [
    selectedInvoiceSubmissionId,
    avengers3015TransferMoneyFromCustomerToContractor,
    handelCloseModal,
    payoutInvoiceMutate,
  ]);

  return (
    <Modal
      isOpen={isTransferPaymentModalOpen}
      closeModal={handelCloseModal}
      data-testid="transfer-payment-modal"
      header={modalTitle}
      dataCy={{ modalHeader: 'invoice-details-modal-title' }}
    >
      {isLoading ? (
        <ModalSkeletonLoader />
      ) : (
        <>
          <DetailsSection data={data} />

          <ModalButtonsFooter
            placement="space-between"
            buttonsInfo={[
              {
                text: cancel,
                btnType: 'secondaryGrey',
                dataCy: 'transfer-payment-cancel-button',
                onClick: handelCloseModal,
              },
              {
                text: (
                  <>
                    {isSubmitting || payoutInvoiceIsLoading ? (
                      <Spinner size={30} />
                    ) : (
                      transfer
                    )}
                  </>
                ),
                type: 'submit',
                dataCy: 'transfer-payment-submit-action',
                onClick: handlePayoutInvoice as () => void,
                disabled: isSubmitting || payoutInvoiceIsLoading,
              },
            ]}
            customStyle={{
              padding: '2rem 0 0',
              borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
            }}
          />
        </>
      )}
    </Modal>
  );
};

export default TransferPaymentModal;
