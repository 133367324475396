import React, { useCallback, useMemo } from 'react';

import { useFlags } from '@workmotion/feature-flags-library';
import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { toast } from 'react-toastify';
import { ModalButtonsFooter, palette, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { useUpdateInvoiceStatus } from 'networking/contractor-api/contractor-mutations';
import { InvoiceSubmissionStatus } from 'types/invoice-submission';

type ButtonInfoProps = {
  text: string | JSX.Element;
  dataCy?: string;
  [x: string]: any;
};

const {
  invoiceSubmission: {
    invoiceDetailsModal: {
      modalFooter: {
        actionButtons: {
          adjust,
          readyForReview,
          approve,
          reject,
          transferHistory,
          reinitiatePayment,
          transferPayment,
        },
        approveInvoiceSuccessMessage,
      },
    },
  },
} = en;

const DetailsModalFooter: React.FC<{
  status: InvoiceSubmissionStatus;
  selectedInvoiceSubmissionId: string;
  handelOpenAdjustModal: () => void;
  handelOpenReadyForReviewModal: () => void;
  handelOpenTransferHistoryDrawer: () => void;
  handelOpenRejectionReasonModal: () => void;
  handelOpenTransferPaymentModal: () => void;
  handelCloseModal: () => void;
}> = ({
  status,
  handelOpenAdjustModal,
  handelOpenReadyForReviewModal,
  handelOpenTransferHistoryDrawer,
  handelOpenRejectionReasonModal,
  handelOpenTransferPaymentModal,
  selectedInvoiceSubmissionId,
  handelCloseModal,
}) => {
  const { avengers2939NiumPaymentIntegrationPaymentsProcessing } = useFlags();

  const {
    userIdentityInfo: { isContractor, isHrManager, isCompanyAdmin },
  } = useUserIdentity();

  const { isLoading: approvingIsLoading, mutate } = useUpdateInvoiceStatus(
    selectedInvoiceSubmissionId
  );

  const paymentButtonLabel = useMemo(() => {
    if (status === InvoiceSubmissionStatus.FAILED) return reinitiatePayment;

    return transferPayment;
  }, [status]);

  const handleApproveInvoice = useCallback(() => {
    mutate(
      {
        newStatus: InvoiceSubmissionStatus.APPROVED,
      },
      {
        onSuccess: () => {
          toast(approveInvoiceSuccessMessage, {
            type: 'success',
          });

          handelCloseModal();
        },
      }
    );
  }, [handelCloseModal, mutate]);

  const buttonInfoForContractor: ButtonInfoProps[] = useMemo(() => {
    if (status === InvoiceSubmissionStatus.DRAFT) {
      return [
        {
          text: adjust,
          btnType: 'secondaryGrey',
          onClick: handelOpenAdjustModal,
          dataCy: 'adjust-invoice-action-button',
        },
        {
          text: readyForReview,
          onClick: handelOpenReadyForReviewModal,
          dataCy: 'ready-for0review-action-button',
        },
      ];
    }

    return null;
  }, [handelOpenAdjustModal, handelOpenReadyForReviewModal, status]);

  const buttonInfoForHR: ButtonInfoProps[] = useMemo(() => {
    if (status === InvoiceSubmissionStatus.PENDING) {
      return [
        {
          text: reject,
          btnType: 'fairPink',
          onClick: handelOpenRejectionReasonModal,
          dataCy: 'reject-invoice-action-button',
        },
        {
          text: <>{approvingIsLoading ? <Spinner size={30} /> : approve}</>,
          type: 'submit',
          dataCy: 'approve-invoice-action-button',
          onClick: handleApproveInvoice,
          disabled: approvingIsLoading,
        },
      ];
    }

    if (
      status === InvoiceSubmissionStatus.FAILED &&
      avengers2939NiumPaymentIntegrationPaymentsProcessing
    ) {
      return [
        {
          text: transferHistory,
          btnType: 'secondaryGrey',
          onClick: handelOpenTransferHistoryDrawer,
          dataCy: 'transfer-history-action-button',
        },
        {
          text: paymentButtonLabel,
          type: 'submit',
          onClick: handelOpenTransferPaymentModal,
          dataCy: 'transfer-payment-submit-action',
        },
      ];
    }

    if (
      status === InvoiceSubmissionStatus.APPROVED &&
      avengers2939NiumPaymentIntegrationPaymentsProcessing
    ) {
      return [
        {
          text: paymentButtonLabel,
          type: 'submit',
          onClick: handelOpenTransferPaymentModal,
          dataCy: 'transfer-payment-submit-action',
        },
      ];
    }

    if (
      (status === InvoiceSubmissionStatus.INITIATED ||
        status === InvoiceSubmissionStatus.PAID) &&
      avengers2939NiumPaymentIntegrationPaymentsProcessing
    ) {
      return [
        {
          text: transferHistory,
          btnType: 'secondaryGrey',
          onClick: handelOpenTransferHistoryDrawer,
          dataCy: 'transfer-history-action-button',
        },
      ];
    }

    return null;
  }, [
    approvingIsLoading,
    avengers2939NiumPaymentIntegrationPaymentsProcessing,
    handelOpenRejectionReasonModal,
    handelOpenTransferHistoryDrawer,
    handelOpenTransferPaymentModal,
    handleApproveInvoice,
    paymentButtonLabel,
    status,
  ]);

  const buttonInfo: ButtonInfoProps[] = useMemo(() => {
    if (isContractor) return buttonInfoForContractor;
    if (isHrManager || isCompanyAdmin) return buttonInfoForHR;

    return null;
  }, [
    buttonInfoForContractor,
    buttonInfoForHR,
    isCompanyAdmin,
    isContractor,
    isHrManager,
  ]);

  if (!buttonInfo) return null;

  return (
    <ModalButtonsFooter
      placement={buttonInfo?.length === 1 ? 'flex-end' : 'space-between'}
      buttonsInfo={buttonInfo}
      customStyle={{
        padding: '2rem 0 0',
        borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
      }}
    />
  );
};

export default DetailsModalFooter;
