import { addBreadcrumb, captureException } from '@sentry/react';
import { toast } from 'react-toastify';

import { RequestError } from 'types';

export const handleRequestError = (
  err: RequestError,
  backupErrorMessage: string,
  priorErrorMessage?: string
): void => {
  if (err) {
    if (err?.response?.status >= 500) {
      captureException(err);
    } else {
      addBreadcrumb({
        message: 'API Error',
        level: 'error',
        data: { error: err },
      });
    }
  }

  const apiMessage = err.response?.data?.message || err.response?.data?.detail;

  const message = priorErrorMessage || apiMessage || backupErrorMessage;

  toast(message, {
    type: 'error',
  });
};
