import React, { useMemo, useState } from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { Pagination } from 'workmotion-design-system';

import { DEFAULT_PAGE_SIZE, invoiceSubmissionFilters } from 'constant';
import { getCurrentCompanyId } from 'helpers/get-current-company-id';
import { usePagination } from 'helpers/use-pagination';
import { en } from 'localization';
import { useListAllInvoiceSubmission } from 'networking/contractor-api/contractor-network-requests';
import { ErrorBoundaryWrapper } from 'shared-components/error-fallback';

import AdjustInvoiceModal from './adjust-invoice-modal';
import ConfirmReadyForReviewModal from './confirm-ready-for-review-modal';
import InvoiceDetailsModal from './invoice-details-modal';
import { InvoiceSubmissionList } from './invoice-submission-list';
import RejectionReasonModal from './rejection-reason-modal';
import TableHeader from './table-header';
import TransferHistoryDrawer from './transfer-history-drawer';
import TransferPaymentModal from './transfer-payment-modal';

const {
  invoiceSubmission: {
    table: { paginationLabel },
  },
} = en;

const InvoiceSubmission: React.FC = () => {
  const {
    handleChangePage,
    currentPage,
    currentPageSize,
    handleChangePageSize,
    handleChangeFilters,
    removeFilters,
    requestParams,
  } = usePagination(invoiceSubmissionFilters, DEFAULT_PAGE_SIZE);

  const {
    userIdentityInfo: {
      isAdmin,
      companyId: userCompanyId,
      isContractor,
      userInfo: { userId },
    },
  } = useUserIdentity();

  const currentCompanyId = getCurrentCompanyId();

  const companyId = useMemo(() => {
    if (isAdmin) {
      return currentCompanyId;
    }

    return userCompanyId;
  }, [isAdmin, currentCompanyId, userCompanyId]);

  const getInvoiceSubmissionParams = useMemo(() => {
    if (isContractor)
      return { ...requestParams, contractorId: userId as string };

    return { ...requestParams, companyId };
  }, [companyId, isContractor, requestParams, userId]);

  const { data, isLoading, isFetching } = useListAllInvoiceSubmission(
    getInvoiceSubmissionParams
  );

  const [selectedInvoiceSubmissionId, setSelectedInvoiceSubmissionId] =
    useState<string>();

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const [isAdjustInvoiceModalOpen, setIsAdjustInvoiceModalOpen] =
    useState(false);

  const [isTransferHistoryDrawerOpen, setIsTransferHistoryDrawerOpen] =
    useState(false);

  const [isReadyForReviewModalOpen, setIsReadyForReviewModalOpen] =
    useState(false);

  const [isRejectionReasonModalOpen, setIsRejectionReasonModalOpen] =
    useState(false);

  const [isTransferPaymentModalOpen, setIsTransferPaymentModalOpen] =
    useState(false);

  return (
    <ErrorBoundaryWrapper>
      <div data-testid="invoice-submission-section">
        <TableHeader
          handleChangeFilters={handleChangeFilters}
          removeFilters={removeFilters}
          requestParams={requestParams}
        />

        <InvoiceSubmissionList
          invoiceSubmissions={data?.content}
          isLoading={isLoading || isFetching}
          setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
          setIsDetailsModalOpen={setIsDetailsModalOpen}
          setIsAdjustInvoiceModalOpen={setIsAdjustInvoiceModalOpen}
          setIsTransferHistoryDrawerOpen={setIsTransferHistoryDrawerOpen}
          setIsReadyForReviewModalOpen={setIsReadyForReviewModalOpen}
          setIsTransferPaymentModalOpen={setIsTransferPaymentModalOpen}
        />

        {!!data?.page?.totalElements && !isLoading && (
          <Pagination
            total={data?.page?.totalElements}
            currentPage={parseInt(currentPage as string, 10)}
            onChange={(selected: number) => handleChangePage(selected)}
            itemsName={paginationLabel}
            pageSize={parseInt(currentPageSize as string, 10)}
            onPageSizeChanged={handleChangePageSize}
          />
        )}

        <InvoiceDetailsModal
          isDetailsModalOpen={isDetailsModalOpen}
          setIsDetailsModalOpen={setIsDetailsModalOpen}
          selectedInvoiceSubmissionId={selectedInvoiceSubmissionId}
          setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
          setIsAdjustInvoiceModalOpen={setIsAdjustInvoiceModalOpen}
          setIsReadyForReviewModalOpen={setIsReadyForReviewModalOpen}
          setIsTransferHistoryDrawerOpen={setIsTransferHistoryDrawerOpen}
          setIsRejectionReasonModalOpen={setIsRejectionReasonModalOpen}
          setIsTransferPaymentModalOpen={setIsTransferPaymentModalOpen}
        />

        <AdjustInvoiceModal
          isAdjustInvoiceModalOpen={isAdjustInvoiceModalOpen}
          setIsAdjustInvoiceModalOpen={setIsAdjustInvoiceModalOpen}
          selectedInvoiceSubmissionId={selectedInvoiceSubmissionId}
          setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
        />

        <TransferPaymentModal
          isTransferPaymentModalOpen={isTransferPaymentModalOpen}
          setIsTransferPaymentModalOpen={setIsTransferPaymentModalOpen}
          selectedInvoiceSubmissionId={selectedInvoiceSubmissionId}
          setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
        />

        <TransferHistoryDrawer
          isTransferHistoryDrawerOpen={isTransferHistoryDrawerOpen}
          setIsTransferHistoryDrawerOpen={setIsTransferHistoryDrawerOpen}
          selectedInvoiceSubmissionId={selectedInvoiceSubmissionId}
          setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
        />

        <ConfirmReadyForReviewModal
          isReadyForReviewModalOpen={isReadyForReviewModalOpen}
          setIsReadyForReviewModalOpen={setIsReadyForReviewModalOpen}
          selectedInvoiceSubmissionId={selectedInvoiceSubmissionId}
          setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
        />

        <RejectionReasonModal
          isRejectionReasonModalOpen={isRejectionReasonModalOpen}
          setIsRejectionReasonModalOpen={setIsRejectionReasonModalOpen}
          selectedInvoiceSubmissionId={selectedInvoiceSubmissionId}
          setSelectedInvoiceSubmissionId={setSelectedInvoiceSubmissionId}
        />
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default InvoiceSubmission;
