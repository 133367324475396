import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useAccessToken } from 'helpers/use-access-token';
import { QueryParameters } from 'types';
import {
  GetInvoiceByIdParameters,
  GetInvoicePaymentDetailsParameters,
} from 'types/invoice-submission';
import { GetWorkStatementByIdParameters } from 'types/work-statement';

import {
  downloadInvoiceDocument,
  getAllContractorsClient,
  getContractorClient,
  getInvoiceById,
  getInvoicePaymentDetails,
  getInvoiceTransactionStatusHistory,
  getVirtualAccountByCompanyId,
  getWalletBalanceByCompanyId,
  getWorkStatementById,
  getWorkStatementWalletDetailsByCompanyId,
  listAllContractorInvoices,
  listAllWorkStatements,
} from './contractor-client';

export const useListAllWorkStatements = () => {
  const { accessToken } = useAccessToken();

  const getAllWorkStatements = async (params: QueryParameters) =>
    listAllWorkStatements(accessToken?.accessToken, params);

  return { getAllWorkStatements };
};

export const useGetWorkStatementById = (selectedWorkStatement: string) => {
  const { accessToken } = useAccessToken();

  const getOneWorkStatement = async (params: GetWorkStatementByIdParameters) =>
    getWorkStatementById(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getWorkStatementById', selectedWorkStatement],
    queryFn: () =>
      getOneWorkStatement({ workStatementId: selectedWorkStatement }),
    enabled: !!selectedWorkStatement,
  });

  return { data, isLoading, isFetching };
};

export const useGetAllContractors = () => {
  const { accessToken } = useAccessToken();

  const getAllContractors = useCallback(
    async (params: QueryParameters) =>
      getAllContractorsClient(accessToken?.accessToken, params),
    [accessToken?.accessToken]
  );

  return { getAllContractors };
};

export const useGetContractor = (contractorId: string) => {
  const { accessToken } = useAccessToken();

  const getContractor = async () =>
    getContractorClient(accessToken?.accessToken, contractorId);

  const { data, isLoading } = useQuery({
    queryKey: ['getContractor', contractorId],
    queryFn: getContractor,
    staleTime: 0,
  });

  return { data, isLoading };
};

export const useListAllInvoiceSubmission = getInvoiceSubmissionParams => {
  const { accessToken } = useAccessToken();

  const getAllInvoiceSubmissions = async (params: QueryParameters) =>
    listAllContractorInvoices(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['invoiceSubmissionRecords', getInvoiceSubmissionParams],
    queryFn: () => getAllInvoiceSubmissions(getInvoiceSubmissionParams),
  });

  return { data, isLoading, isFetching };
};

export const useGetInvoiceSubmissionById = (
  selectedInvoice: string,
  isDetailsModalOpen: boolean
) => {
  const { accessToken } = useAccessToken();

  const getOneInvoiceSubmission = async (params: GetInvoiceByIdParameters) =>
    getInvoiceById(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getInvoiceSubmissionById', selectedInvoice],
    queryFn: () => getOneInvoiceSubmission({ invoiceId: selectedInvoice }),
    enabled: !!selectedInvoice && isDetailsModalOpen,
  });

  return { data, isLoading, isFetching };
};

export const useDownloadInvoiceUrl = (selectedInvoice: string) => {
  const { accessToken } = useAccessToken();

  const getInvoiceDocumentDownloadUrl = async (params: { invoiceId: string }) =>
    downloadInvoiceDocument(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getInvoiceDocumentDownloadUrl', selectedInvoice],
    queryFn: () =>
      getInvoiceDocumentDownloadUrl({ invoiceId: selectedInvoice }),
    enabled: !!selectedInvoice,
  });

  return { data, isLoading, isFetching };
};

export const useGetInvoicePaymentDetails = (
  selectedInvoice: string,
  isTransferPaymentModalOpen: boolean
) => {
  const { accessToken } = useAccessToken();

  const getSingleInvoicePaymentDetails = async (
    params: GetInvoicePaymentDetailsParameters
  ) => getInvoicePaymentDetails(accessToken?.accessToken, params);

  const { data, isLoading } = useQuery({
    queryKey: ['getInvoicePaymentDetails', selectedInvoice],
    queryFn: () =>
      getSingleInvoicePaymentDetails({ invoiceId: selectedInvoice }),
    enabled: !!selectedInvoice && isTransferPaymentModalOpen,
    cacheTime: 0,
  });

  return { data, isLoading };
};

export const useGetInvoiceTransactionStatusHistory = (
  selectedInvoice: string,
  isTransferHistoryDrawerOpen: boolean
) => {
  const { accessToken } = useAccessToken();

  const getTransactionStatusHistory = async (params: { invoiceId: string }) =>
    getInvoiceTransactionStatusHistory(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getTransactionStatusHistory', selectedInvoice],
    queryFn: () => getTransactionStatusHistory({ invoiceId: selectedInvoice }),
    enabled: !!selectedInvoice && isTransferHistoryDrawerOpen,
  });

  return { data, isLoading, isFetching };
};

export const useGetWalletBalanceByCompanyId = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getWalletBalance = async (params: { companyId: string }) =>
    getWalletBalanceByCompanyId(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getWalletBalanceByCompanyId', companyId],
    queryFn: () => getWalletBalance({ companyId }),
    enabled: !!companyId,
  });

  return { data, isLoading, isFetching };
};

export const useGetVirtualAccountByCompanyId = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const getVirtualAccount = async (params: { companyId: string }) =>
    getVirtualAccountByCompanyId(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getVirtualAccountByCompanyId', companyId],
    queryFn: () => getVirtualAccount({ companyId }),
    enabled: !!companyId,
  });

  return { data, isLoading, isFetching };
};

export const useGetWorkStatementWalletDetailsByCompanyId = (
  companyId: string,
  enabled: boolean = true
) => {
  const { accessToken } = useAccessToken();

  const getWalletDetailsByCompanyId = async (params: { companyId: string }) =>
    getWorkStatementWalletDetailsByCompanyId(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getWorkStatementWalletDetailsByCompanyId', companyId],
    queryFn: () => getWalletDetailsByCompanyId({ companyId }),
    enabled: !!companyId && enabled,
  });

  return { data, isLoading, isFetching };
};
