import React from 'react';

import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Button, palette, Spinner } from 'workmotion-design-system';

import { handleRequestError } from 'helpers/handle-request-error';
import { en } from 'localization';
import { useGetKycUrl } from 'networking/contractor-payment-api/contractor-payment-network-requests';

import ApproveIcon from './ApprovalIcon.svg';
import {
  KYCBannerTextField,
  KYCBannerTitle,
  KYCBannerContainer,
  KYCBannerContent,
  KYCBannerIcon,
  KYCBannerContentField,
} from './styled-components';

const {
  generic: { somethingWentWrong },
  contractor: {
    paymentProfile: {
      customerPaymentProfile: {
        KYCBanner: {
          title,
          subTitle,
          nextStep,
          finalizeStep,
          processTakes,
          period,
          whatsHappen,
          provideDocuments,
          submitButtonLabel,
        },
      },
    },
  },
} = en;

const KYCBanner: React.FC<{
  companyId: string;
}> = ({ companyId }) => {
  const { getKycUrl } = useGetKycUrl();

  const { refetch: fetchKycUrl, isFetching: isLoading } = useQuery({
    queryKey: ['getKycUrl', companyId],
    queryFn: () => getKycUrl(companyId),
    enabled: false,
  });

  const handleButtonClick = async () => {
    try {
      const { data } = await fetchKycUrl();

      if (data?.kycUrl) {
        window.open(data.kycUrl, '_blank');
      }
    } catch (error) {
      handleRequestError(error, somethingWentWrong);
    }
  };

  return (
    <KYCBannerContainer>
      <KYCBannerContent>
        <Typography sx={KYCBannerTitle}>{title}</Typography>

        <Typography style={{ marginBottom: '1rem' }} sx={KYCBannerTextField}>
          {subTitle}
        </Typography>

        <div style={{ marginBottom: '1rem', lineHeight: '1.375rem' }}>
          <Typography sx={KYCBannerContentField}>{nextStep}</Typography>

          <Typography sx={KYCBannerTextField}>{finalizeStep}</Typography>

          <Typography sx={KYCBannerTextField}>
            <span>&emsp;• </span>

            <span>{processTakes}</span>

            <span style={{ fontWeight: '500' }}>{period}</span>

            <span>{whatsHappen} </span>

            <span
              style={{
                fontWeight: '500',
                color: palette.primary.genericViridian,
              }}
            >
              WorkMotion
            </span>

            <span> platform.</span>
          </Typography>

          <Typography sx={KYCBannerTextField}>
            <span>&emsp;• </span>

            <span>{provideDocuments}</span>
          </Typography>
        </div>

        <div>
          <Button
            style={{ borderRadius: '0.75rem' }}
            onClick={handleButtonClick}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <Spinner size={30} /> : submitButtonLabel}
          </Button>
        </div>
      </KYCBannerContent>

      <KYCBannerIcon>
        <ApproveIcon />
      </KYCBannerIcon>
    </KYCBannerContainer>
  );
};

export { KYCBanner };
