import React, { useCallback, useEffect, useState } from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { Avatar } from 'workmotion-design-system';

import { formatAmountWithCurrency } from 'helpers/format-amount-with-currency';
import { formatDateInUTC } from 'helpers/get-date-utils';
import { useDownloadInvoiceUrl } from 'networking/contractor-api/contractor-network-requests';
import { ItemActionsDropdown } from 'shared-components/table-components/item-actions-dropdown';
import {
  ActionItemsCellContainer,
  TableCell,
  TableRow,
} from 'shared-components/table-components/table-components';
import {
  InvoicesResponse,
  InvoiceSubmissionStatus,
} from 'types/invoice-submission';
import {
  avatarStyles,
  ContractorListField,
  PerMilestoneAmountContainer,
} from 'work-statement/styled-components';

import ActionMenuItems from './action-menu-items';
import { PreviewStatusLabel } from './status-label';

const InvoiceSubmissionListItem: React.FC<{
  invoice: InvoicesResponse;
  setSelectedInvoiceSubmissionId: React.Dispatch<React.SetStateAction<string>>;
  setIsDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAdjustInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTransferHistoryDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReadyForReviewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTransferPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  invoice,
  setSelectedInvoiceSubmissionId,
  setIsDetailsModalOpen,
  setIsAdjustInvoiceModalOpen,
  setIsTransferHistoryDrawerOpen,
  setIsReadyForReviewModalOpen,
  setIsTransferPaymentModalOpen,
}) => {
  const {
    id,
    workStatementId,
    requestDate,
    dueDate,
    totalAmount,
    invoiceNumber,
    currency,
    contractorName,
    status,
  } = invoice;

  const {
    userIdentityInfo: { isContractor },
  } = useUserIdentity();

  const [shouldDownloadInvoice, setShouldDownloadInvoice] = useState(false);

  const { data: downloadInvoiceUrl, isFetching: isDownloadInvoiceLoading } =
    useDownloadInvoiceUrl(shouldDownloadInvoice && id);

  useEffect(() => {
    if (downloadInvoiceUrl && shouldDownloadInvoice) {
      try {
        window.open(downloadInvoiceUrl, '_blank');
      } catch (error) {
        console.error('Error downloading file:', error);
      } finally {
        setShouldDownloadInvoice(false);
      }
    }
  }, [downloadInvoiceUrl, shouldDownloadInvoice]);

  const openDetailsModal = useCallback(() => {
    setSelectedInvoiceSubmissionId(id);
    setIsDetailsModalOpen(true);
  }, [id, setIsDetailsModalOpen, setSelectedInvoiceSubmissionId]);

  const openAdjustInvoiceModal = useCallback(() => {
    setSelectedInvoiceSubmissionId(id);
    setIsAdjustInvoiceModalOpen(true);
  }, [id, setIsAdjustInvoiceModalOpen, setSelectedInvoiceSubmissionId]);

  const openTransferHistoryDrawer = useCallback(() => {
    setSelectedInvoiceSubmissionId(id);
    setIsTransferHistoryDrawerOpen(true);
  }, [id, setIsTransferHistoryDrawerOpen, setSelectedInvoiceSubmissionId]);

  const openConfirmReadyForReviewModal = useCallback(() => {
    setSelectedInvoiceSubmissionId(id);
    setIsReadyForReviewModalOpen(true);
  }, [id, setIsReadyForReviewModalOpen, setSelectedInvoiceSubmissionId]);

  const openTransferPaymentModal = useCallback(() => {
    setSelectedInvoiceSubmissionId(id);
    setIsTransferPaymentModalOpen(true);
  }, [id, setIsTransferPaymentModalOpen, setSelectedInvoiceSubmissionId]);

  const handleDownloadInvoice = useCallback(() => {
    setShouldDownloadInvoice(true);
  }, []);

  return (
    <TableRow
      data-is-clickable
      data-cy={`invoice-submission-record-for-work-statement-record-of-${workStatementId}`}
    >
      <TableCell onClick={openDetailsModal}>{invoiceNumber}</TableCell>

      <TableCell onClick={openDetailsModal}>
        {formatDateInUTC(requestDate, 'DATE')}
      </TableCell>

      <TableCell onClick={openDetailsModal}>
        {formatDateInUTC(dueDate, 'DATE')}
      </TableCell>

      {!isContractor && (
        <TableCell onClick={openDetailsModal}>
          <ContractorListField>
            <Avatar
              avatarProps={{
                sx: avatarStyles,
              }}
              text={{ placeholder: contractorName }}
              size="small"
            />

            {contractorName}
          </ContractorListField>
        </TableCell>
      )}

      <TableCell onClick={openDetailsModal}>
        <PerMilestoneAmountContainer>
          <span>{formatAmountWithCurrency(totalAmount, currency)}</span>
        </PerMilestoneAmountContainer>
      </TableCell>

      <TableCell onClick={openDetailsModal}>
        <PreviewStatusLabel status={status as InvoiceSubmissionStatus} />
      </TableCell>

      <TableCell>
        <ActionItemsCellContainer>
          <ItemActionsDropdown
            buttonDataTestId="invoice-submission-more-option-dropdown"
            menu={
              <ActionMenuItems
                invoice={invoice}
                openDetailsModal={openDetailsModal}
                openAdjustInvoiceModal={openAdjustInvoiceModal}
                openTransferHistoryDrawer={openTransferHistoryDrawer}
                openConfirmReadyForReviewModal={openConfirmReadyForReviewModal}
                openTransferPaymentModal={openTransferPaymentModal}
                handleDownloadInvoice={handleDownloadInvoice}
                isDownloadInvoiceLoading={isDownloadInvoiceLoading}
              />
            }
          />
        </ActionItemsCellContainer>
      </TableCell>
    </TableRow>
  );
};

export { InvoiceSubmissionListItem };
