import React, { useCallback } from 'react';

import { DatePicker } from 'workmotion-design-system';

import { getDateFromIso, getFormattedIsoDate } from 'helpers/get-date-utils';
import { en } from 'localization';

interface EndDueDateFilterProps {
  value: string;
  setEndDueDate: React.Dispatch<React.SetStateAction<string>>;
  startDueDate: string;
}

const {
  invoiceSubmission: {
    table: {
      endDueDateFilter: { placeHolder },
    },
  },
} = en;

const EndDueDateFilter: React.FC<EndDueDateFilterProps> = ({
  value,
  setEndDueDate,
  startDueDate,
}) => {
  const dateChange = useCallback(
    (date: Date) => {
      setEndDueDate(getFormattedIsoDate(date) || '');
    },
    [setEndDueDate]
  );

  return (
    <DatePicker
      value={getDateFromIso(value)}
      onChange={dateChange}
      dataCy={'end-due-date-filter'}
      placeholder={placeHolder}
      minDate={getDateFromIso(startDueDate)}
    />
  );
};

export { EndDueDateFilter };
