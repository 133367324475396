import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { MenuState } from '@szhsin/react-menu';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  useUserIdentity,
  getCurrentCompanyId,
} from '@workmotion/frontend-auth-library';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { SearchIcon, palette } from 'workmotion-design-system';
import '@szhsin/react-menu/dist/index.css';

import { en } from 'localization';
import { useGetAllContractors } from 'networking/contractor-api/contractor-network-requests';
import { InputField, InputProps } from 'shared-components/input-field';
import { QueryParameters } from 'types';
import {
  ContractorBaseResponse,
  GetAllContractorsQueryParameters,
} from 'types/contractor';

import { SearchResultsMenu } from './search-results-menu';

const PAGE_SIZE = 10;

export const Input = styled(InputField)({
  height: '100%',
  paddingLeft: 40,
  background: palette.greyscale.ghostWhite,
  width: '100%',
  borderColor: palette.greyscale.antiFlashWhite,
  borderRadius: '0.5rem',
  '&[data-is-disabled="true"]': {
    cursor: 'not-allowed',
    backgroundColor: palette.greyscale.ghostWhite,
    borderColor: palette.greyscale.antiFlashWhite,
    color: palette.greyscale.sharkMouth,
  },
});

interface ContractorSearchProps {
  onSelect?: (contractor: ContractorBaseResponse) => void;
  onRemove?: () => void;
  initialSearchValue?: string;
  inputProps?: InputProps;
  requestParams?: Partial<GetAllContractorsQueryParameters>;
  searchBoxStyles?: React.CSSProperties;
  withResetButton?: boolean;
  additionalParams?: QueryParameters;
  disabled?: boolean;
}

const ContractorSearch: React.FC<ContractorSearchProps> = ({
  onSelect,
  onRemove,
  initialSearchValue,
  inputProps,
  searchBoxStyles,
  withResetButton = true,
  additionalParams,
  disabled,
}) => {
  const {
    generic: {
      sharedComponents: {
        contractorSearch: { placeHolder },
      },
    },
  } = en;

  const [menuState, setMenuState] = useState<MenuState>('closed');

  const [isContractorSelected, setIsContractorSelected] = useState(false);

  const [searchValue, setSearchValue] = useState<string>(
    initialSearchValue || ''
  );

  useEffect(() => {
    setSearchValue(initialSearchValue);
  }, [initialSearchValue]);

  const inputContainerRef = useRef<HTMLDivElement | null>(null);

  const { getAllContractors } = useGetAllContractors();

  const [defaultParams, setDefaultParams] = useState<QueryParameters>(null);

  const {
    userIdentityInfo: { companyId: userCompanyId, isAdmin },
  } = useUserIdentity();

  const selectedCompanyId = getCurrentCompanyId();

  const companyId = useMemo(() => {
    if (isAdmin) {
      return selectedCompanyId;
    }

    return userCompanyId;
  }, [isAdmin, selectedCompanyId, userCompanyId]);

  const {
    data: contractorData,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['getSearchContractors', defaultParams],
    queryFn: ({ pageParam = 0 }) => {
      const params = {
        ...defaultParams,
        page: pageParam,
        size: PAGE_SIZE,
        ...additionalParams,
      };

      if (companyId) {
        params.companyId = companyId;
      } else {
        delete params.companyId;
      }

      return getAllContractors(params);
    },
    getNextPageParam: lastPage =>
      lastPage.page.last ? undefined : lastPage.page.number + 1,
    enabled: !!defaultParams,
  });

  const [contractors, setContractors] = useState([]);

  const getContractorsArray = useCallback(() => {
    if (contractorData?.pages.length) {
      const absenceArray = [];

      contractorData.pages.forEach(page => {
        absenceArray.push(...page.content);
      });

      setContractors(absenceArray);
    }
  }, [contractorData]);

  useEffect(() => {
    getContractorsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractorData]);

  const debouncedFetchItems = useDebouncedCallback((value: string) => {
    setDefaultParams(prevDefaultParams => ({
      ...prevDefaultParams,
      searchTerm: value,
    }));

    setMenuState('open');
  }, 300);

  const onChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(value);

      const isValueValid = value && value.length >= 3;

      setMenuState(prev => {
        if (!isValueValid && prev === 'open') {
          return 'closed';
        }

        return prev;
      });

      if (!isValueValid) {
        return;
      }

      debouncedFetchItems(value);
    },
    [debouncedFetchItems]
  );

  const closeMenu = useCallback(() => {
    setMenuState('closed');
  }, []);

  const openMenuOnFocus = useCallback(() => {
    if (contractors?.length) {
      setMenuState('open');
    }
  }, [contractors?.length]);

  const handleSelectContractor = useCallback(
    (selectedContractor: ContractorBaseResponse) => {
      setContractors([selectedContractor]);

      setSearchValue(
        `${selectedContractor.firstName} ${selectedContractor.lastName}`
      );

      setIsContractorSelected(true);

      if (onSelect) {
        onSelect(selectedContractor);
      }
      closeMenu();
    },
    [closeMenu, onSelect]
  );

  const resetSelectedContractor = useCallback(() => {
    setContractors(null);
    setSearchValue('');
    setIsContractorSelected(false);
    onRemove();
    closeMenu();
  }, [onRemove, closeMenu]);

  const onKeypressHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const keyCode = event.which || event.keyCode;

      if (
        (event.key === 'Enter' || keyCode === 13) &&
        searchValue.length === 0
      ) {
        onRemove();
        setIsContractorSelected(false);
      }
    },
    [searchValue, onRemove]
  );

  return (
    <div onFocus={openMenuOnFocus} style={{ width: '100%' }}>
      <div
        ref={inputContainerRef}
        style={{
          position: 'relative',
          height: 40,
          width: '15.5rem',
          ...searchBoxStyles,
        }}
      >
        <SearchIcon
          style={{
            position: 'absolute',
            top: 14,
            left: 18,
            fontSize: '.8rem',
            color: palette.greyscale.grainOfSalt,
          }}
        />

        <Input
          {...inputProps}
          value={searchValue}
          onChange={onChange}
          onBlur={closeMenu}
          placeholder={placeHolder}
          data-testid="contractor-filter-search-input"
          onKeyPress={onKeypressHandler}
          disabled={disabled}
          data-is-disabled={disabled}
        />

        {(isContractorSelected || initialSearchValue) && withResetButton && (
          <CloseRoundedIcon
            sx={{
              position: 'absolute',
              top: 11,
              right: 14,
              fontSize: '1.2rem',
              cursor: 'pointer',
              color: palette.greyscale.sharkMouth,
            }}
            onClick={resetSelectedContractor}
            data-testid="contractor-filter-reset-btn"
          />
        )}
      </div>

      <SearchResultsMenu
        anchorRef={inputContainerRef}
        menuState={menuState}
        results={contractors}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        handleSelectContractor={handleSelectContractor}
        hasNextPage={hasNextPage}
        getNextPage={fetchNextPage}
      />
    </div>
  );
};

export { ContractorSearch };
