import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const ProfileBodyContainer = styled.div({
  display: 'flex',
  gap: '1.5rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media(min-width:1200px)': {
    flexDirection: 'row',
  },
});

export const MainSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  '@media(min-width:1200px)': {
    width: '75%',
  },
});

export const SideSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  '@media(min-width:1200px)': {
    width: '25%',
    alignItems: 'end',
  },
});

export const SideCard = styled.div({
  borderRadius: '1rem',
  background: palette.secondary.white,
  padding: '1.5rem',
  '@media(min-width:1200px)': {
    width: '100%',
  },
});

export const SideInfoTitle = styled.p({
  marginBottom: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  b: {
    color: palette.greyscale.persianBlue,
    fontWeight: 600,
    fontSize: '1rem',
    fontFamily: 'Work Sans',
  },
});

export const SideInfoTitleDetailsButton = styled.button({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  span: {
    marginLeft: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontFamily: 'Work Sans',
    color: palette.primary.genericViridian,
  },
});

export const VirtualAccountInfoItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',

  '&:last-child': {
    marginBottom: '0',
  },
});

export const VirtualAccountInfoValue = styled.p({
  color: palette.greyscale.persianBlue,
  marginInlineStart: '0.5rem',
  fontWeight: 400,
  fontSize: '0.875rem',
  fontFamily: 'Work Sans',
});

export const CopyIconContainer = styled.div({
  cursor: 'pointer',
  marginInlineStart: '0.5rem',
  color: palette.greyscale.UCLABlue,
  svg: {
    fontSize: '1rem',
  },
  height: '1rem',
  width: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WalletBalanceCardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1rem',
  background: palette.secondary.white,
  padding: '1.5rem',
});

export const WalletBalanceTitle = styled.b({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '1rem',
  fontFamily: 'Work Sans',
  marginBottom: '0.75rem',
});

export const WalletBalanceValue = styled.div({
  display: 'flex',
  alignItems: 'center',
  b: {
    color: palette.primary.genericViridian,
    fontWeight: 700,
    fontSize: '1.5rem',
    fontFamily: 'Work Sans',
    marginInlineEnd: '0.5rem',
  },
});

export const VirtualAccountInfoValueStyles = {
  fontFamily: 'Work Sans',
  fontWeight: 400,
  color: palette.greyscale.persianBlue,
  marginInlineStart: '0.5rem',
  fontSize: '0.875rem',
};

export const CurrencyFieldAtDetailsModal = styled.p({
  color: palette.greyscale.persianBlue,
  fontSize: '0.875rem',
  lineHeight: '1rem',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  span: {
    marginInlineStart: '0.5rem',
  },
});

export const SectionTitle = styled.div({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '1rem',
  marginBottom: '2rem',
});
