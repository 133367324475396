import React from 'react';

import { Spinner } from 'workmotion-design-system';

import NoItems from 'assets/empty-state-illustration.png';
import { en } from 'localization';
import { InfoSideMenu } from 'shared-components/info-side-menu';
import {
  StyledSectionWrapper,
  StyledSubTitle,
  StyledTitle,
} from 'shared-components/table';
import {
  HeaderCell,
  Table,
  TableHeadRow,
} from 'shared-components/table-components/table-components';
import { InvoicesResponse } from 'types/invoice-submission';
import { HeaderCellWithIcon } from 'work-statement/styled-components';

import { InvoiceSubmissionListItem } from './invoice-submission-list-item';
import { InvoiceSubmissionStatusInformation } from './invoice-submission-status-info';

const {
  invoiceSubmission: {
    table: {
      headers: {
        invoiceId,
        submissionDate,
        dueDate,
        contractor,
        totalAmount,
        status,
        actions,
      },
      statusLegend: { legendTitle },
      noInvoiceSubmissionTitleMessage,
      noInvoiceSubmissionMessage,
    },
  },
} = en;

const InvoiceSubmissionList: React.FC<{
  isLoading: boolean;
  invoiceSubmissions: InvoicesResponse[];
}> = ({ isLoading, invoiceSubmissions }) => {
  if (isLoading) {
    return (
      <StyledSectionWrapper>
        <Spinner data-testid="invoice-submission-loading" />
      </StyledSectionWrapper>
    );
  }

  if (invoiceSubmissions?.length) {
    return (
      <Table
        data-testid="invoice-submission-table"
        data-cy="invoice-submission-table"
      >
        <thead>
          <TableHeadRow>
            <HeaderCell>{invoiceId}</HeaderCell>

            <HeaderCell>{submissionDate}</HeaderCell>

            <HeaderCell>{dueDate}</HeaderCell>

            <HeaderCell>{contractor}</HeaderCell>

            <HeaderCell>{totalAmount}</HeaderCell>

            <HeaderCell>
              <HeaderCellWithIcon>
                {status}

                <InfoSideMenu
                  sideMenuTitle={legendTitle}
                  dataTestId="status-info"
                >
                  <InvoiceSubmissionStatusInformation />
                </InfoSideMenu>
              </HeaderCellWithIcon>
            </HeaderCell>

            <HeaderCell>{actions}</HeaderCell>
          </TableHeadRow>
        </thead>

        <tbody>
          {invoiceSubmissions?.map(invoice => (
            <InvoiceSubmissionListItem key={invoice.id} invoice={invoice} />
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <StyledSectionWrapper>
      <img src={NoItems} alt="No invoice submission Illustration" />

      <StyledTitle>{noInvoiceSubmissionTitleMessage}</StyledTitle>

      <StyledSubTitle>{noInvoiceSubmissionMessage}</StyledSubTitle>
    </StyledSectionWrapper>
  );
};

export { InvoiceSubmissionList };
