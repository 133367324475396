import { QueryParameters } from 'types';
import {
  AddBeneficiaryRequest,
  AdditionalInformationRequest,
  ApplicantDetailsRequest,
  BusinessDetailsRequest,
  CustomerOnboardingIndividualsDocumentUpload,
  GetBeneficiarySchemaQueryParameters,
  StakeholdersDetailsResponse,
  KycResultRequest,
} from 'types/contractor-payment';

import { createClient } from './open-api-client';

export async function getBeneficiarySchemaClient(
  token: string,
  params?: GetBeneficiarySchemaQueryParameters
) {
  const client = await createClient(token);

  const response = await client.getBeneficiarySchema(params as any);

  return response.data;
}

export async function addBeneficiaryDetailsClient(
  token: string,
  contractorId: string,
  data: AddBeneficiaryRequest
) {
  const client = await createClient(token);

  const response = await client.createBeneficiary(
    {
      contractorId,
    },
    { ...data }
  );

  return response.data;
}

export async function getSupportedCurrenciesClient(
  token: string,
  params?: QueryParameters
) {
  const client = await createClient(token);

  const response = await client.getSupportedCurrencies(params as any);

  return response.data;
}

export async function getBusinessDetailsClient(
  token: string,
  companyId: string
) {
  const client = await createClient(token);

  const response = await client.getBusinessDetails({ companyId });

  return response.data;
}

export async function addBusinessDetailsClient(
  token: string,
  companyId: string,
  data: BusinessDetailsRequest
) {
  const client = await createClient(token);

  const response = await client.addOrUpdateBusinessDetailsStep(
    { companyId },
    { ...data }
  );

  return response.data;
}

export async function getApplicantDetailsClient(
  token: string,
  companyId: string
) {
  const client = await createClient(token);

  const response = await client.getApplicantDetails({ companyId });

  return response.data;
}

export async function addApplicantDetailsClient(
  token: string,
  companyId: string,
  data: ApplicantDetailsRequest
) {
  const client = await createClient(token);

  const response = await client.addOrUpdateApplicantDetailsStep(
    { companyId },
    { ...data }
  );

  return response.data;
}

export async function getStakeHoldersDetailsClient(
  token: string,
  companyId: string
) {
  const client = await createClient(token);

  const response = await client.getStakeHoldersDetails({ companyId });

  return response.data;
}

export async function addStakeHoldersDetailsClient(
  token: string,
  companyId: string,
  data: StakeholdersDetailsResponse
) {
  const client = await createClient(token);

  const response = await client.addOrUpdateStakeHoldersDetailsStep(
    { companyId },
    { ...data }
  );

  return response.data;
}

export async function getAdditionalInformationStepClient(
  token: string,
  companyId: string
) {
  const client = await createClient(token);

  const response = await client.getAdditionalInformationStep({ companyId });

  return response.data;
}

export async function addAdditionalInformationDetailsClient(
  token: string,
  companyId: string,
  data: AdditionalInformationRequest
) {
  const client = await createClient(token);

  const response = await client.addOrUpdateAdditionalInformationStep(
    { companyId },
    { ...data }
  );

  return response.data;
}

export async function getDocumentDetailsForCustomerOnboardingClient(
  token: string,
  companyId: string
) {
  const client = await createClient(token);

  const response = await client.getDocumentDetailsForCustomerOnboarding({
    companyId,
  });

  return response.data;
}

export async function uploadApplicantDocumentClient(
  token: string,
  applicantId: string,
  companyId: string,
  data: CustomerOnboardingIndividualsDocumentUpload
) {
  const client = await createClient(token, { isMultiPart: true });
  const formData = new FormData();

  if (data.file) {
    (data?.file as unknown as File[]).forEach((file: File) => {
      formData.append('file', file);
    });
  }

  if (data.documentType) {
    formData.append('documentType', data.documentType);
  }

  if (data.documentNumber) {
    formData.append('documentNumber', data.documentNumber);
  }

  if (data.documentIssuanceCountry) {
    formData.append('documentIssuanceCountry', data.documentIssuanceCountry);
  }

  if (data.documentExpiryDate) {
    formData.append('documentExpiryDate', data.documentExpiryDate);
  }

  const response = await client.uploadApplicantDocument(
    { applicantId, companyId },
    formData as never
  );

  return response.data;
}

export async function uploadBusinessDocumentClient(
  token: string,
  companyId: string,
  data: CustomerOnboardingIndividualsDocumentUpload
) {
  const client = await createClient(token, { isMultiPart: true });
  const formData = new FormData();

  if (data.file) {
    (data?.file as unknown as File[]).forEach((file: File) => {
      formData.append('file', file);
    });
  }

  if (data.documentType) {
    formData.append('documentType', data.documentType);
  }

  const response = await client.uploadBusinessDocument(
    { companyId },
    formData as never
  );

  return response.data;
}

export async function uploadStakeholderDocumentClient(
  token: string,
  stakeholderId: string,
  companyId: string,
  data: CustomerOnboardingIndividualsDocumentUpload
) {
  const client = await createClient(token, { isMultiPart: true });
  const formData = new FormData();

  if (data.file) {
    (data?.file as unknown as File[]).forEach((file: File) => {
      formData.append('file', file);
    });
  }

  if (data.documentType) {
    formData.append('documentType', data.documentType);
  }

  if (data.documentNumber) {
    formData.append('documentNumber', data.documentNumber);
  }

  if (data.documentIssuanceCountry) {
    formData.append('documentIssuanceCountry', data.documentIssuanceCountry);
  }

  if (data.documentExpiryDate) {
    formData.append('documentExpiryDate', data.documentExpiryDate);
  }

  const response = await client.uploadStakeholderDocument(
    { stakeholderId, companyId },
    formData as never
  );

  return response.data;
}

export async function submitCustomerOnboardingClient(
  token: string,
  companyId: string,
  data?: any
) {
  const client = await createClient(token);

  const response = await client.submitCustomerOnboarding(
    { companyId },
    { ...data }
  );

  return response.data;
}

export async function getSummaryReviewStepClient(
  token: string,
  companyId: string
) {
  const client = await createClient(token);

  const response = await client.getSummeryReviewStep({
    companyId,
  });

  return response.data;
}

export async function deleteCustomerBusinessDocumentClient(
  token: string,
  companyId: string,
  businessDocumentId: string,
  fileKey?: string
) {
  const client = await createClient(token);

  const response = await client.deleteCustomerBusinessDocument({
    companyId,
    businessDocumentId,
    fileKey,
  });

  return response.data;
}

export async function deleteApplicantDocumentClient(
  token: string,
  companyId: string,
  applicantDocumentId: string,
  fileKey?: string
) {
  const client = await createClient(token);

  const response = await client.deleteApplicantDocument({
    companyId,
    applicantDocumentId,
    fileKey,
  });

  return response.data;
}

export async function deleteStakeholderDocumentClient(
  token: string,
  companyId: string,
  stakeholderId: string,
  stakeholderDocumentId: string,
  fileKey?: string
) {
  const client = await createClient(token);

  const response = await client.deleteStakeholderDocument({
    companyId,
    stakeholderId,
    stakeholderDocumentId,
    fileKey,
  });

  return response.data;
}

export async function getKycUrlClient(token: string, companyId: string) {
  const client = await createClient(token);

  const response = await client.getKycUrl({
    companyId,
  });

  return response.data;
}

export async function getPaymentProfileStatusClient(
  token: string,
  companyId: string
) {
  const client = await createClient(token);

  const response = await client.getPaymentProfileStatus({
    companyId,
  });

  return response.data;
}

export async function handleKycResultClient(
  token: string,
  companyId: string,
  data: KycResultRequest
) {
  const client = await createClient(token);

  const response = await client.handleKycResult(
    {
      companyId,
    },
    { ...data }
  );

  return response.data;
}
