import React from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { MenuItem } from '@mui/material';
import { useUserIdentity } from '@workmotion/frontend-auth-library';

import { en } from 'localization';
import {
  actionMenuItemIconStyles,
  actionMenuItemStyles,
} from 'work-statement/styled-components';

const {
  invoiceSubmission: {
    table: {
      actionItems: { preview },
    },
  },
} = en;

const ActionMenuItems: React.FC = () => {
  const {
    userIdentityInfo: { isHrManager, isCompanyAdmin },
  } = useUserIdentity();

  const isHrUser = isHrManager || isCompanyAdmin;

  const PreviewMenuItem = () => (
    <MenuItem
      data-testid="invoice-submission-preview-option"
      sx={actionMenuItemStyles}
    >
      <VisibilityIcon style={actionMenuItemIconStyles} />

      {preview}
    </MenuItem>
  );

  if (isHrUser) {
    return <PreviewMenuItem />;
  }

  return null;
};

export default ActionMenuItems;
