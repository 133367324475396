import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { StyledTableHeadCell } from 'shared-components/table';

const border = `1px solid ${palette.greyscale.antiFlashWhite}`;

const Table = styled.table({
  width: '100%',
  '& tr:last-child td:first-child': {
    borderBottomLeftRadius: '0.5rem',
  },
  '& tr:last-child td:last-child': {
    borderBottomRightRadius: '0.5rem',
  },
  '& td:first-child': {
    borderLeft: border,
  },
  '& td:last-child': {
    borderRight: border,
  },
  borderCollapse: 'separate',
  borderSpacing: 0,
});

const TableHeadRow = styled.tr({
  background: palette.greyscale.antiFlashWhite,
  height: 46,
  '& > :first-child': {
    paddingLeft: '1.5rem',
  },
  '& > :last-child': {
    paddingRight: '1.5rem',
  },
});

const HeaderCell = styled(StyledTableHeadCell)({
  fontSize: 12,
  maxWidth: 150,
  lineHeight: 0.8,
  color: palette.greyscale.UCLABlue,
  fontFamily: 'Work Sans',
  fontWeight: 600,
  textAlign: 'left',
  padding: '0 15px',
  whiteSpace: 'nowrap',
});

const TableCell = styled.td({
  maxWidth: 150,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 14,
  verticalAlign: 'middle',
  fontFamily: 'Work Sans',
  color: palette.greyscale.persianBlue,
  fontWeight: 400,
  borderBottom: border,
  padding: '0 15px',
  '&[data-is-smaller-width="true"]': {
    '@media(max-width: 1350px)': {
      maxWidth: '6.25rem',
    },
  },
});

const TableRow = styled.tr({
  height: 64,
  background: `${palette.secondary.white}`,
  '&[data-is-clickable]': {
    transition: 'background-color 450ms ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.greyscale.ghostWhite,
    },
  },
  '&[data-is-selected="true"]': {
    background: palette.greyscale.ghostWhite,
  },
  '& > :first-child': {
    paddingLeft: '1.5rem',
  },
  '& > :last-child': {
    paddingRight: '1.5rem',
  },
});

export const ActionItemsCellContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export { HeaderCell, Table, TableRow, TableHeadRow, TableCell };
