import { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const TableHeaderContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5rem',
  background: palette.secondary.white,
  borderRadius: '1rem 1rem 0 0',
  zIndex: 1,
});

export const FiltersAndActions = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const avatarStyles: SxProps = {
  color: palette.semantic.persianGreen,
  height: '2rem',
  width: '2rem',
  fontSize: '0.75rem',
  lineHeight: '0.875rem',
  backgroundColor: palette.secondary.softMint,
};

export const ContractorListField = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const SideMenuContainer = styled.div({
  padding: '0 2rem 2rem 2rem',
});

export const StyledInfoBox = styled.div({
  padding: '1rem 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  fontSize: '.875rem',
  lineHeight: '1.375rem',
  color: palette.greyscale.UCLABlue,
  borderBottom: `1px solid ${[palette.greyscale.antiFlashWhite]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
  'p, ol': {
    margin: '0px !important',
  },
  ol: {
    paddingLeft: '1rem !important',
  },
});

export const HeaderCellWithIcon = styled.div({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
});

export const PreviewPaymentTypeLabel = styled.b({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '0.875rem',
});

export const filtersSelectStyles: SxProps = {
  lineHeight: '0.875rem',
  fontSize: '0.75rem',
  fontFamily: 'Work Sans',
  color: palette.greyscale.persianBlue,
  padding: '0.5rem 0 0 1rem',
  borderRadius: '0.75rem',
};

export const actionMenuItemStyles: SxProps = {
  fontSize: '0.75rem',
  fontFamily: 'Work Sans',
  color: palette.greyscale.UCLABlue,
  padding: '.68rem 1rem',
};

export const actionMenuItemIconStyles = {
  marginInlineEnd: '0.5rem',
  color: palette.greyscale.UCLABlue,
  fontSize: '1rem',
};

export const ContractorCardContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '0.5rem',
  width: '100%',
});

export const ContractorInfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  h2: {
    fontSize: '1rem',
    fontWeight: 600,
    color: palette.primary.genericViridian,
    marginBottom: '0.5rem',
  },
  p: {
    fontSize: '0.75rem',
    color: palette.greyscale.UCLABlue,
  },
});

export const StyledActionsContainer = styled.div({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '0.25rem',
});

export const StyledIconContainer = styled.div({
  borderRadius: '0.25rem',
  cursor: 'pointer',
  width: '1rem',
  height: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    color: palette.greyscale.UCLABlue,
    fontSize: '1rem',
  },
  '&:hover': {
    background: palette.tertiary.aquaHaze,
  },
  '&:hover svg': {
    color: palette.primary.genericViridian,
  },
});

export const FormContainer = styled.div({
  marginTop: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
});

export const StyledDataWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontFamily: 'Work Sans',
  fontWeight: '400',
  lineHeight: '1rem',
  fontSize: '0.875rem',
  padding: '1rem 0',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${palette.greyscale.antiFlashWhite}`,
  },
  '@media (max-width: 1200px)': {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '0.75rem',
  },
});

export const StyledDataLabel = styled.span({
  color: palette.greyscale.UCLABlue,
  width: '44%',
});

export const StyledDataValue = styled.span({
  color: palette.greyscale.persianBlue,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '50%',
  '@media (max-width: 1200px)': {
    maxWidth: '100%',
  },
});

export const StyledMainContainer = styled.div({
  display: 'flex',
  minWidth: '100%',
});

export const StyledMainContent = styled.div`
  width: 100%;
  padding: 3.0625rem 1.875rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    padding: 1.5rem 1rem;
  }
`;

export const StyledTitle = styled.h4`
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #0f4539;
  margin: 0 3.75rem 0.75rem 0;
  font-family: Archivo;
  font-weight: 600;
  text-align: left;
  '@media(max-width: 1360px)': {
    font-size: 1.25rem;
  }
`;

export const StyledFormSection = styled.section({
  backgroundColor: '#fbfbfc',
  padding: '1.5rem',
  border: '1px solid #EFF0F5',
  borderRadius: 8,
  marginBottom: '1.5rem',
  '@media(max-width: 1200px)': {
    padding: '1.5rem',
  },
});

export const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StyledLayoutCaptionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
});

export const StyledCountryDataContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '1rem',
  gap: '0.5rem',
  color: palette.greyscale.persianBlue,
});

export const StyledAction = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  cursor: 'pointer',
  color: palette.greyscale.UCLABlue,
  span: { textTransform: 'uppercase', fontWeight: 500, fontSize: 14 },
});

export const StyledSectionTitle = styled.div({
  fontWeight: '600',
  alignItems: 'flex-start',
});

export const StyledSectionSubTitle = styled.div({
  fontFamily: 'Work Sans',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  color: palette.greyscale.UCLABlue,
});

export const TwoFieldInOneRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',

  '& > *': {
    width: '48%',
  },
});

export const radioGroupLabelStyle: SxProps = {
  '& .MuiFormControlLabel-root': {
    width: '48.4%',
    marginBottom: '0 !important',

    '@media (max-width: 480px) ': {
      width: '100%',
      marginBottom: '0.75rem !important',
    },
  },
};

export const checkboxLabelStyle = {
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  color: palette.greyscale.UCLABlue,
};

export const StyledSummaryReviewHeader = styled.header({
  margin: '1.875rem 0 1.5rem 2.875rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StyledSummaryReviewTitle = styled.h2({
  fontSize: '1rem', // 16px to 1rem
  fontFamily: 'Archivo',
  fontWeight: 600,
  color: palette.greyscale.persianBlue,
  lineHeight: '1.5rem',
  marginBottom: '0.625rem', // 10px to 0.625rem
});

export const StyledSummaryReviewSubTitle = styled.p({
  fontSize: '0.875rem',
  fontWeight: 300,
  color: palette.greyscale.UCLABlue,
  lineHeight: '1.43rem',
});

export const StyledControlContainer = styled.section({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Work Sans',
  fontWeight: 400,
});

export const StyledButtonsContainer = styled.section({
  marginTop: '3.25rem',
  paddingLeft: '2.875rem',
  display: 'flex',
  gap: '3.25rem',
  button: {
    width: '12.5rem',
  },
});

export const StyledOnboardingContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
});

export const StyledPageContainer = styled.div({
  fontSize: '0.875rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: palette.secondary.white,
  borderRadius: '1rem',
  gap: '1.5rem',
  padding: '1.5rem',
  width: '28.75rem',
  '@media (max-width: 480px)': {
    width: '100%',
  },
});

export const StyledInfoTip = styled.p({
  fontWeight: 500,
  color: palette.greyscale.persianBlue,
  fontSize: '1.5rem',
  lineHeight: '2rem',
  textAlign: 'center',
  width: '25.75rem',
  maxWidth: '100%',
});

export const StyledInfoDescription = styled.div({
  lineHeight: '1.375rem',
  width: '25.75rem',
  maxWidth: '100%',
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '0.875rem',
  color: palette.greyscale.UCLABlue,
  a: {
    color: palette.primary.cerisePink,
  },
  p: {
    margin: 0,
  },
  strong: {
    color: palette.primary.genericViridian,
    fontWeight: 600,
    a: {
      fontWeight: 400,
      color: 'inherit',
      textDecoration: 'none',
    },
  },
});

export const StyledSupportDescription = styled.div({
  position: 'absolute',
  bottom: '3rem',
  lineHeight: '1.375rem',
  width: '26.1875rem',
  maxWidth: '100%',
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '0.875rem',
  color: palette.greyscale.UCLABlue,
  a: {
    color: palette.primary.cerisePink,
  },
  p: {
    margin: 0,
  },
  strong: {
    color: palette.primary.genericViridian,
    fontWeight: 600,
    a: {
      fontWeight: 400,
      color: 'inherit',
      textDecoration: 'none',
    },
  },
});

export const StyledInfoFooter = styled.div({
  maxWidth: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  button: {
    width: '11.875rem',

    '@media (max-width: 480px) ': {
      width: '100%',
    },
  },
});
