import React from 'react';

import { StyledStatusLabel } from './styled-components';

export const StatusLabel: React.FC<{
  status: string;
}> = ({ status }) => (
  <StyledStatusLabel
    as="div"
    style={{ cursor: 'auto' }}
    data-status={status?.toLocaleLowerCase()}
  >
    {status === 'IN_REVIEW' ? 'REVIEWING' : status}
  </StyledStatusLabel>
);
