import React from 'react';

import { Skeleton } from '@mui/material';
import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { CurrencyFlag } from 'workmotion-design-system';

import { formatAmountWithCurrency } from 'helpers/format-amount-with-currency';
import { en } from 'localization';
import { useGetWalletBalanceByCompanyId } from 'networking/contractor-api/contractor-network-requests';

import {
  WalletBalanceCardContainer,
  WalletBalanceTitle,
  WalletBalanceValue,
} from './styled-components';

const {
  contractor: {
    paymentProfile: {
      customerPaymentProfile: { walletBalanceTitle },
    },
  },
} = en;

const WalletBalanceCard = () => {
  const {
    userIdentityInfo: { companyId },
  } = useUserIdentity();

  const { data, isLoading } = useGetWalletBalanceByCompanyId(companyId);

  return (
    <WalletBalanceCardContainer data-testid="wallet-balance-card">
      <WalletBalanceTitle>{walletBalanceTitle}</WalletBalanceTitle>

      {isLoading ? (
        <Skeleton
          variant="rounded"
          height={30}
          width={250}
          data-testid="balance-loading-skelton"
        />
      ) : (
        <WalletBalanceValue>
          <b>{formatAmountWithCurrency(data?.amount, data?.currency)}</b>

          <CurrencyFlag currency={data?.currency} size={22} />
        </WalletBalanceValue>
      )}
    </WalletBalanceCardContainer>
  );
};

export default WalletBalanceCard;
