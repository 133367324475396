import React, { useState } from 'react';

import { InfoIcon, palette, Drawer } from 'workmotion-design-system';

import { SideMenuContainer } from 'work-statement/styled-components';

interface InfoSideMenuProps {
  sideMenuTitle: string;
  dataTestId?: string;
  children: React.ReactNode;
}

const InfoSideMenu: React.FC<InfoSideMenuProps> = ({
  sideMenuTitle,
  dataTestId,
  children,
}) => {
  const [isMenuOpened, setMenuOpened] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setMenuOpened(open);
  };

  return (
    <>
      <InfoIcon
        height="0.875rem"
        width="0.875rem"
        color={palette.greyscale.sharkMouth}
        onClick={toggleDrawer(true)}
        cursor="pointer"
        data-testid={`${dataTestId}-drawer-info-icon`}
      />

      <Drawer
        anchor="right"
        open={isMenuOpened}
        onClose={toggleDrawer(false)}
        title={sideMenuTitle}
      >
        <SideMenuContainer>{children}</SideMenuContainer>
      </Drawer>
    </>
  );
};

export { InfoSideMenu };
