import React, { useCallback } from 'react';

import { DatePicker } from 'workmotion-design-system';

import { getDateFromIso, getFormattedIsoDate } from 'helpers/get-date-utils';
import { en } from 'localization';

interface StartDueDateFilterProps {
  value: string;
  setStartDueDate: React.Dispatch<React.SetStateAction<string>>;
  endDueDate: string;
}

const {
  invoiceSubmission: {
    table: {
      startDueDateFilter: { placeHolder },
    },
  },
} = en;

const StartDueDateFilter: React.FC<StartDueDateFilterProps> = ({
  value,
  setStartDueDate,
  endDueDate,
}) => {
  const dateChange = useCallback(
    (date: Date) => {
      setStartDueDate(getFormattedIsoDate(date) || '');
    },
    [setStartDueDate]
  );

  return (
    <DatePicker
      value={getDateFromIso(value)}
      onChange={dateChange}
      dataCy={'start-due-date-filter'}
      data-testid={'start-due-date-filter'}
      placeholder={placeHolder}
      maxDate={getDateFromIso(endDueDate)}
    />
  );
};

export { StartDueDateFilter };
