import React from 'react';

import { MenuButton } from '@szhsin/react-menu';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { InvoiceSubmissionStatus } from 'types/invoice-submission';

export const StyledStatusLabel = styled(MenuButton)({
  width: 108,
  height: 24,
  borderRadius: 12,
  fontSize: 12,
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: 'none',
  background: palette.secondary.softMint,
  color: palette.semantic.persianGreen,
  '&[data-status="pending"]': {
    background: palette.secondary.ivoryWillow,
    color: palette.semantic.lemonCurry,
  },
  '&[data-status="approved"]': {
    background: palette.semantic.babyBlue,
    color: palette.semantic.toyBlue,
  },
  '&[data-status="draft"], &[data-status="initiated"]': {
    background: palette.greyscale.ghostWhite,
    color: palette.greyscale.UCLABlue,
  },
  '&[data-status="rejected"], &[data-status="failed"]': {
    background: palette.semantic.fairPink,
    color: palette.semantic.sunsetOrange,
  },
});

export const PreviewStatusLabel: React.FC<{
  status: InvoiceSubmissionStatus;
}> = ({ status }) => (
  <StyledStatusLabel
    as="div"
    style={{ cursor: 'auto' }}
    data-status={status.toLocaleLowerCase()}
  >
    {status.replaceAll('_', ' ')}
  </StyledStatusLabel>
);
