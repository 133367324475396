import React, { lazy, Suspense } from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { Route, Routes } from 'react-router-dom';

import InvoiceSubmission from 'invoice-submission';
import { PageLoadingSpinner } from 'shared-components/page-loading-spinner';

const WorkStatement = lazy(() => import('work-statement'));
const Contractors = lazy(() => import('contractors'));
const ViewContractor = lazy(() => import('contractors/view-contractor'));

const AppRoutes: React.FC = () => {
  const {
    userIdentityInfo: { isHrManager, isAdmin, isCompanyAdmin, isContractor },
  } = useUserIdentity();

  return (
    <Routes>
      {(isHrManager || isAdmin || isCompanyAdmin) && (
        <>
          <Route
            path="/contractors"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <Contractors showContractorsType="ACTIVE" />
              </Suspense>
            }
          />

          <Route
            path="/contractors/onboardings"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <Contractors showContractorsType="ONBOARDING" />
              </Suspense>
            }
          />

          <Route
            path="contractors/:id/*"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <ViewContractor />
              </Suspense>
            }
          />

          <Route
            path="/contractors-work-statements"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <WorkStatement />
              </Suspense>
            }
          />

          <Route
            path="/contractors-invoice-submission"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <InvoiceSubmission />
              </Suspense>
            }
          />
        </>
      )}

      {isContractor && (
        <>
          <Route
            path="/work-statements"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <WorkStatement />
              </Suspense>
            }
          />

          <Route
            path="/invoice-submission"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <h1>Invoice submission</h1>
              </Suspense>
            }
          />
        </>
      )}
    </Routes>
  );
};

export { AppRoutes };
