export const formatNumber = (number: string | number) => {
  if (!Number.isNaN(Number(number))) {
    return Number(number)?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return null;
};
