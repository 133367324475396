import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { isBefore, isEqual } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { useViewContractorContext } from 'contractors/view-contractor/view-contractor-context';
import { PageLoadingSpinner } from 'shared-components/page-loading-spinner';

import { ContractInfoCard } from './contract-info-card ';
import { ContractorInfoCard } from './contractor-info-card';
import { InfoCard } from './info-card';
import { ManagerInfoCard } from './manager-info-card';
import { PageCardList } from './page-card-list';
import { SignatureBanner } from './signature-banner';
import {
  StyledBack,
  StyledBodyContainer,
  StyledMainBody,
  StyledSideBody,
  ViewProfileContainer,
} from './view-profile.styles';

export const Profile = () => {
  const navigate = useNavigate();
  const { contractorInfo, isLoading } = useViewContractorContext();

  const {
    userIdentityInfo: { isContractor },
  } = useUserIdentity();

  if (isLoading || !contractorInfo) {
    return <PageLoadingSpinner />;
  }

  const currentDate = new Date();

  const isActiveContractor =
    contractorInfo?.status === 'CONTRACT_SIGNED' &&
    (isEqual(currentDate, new Date(contractorInfo?.contractInfo?.startDate)) ||
      isBefore(new Date(contractorInfo?.contractInfo?.startDate), currentDate));

  const onBackClick = () => {
    if (isActiveContractor) {
      navigate('/contractors');
    } else {
      navigate('/contractors/onboardings');
    }
  };

  return (
    <ViewProfileContainer>
      <StyledBack onClick={onBackClick}>
        <ArrowBackIcon style={{ fontSize: '1rem' }} />

        <span>BACK</span>
      </StyledBack>

      <StyledBodyContainer>
        <StyledMainBody>
          {!isContractor &&
            !contractorInfo?.documents?.find(
              doc => doc?.documentType === 'EMPLOYMENT_CONTRACT'
            ) && <SignatureBanner contractorId={contractorInfo?.id} />}

          <InfoCard contractorInfo={contractorInfo} />

          <PageCardList contractorId={contractorInfo?.id} />
        </StyledMainBody>

        <StyledSideBody>
          {contractorInfo?.id && (
            <ContractorInfoCard contractorInfo={contractorInfo} />
          )}

          {contractorInfo?.contractInfo?.id && (
            <ContractInfoCard contractInfo={contractorInfo?.contractInfo} />
          )}

          {contractorInfo?.directManager?.email ||
            (contractorInfo?.directManager?.name && (
              <ManagerInfoCard directManager={contractorInfo?.directManager} />
            ))}
        </StyledSideBody>
      </StyledBodyContainer>
    </ViewProfileContainer>
  );
};
