import React, { useCallback } from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalButtonsFooter,
  palette,
  Spinner,
} from 'workmotion-design-system';

import { en } from 'localization';
import { useAdjustInvoiceById } from 'networking/contractor-api/contractor-mutations';
import { useGetInvoiceSubmissionById } from 'networking/contractor-api/contractor-network-requests';
import { WorkStatementPaymentType } from 'types/work-statement';
import { ModalSpinnerWrapper } from 'work-statement/styled-components';

import FormComponents from './form';
import {
  adjustInvoiceSchema,
  AdjustInvoiceType,
  getAdjustInvoiceInitialValues,
} from './form/formik-values-setup';

type ModalProps = {
  isAdjustInvoiceModalOpen: boolean;
  setIsAdjustInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceSubmissionId: string;
  setSelectedInvoiceSubmissionId: React.Dispatch<React.SetStateAction<string>>;
};

const {
  invoiceSubmission: {
    adjustInvoice: {
      cancelButtonLabel,
      submitButtonLabel,
      adjustInvoiceHeader,
      successMessage,
    },
  },
} = en;

const AdjustInvoiceModal: React.FC<ModalProps> = ({
  isAdjustInvoiceModalOpen,
  setIsAdjustInvoiceModalOpen,
  selectedInvoiceSubmissionId,
  setSelectedInvoiceSubmissionId,
}) => {
  const { isLoading: isAdjustingLoading, mutate } = useAdjustInvoiceById(
    selectedInvoiceSubmissionId
  );

  const { data: invoiceDetails, isLoading: invoiceIsLoading } =
    useGetInvoiceSubmissionById(
      selectedInvoiceSubmissionId,
      isAdjustInvoiceModalOpen
    );

  const isHourlyBased =
    invoiceDetails?.paymentType === WorkStatementPaymentType.HOURLY_BASED;

  const handelCloseModal = useCallback(() => {
    setIsAdjustInvoiceModalOpen(false);
    setSelectedInvoiceSubmissionId(null);
  }, [setIsAdjustInvoiceModalOpen, setSelectedInvoiceSubmissionId]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }: FormikHelpers<AdjustInvoiceType>) => {
      const {
        hours,
        minutes,
        externalInvoiceDocument,
        optionalDocuments,
        ...restValues
      } = values;

      const submittedValues = {
        ...restValues,
        hours: isHourlyBased
          ? {
              hours: Number(hours),
              minutes: Number(minutes),
            }
          : null,
        externalInvoiceDocument: !!externalInvoiceDocument?.length
          ? externalInvoiceDocument[0]
          : null,
        optionalDocuments: !!optionalDocuments?.length
          ? optionalDocuments
          : null,
      };

      mutate(submittedValues, {
        onSuccess: () => {
          toast(successMessage, {
            type: 'success',
          });

          handelCloseModal();
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        },
      });
    },
    [isHourlyBased, mutate, handelCloseModal]
  );

  return (
    <Modal
      isOpen={isAdjustInvoiceModalOpen}
      closeModal={handelCloseModal}
      data-testid="adjust-invoice-modal"
      header={adjustInvoiceHeader}
      dataCy={{ modalHeader: 'adjust-invoice-modal-title' }}
    >
      {selectedInvoiceSubmissionId && invoiceIsLoading ? (
        <ModalSpinnerWrapper data-testid="adjust-invoice-spinner">
          <Spinner />
        </ModalSpinnerWrapper>
      ) : (
        <Formik
          initialValues={getAdjustInvoiceInitialValues(invoiceDetails)}
          onSubmit={handleSubmit}
          validationSchema={adjustInvoiceSchema(invoiceDetails)}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <FormComponents invoiceDetails={invoiceDetails} />

              <ModalButtonsFooter
                placement="space-between"
                buttonsInfo={[
                  {
                    text: cancelButtonLabel,
                    btnType: 'secondaryGrey',
                    onClick: handelCloseModal,
                    dataCy: 'adjust-invoice-cancel-action',
                  },
                  {
                    text: (
                      <>
                        {isSubmitting || isAdjustingLoading ? (
                          <Spinner size={30} />
                        ) : (
                          submitButtonLabel
                        )}
                      </>
                    ),
                    type: 'submit',
                    dataCy: 'adjust-invoice-submit-action',
                    disabled: isSubmitting || isAdjustingLoading,
                  },
                ]}
                customStyle={{
                  padding: '2rem 0 0',
                  borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
                }}
              />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AdjustInvoiceModal;
