const enSomethingWentWrong =
  'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.';

export const en = {
  generic: {
    genricError:
      'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.',
    pageNotFoundScreen: {
      title: 'Page not found',
      subTitle1: 'The page you were looking for could not be found.',
      subTitle2:
        'It might have been removed, renamed, or did not exist in the first place.',
    },
    sharedComponents: {
      errorFallback: {
        somethingWentWrong: 'Hmm.. Something went wrong.',
        weHaveBeenNotified:
          'We have been notified with the problem and we will be investigating this as fast as we can',
        retry: 'Retry',
      },
      filtersMenu: {
        applyButton: 'APPLY',
        resetButton: 'RESET',
      },
      contractorSearch: {
        placeHolder: 'Search by contractor name',
        failureMessage: 'Failed to get contractor!',
        noContractorFound: 'No contractor found',
      },
    },
    somethingWentWrong: enSomethingWentWrong,

    yes: 'Yes',
    no: 'No',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
  },
  workStatement: {
    table: {
      headers: {
        projectName: 'Project Name',
        paymentType: 'Payment Type',
        frequency: 'Frequency',
        startDate: 'Start Date',
        rate: 'Rate/Amount',
        contractor: 'Contractor',
        status: 'Status',
        actions: '',
      },
      perMilestoneTooltipText:
        'Please note that the amount displayed reflects the total amount of the work statement. The amount per milestone can be found in the preview.',
      actionItems: {
        preview: 'Preview',
        assignContractor: 'Assign contractor',
        edit: 'Edit',
        finishWork: 'Finish work',
        cancelWork: 'Cancel work',
        submitHours: 'Submit hours',
      },
      statusLegend: {
        legendTitle: 'Status information',
        new: 'This invoice has been partially paid. Please settle the remaining balance or contact your CX representative if you believe this is an error. For details on the payments made, check the payment history.',
        not_assigned:
          'This invoice not been assigned to a contractor yet. Please assign a contractor to this invoice to proceed.',
        in_progress:
          'Your invoice is due for payment. Please ensure it is paid before the due date to avoid any complications.',
        completed:
          'Your invoice has exceeded the payment terms. To avoid late payment fees, please make the payment as soon as possible.',
        cancelled:
          'This invoice has been fully paid, either through a payment or a credit note. For more details, please check the payment history.',
      },
      paymentTypeLegend: {
        legendTitle: 'Payment type explanations',
        fixed_term:
          'This invoice has been fully paid, either through a payment or a credit note. For more details, please check the payment history.',
        hourly_based:
          'Your invoice has exceeded the payment terms. To avoid late payment fees, please make the payment as soon as possible.',
        project_based: `Your invoice is due for payment. Please ensure it is paid before the due date to avoid any complications.
1. Please review all the comments added by the talent to be reviewed.
2. Click on **Edit Contract** to go to the onboarding, and edit all necessary fields.`,
      },
      noWorkStatementTitleMessage: 'No work statement added yet',
      noWorkStatementMessage:
        'You will find the list of Work statements here once we start creating statements',
      paginationLabel: 'work statements',
      createStatement: 'Create statement',
      paymentTypesFilter: {
        options: {
          allPaymentsTypes: 'All payment types',
          fixedTermPayments: 'Fixed-term',
          hourlyBasedPayments: 'Hourly-based',
          projectBasedPayments: 'Project-based',
        },
        placeHolder: 'Select payment type',
      },
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          newLabel: 'New',
          notAssigned: 'Not assigned',
          inProgress: 'In progress',
          completed: 'Completed',
          cancelled: 'Cancelled',
        },
        placeHolder: 'Select status',
      },
    },
    createWorkStatement: {
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      createWorkStatementHeader: 'Create work statement',
      updateWorkStatementHeader: 'Update work statement',
      successMessage: 'Work statement created successfully',
      somethingWentWrong:
        'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.',
      milestoneDisclaimer:
        'Milestones provides a way to work through a large project in smaller steps & provides more payment flexibility.',
      paymentType: {
        fieldName: 'paymentType',
        label: 'Payment type',
        placeHolder: 'Select payment type',
        errorMessage: 'Please select a payment type',
        options: {
          fixedTermPayments: 'Fixed-term',
          hourlyBasedPayments: 'Hourly-based',
          projectBasedPayments: 'Project-based',
        },
      },
      paymentFrequency: {
        fieldName: 'frequency',
        label: 'Payment frequency',
        placeHolder: 'Select payment frequency',
        errorMessage: 'Please select a payment frequency',
        options: {
          weekly: 'Weekly',
          biweekly: 'Biweekly',
          monthly: 'Monthly',
        },
      },
      contractor: {
        fieldName: 'contractorId',
        label: 'Contractor name (optional)',
      },
      amount: {
        fieldName: 'amount',
        label: 'Amount',
        placeHolder: 'Enter the amount',
        errorMessage: 'Please enter the amount',
        positiveValueErrorMessage: 'Amount must be positive',
      },
      currency: {
        fieldName: 'currency',
        label: 'Currency',
        placeHolder: 'Enter the currency',
        errorMessage: 'Please enter the currency',
      },
      startDate: {
        fieldName: 'startDate',
        label: 'Start date',
        placeHolder: 'DD/MM/YYYY',
        errorMessage: 'Please enter the start date',
      },
      endDate: {
        fieldName: 'endDate',
        label: 'End date (optional)',
        placeHolder: 'DD/MM/YYYY',
      },
      rate: {
        fieldName: 'rate',
        label: 'Rate',
        placeHolder: 'Enter the rate',
        errorMessage: 'Please enter the rate',
        positiveValueErrorMessage: 'Rate must be positive',
      },
      projectName: {
        fieldName: 'projectName',
        label: 'Project name',
        placeHolder: 'Project name',
        errorMessage: 'Please enter the project name',
      },
      isMilestones: {
        fieldName: 'isMilestone',
        label: 'Do you want to add milestones?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      milestones: {
        fieldName: 'milestones',
      },
      mileStoneTitle: {
        fieldName: 'title',
        label: 'Milestone title',
        placeHolder: 'Please enter the milestone title',
        errorMessage: 'Please enter the milestone title',
      },
      milestoneDescription: {
        fieldName: 'description',
        label: 'Milestone description',
        placeHolder: 'Please enter the milestone description',
        errorMessage: 'Please enter the milestone description',
      },
      addAnotherMilestoneButtonText: 'Add another milestone',
    },
    workStatementDetailsModal: {
      modalTitle: 'Work statement details',
      fields: {
        status: 'Status',
        milestoneStatus: 'Work statement status',
        requestDate: 'Request date',
        paymentType: 'Payment type',
        paymentFrequency: 'Payment frequency',
        startDate: 'Start date',
        endDate: 'End date',
        amount: 'Amount',
        hours: 'Hours',
        rate: 'Rate',
        ProjectName: 'Project Name',
        isMilestones: 'Milestones',
        milestoneTitle: 'Milestone {{milestoneNumber}} title',
        milestoneAmount: 'Milestone {{milestoneNumber}} amount',
      },
    },
    assignContractorModal: {
      modalTitle: 'Assign contractor',
      modalHeader: 'Please take a moment to assign the work to your contractor',
      modalDescription:
        'Please ensure the work is assigned to your contractor prior to the start date.',
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      successMessage: 'Contractor assigned successfully',
      contractor: {
        fieldName: 'contractorId',
        label: 'Contractor name',
        errorMessage: 'Please select a contractor',
      },
    },
    finishMilestonesModal: {
      modalTitle: 'Finish work',
      modalHeader: 'Please decide which milestones are you done with?',
      modalDescription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      successMessage: 'Milestones finished successfully',
      noMilestonesAreReady: 'No milestones are ready to be finished',
    },
    finishWorkModal: {
      title: 'Confirmation',
      modalDescription:
        'Are you sure that your contractor has done with the required work?',
      submitButtonLabel: 'YES, WORK IS DONE',
      cancelButtonLabel: 'BACK',
      successMessage: 'Work statement finished successfully',
    },
    cancelWorkModal: {
      title: 'Cancel confirmation',
      modalDescription: 'Are you sure you want to cancel this work statement?',
      submitButtonLabel: 'CANCEL WORK',
      cancelButtonLabel: 'BACK',
      successMessage: 'Work statement cancelled successfully',
    },
    submitHoursModal: {
      modalTitle: 'Submit hours',
      modalHeader: 'Please take a moment to submit your working hours',
      modalDescription:
        'Enter your worked hours to help us calculate your total amount in the invoice details section. It is possible to attach files and receipts if needed.',
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      successMessage: 'Hours submitted successfully',
      hours: {
        fieldName: 'hours',
        label: 'Hours',
        placeholder: '0h',
        holeNumberErrorMessage: 'Hours must be a whole number',
        positiveErrorMessage: 'Hours must be a positive number',
        noFilledFieldsErrorMessage:
          'At least one of Hours or Minutes must be filled',
      },
      minutes: {
        fieldName: 'minutes',
        label: 'Minutes',
        placeholder: '0m',
        holeNumberErrorMessage: 'Minutes must be a whole number',
        positiveErrorMessage: 'Minutes must be a positive number',
        maxValueErrorMessage: 'Minutes must be less than 60',
      },
    },
  },
  invoiceSubmission: {
    table: {
      headers: {
        invoiceId: 'Invoice ID',
        submissionDate: 'Submission date',
        dueDate: 'Due date',
        contractor: 'Contractor',
        totalAmount: 'Total amount',
        status: 'Status',
        actions: '',
      },
      paginationLabel: 'Invoice submissions',
      actionItems: {
        preview: 'Preview',
      },
      statusLegend: {
        legendTitle: 'Status explanations',
        draft:
          'This invoice has been created. Please settle the remaining balance or contact your CX representative if you believe this is an error. For details on the payments made, check the payment history.',
        pending:
          'Your invoice is due for payment. Please ensure it is paid before the due date to avoid any complications.',
        approved:
          'This invoice has been partially paid. Please settle the remaining balance or contact your CX representative if you believe this is an error. For details on the payments made, check the payment history.',
        rejected:
          'Your invoice has exceeded the payment terms. To avoid late? payment fees, please make the payment as soon as possible.',
      },
      noInvoiceSubmissionTitleMessage: 'No invoice submission added yet',
      noInvoiceSubmissionMessage:
        'You will find the list of Invoice submission here once we start creating statements',
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          draftLabel: 'Draft',
          pendingLabel: 'Pending',
          approvedLabel: 'Approved',
          rejectedLabel: 'Rejected',
        },
        placeHolder: 'Select status',
      },
      startDueDateFilter: {
        placeHolder: 'Start due date',
      },
      endDueDateFilter: {
        placeHolder: 'End due date',
      },
      invoiceNumberSearch: {
        placeHolder: 'Search by invoice number',
      },
    },
  },
  contractor: {
    table: {
      tabs: {
        onboardingTab: 'Onboardings',
        activeTab: 'Active',
      },
      headers: {
        contractorName: 'Contractor',
        country: 'Country',
        jobTitle: 'Job Title',
        startDate: 'Start Date',
        endDate: 'End Date',
        onboardingtimeLine: 'Onboarding Timeline',
        actions: '',
      },
      actionItems: {
        viewProfile: 'View Profile',
        viewTimeline: 'View Timeline',
        continueAction: 'Continue',
        deleteAction: 'Delete',
      },
      activeContractors: {
        noActiveContractorsTitleMessage: 'No active contractors yet',
        noActiveContractorsMessage:
          'You will find the list of active contractors here once we finish onboarding',
      },
      onboardingContractors: {
        noOnboardingContractorsTitleMessage: 'No onboarding contractors yet',
        noOnboardingContractorsMessage:
          'You will find the list of onboarding contractors here once we start onboarding',
        sideDrawerTitle: 'Onboarding progress timeline',
      },

      paginationLabel: 'records',
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          draft: 'Draft',
          onboarding: 'Onboarding',
          contarctorSigned: 'Contract signed',
        },
        placeHolder: 'Select status',
      },
    },
    viewContractor: {
      tabs: {
        contractInfoLabel: 'Contract info',
        basicInfoLabel: 'Basic info',
        loginInfoLabel: 'Login info',
        bankDetailsLabel: 'Bank info',
        documentsLabel: 'Documents',
        paymentsLabel: 'Payments',
        historyLabel: 'History',
        expensesLabel: 'Expenses',
        holidaysLabel: 'Time Off',
        payslips: 'Payslips',
        Invoices: 'Invoices',
        onboardingData: 'Onboarding Data',
      },
      contractInfo: {
        contractDetails: {
          title: 'contract details',
          contractStartDate: 'Contract Start Date',
          employmentDuration: 'Employment duration',
          contractEndDate: 'Contract End Date',
          contractType: 'Contract Type',
          eligibleForReimbursements: 'Is talent eligible for reimbursements?',
          eligibleToWorkFromHome: 'Is talent eligible to work from home?',
          employmentType: 'Employment Type',
          jobDescription: 'Job Description',
          jobTitle: 'Job Title',
          scopeOfWork: 'Scope of work',
          serviceSector: 'Service sector',
          talentIsLocal: 'Is talent local?',
          talentIsSenior: 'Is talent senior?',
          workingHoursPerWeek: 'Working hours per week',
          workAddress: 'The address the talent will work from',
          costCenter: 'Cost Center/Invoice Reference',
          fullTime: 'Full time',
          partTime: 'Part time',
          permanent: 'Permanent',
          fixedTerm: 'Fixed-term',
          placeHolders: {
            jobDescription: 'Write job Description',
            jobTitle: 'Write job Title',
            workingHoursPerWeek: 'Write working hours',
            workAddress: 'Write address the talent will work from',
            costCenter: 'Write cost Center/Invoice Reference',
            contractStartDate: 'Start Date',
            contractEndDate: 'End Date',
          },
          payoutFrequencyOptions: {
            yearly: 'YEARLY',
            halfYearly: 'Half-Yearly',
            quarterly: 'Quarterly',
            monthly: 'Monthly',
          },
          healthInsurancePlans: {
            standard: 'STANDARD',
            premium: 'PREMIUM',
            premiumPlusMaternity: 'PREMIUM PLUS MATERNITY',
          },
          salaryFrequencyOptions: {
            monthly: 'MONTHLY',
            annual: 'ANNUAL',
          },
        },
        contractSignatoryInfo: {
          title: 'contract signatory info',
          signatoryName: 'Signatory name',
          signatoryEmail: 'Signatory email',
          hasProbationPeriod: 'Talent has a probation period?',
          probationPeriod: 'Probation period',
          probationPeriodFrequency: 'Probation Period frequency',
          terminationNoticePeriod: 'Termination notice period',
          terminationNoticePeriodFrequency:
            'Termination Notice Period frequency',
          anythingElse: 'Other details',
          placeHolders: {
            signatoryName: 'write signatory name',
            signatoryEmail: 'write signatory email',
            terminationNoticePeriod: 'Write Termination Notice Period',
          },
        },
        salaryData: {
          title: 'salary calculator',
          employeeGrossSalary: 'Base salary',
          grossSalaryType: 'Gross salary frequency',
          variableBonus: 'Variable bonus',
          variableBonusFrequency: 'Variable bonus frequency',
          variableBonusStartDate: 'Variable bonus start date',
          annualBonus: 'Annual bonus',
          annualBonusFrequency: 'Annual bonus frequency',
          signOnBonus: 'Sign on bonus',
          allowances: 'Annual allowances',
          allowancesFrequency: 'Annual allowances frequency',
          allowancesStartDate: 'Allowances payroll start date',
          bonusAnythingElse: 'Other Bonus',
          ageRange: 'Talent Age Range (Years)',
          insurancePlan: 'Insurance Plan',
          employeeStockOwnershipPlanProvided:
            'Does the talent has stock ownership plan provided?',
          talentHasPrivateHealthInsurance:
            'Talent has private health insurance',
          currency: 'Currency',
          placeholders: {
            currency: 'Write currency',
            grossSalary: 'Write gross salary',
            annualBonus: 'Write annual bonus',
            variableBonus: 'Write variable bonus',
            signOnBonus: 'Write sign on bonus',
            allowances: 'Write annual allowances',
          },
        },
      },
      basicInfo: {
        title: 'Basic Info',
        personalDetails: {
          title: 'personal details',
          firstName: 'First name',
          lastName: 'Last name',
          mobileNumber: 'Mobile number',
          email: 'Email',
          gender: 'Gender',
          dateOfBirth: 'Date of birth',
          nationality: 'Nationality',
          maritalStatus: 'Marital status',
          numberOfChildren: 'Number of children',
          emergencyContact: {
            fullName: 'Emergency contact full name',
            mobileNumber: 'Emergency contact Mobile number',
            relation: 'Emergency contact relation',
          },
          placeholders: {
            firstName: 'Write first name',
            lastName: 'Write last name',
            mobileNumber: 'write mobile number',
            email: 'Write email',
            nationality: 'Write nationality',
            numberOfChildren: 'Write number of children',
            emergencyContact: {
              fullName: 'Write emergency contact full name',
              mobileNumber: 'Write emergency contact Mobile number',
              relation: 'Write emergency contact relation',
            },
            dateOfBirth: 'Write date of birth',
          },
          genderOptions: {
            male: 'Male',
            female: 'Female',
            other: 'Other',
          },
          martialStatusOptions: {
            married: 'Married',
            single: 'Single',
            divorced: 'Divorced',
            registeredPartnership: 'Registered Partnership',
          },
        },
        residingAddress: {
          title: 'Residing address',
          addressLine1: 'Address line 1',
          addressLine2: 'Address line 2',
          cityState: 'City / State',
          countryName: 'Country',
          postalCode: 'Postal code',
          placeholders: {
            title: 'Write residing address',
            addressLine1: 'Write Address line 1',
            addressLine2: 'Write address line 2',
            cityState: 'Write City / State',
            countryName: 'Write country',
            postalCode: 'Write postal code',
          },
        },
        officialInfo: {
          title: 'Official info',
          passportNumber: 'Passport / ID number',
          highestQualificationLevel: 'Highest qualification level',
          passportIssueDate: 'Passport issue date',
          countryOfResidence: 'country of residence',
          taxIdNumber: 'Tax ID number',
          workPermit: {
            expiryDate: 'Work permit expiry date',
            document: 'Work permit document',
          },
          highestQualificationLevelOptions: {
            primaryEducation: 'Primary Education',
            secondaryEduction: 'Secondary Education',
            vocationalEducation: 'Vocational Education',
            bachelor: 'Bachelor',
            master: 'Master',
            doctorate: 'Doctorate',
          },
          healthInsuranceType: {
            statutory: 'Statutory',
            private: 'Private',
          },
          placeholders: {
            title: 'Write official info',
            passportNumber: 'Write passport number',
            highestQualificationMajor: 'Write highest qualification major',
            socialSecurityNumber: 'Write social security number',
            taxIdNumber: 'Write tax ID number',
            dniNumber: 'Write DNI/NIE',
            nationalInsuranceNumber: 'Write national insurance number',
            sefResidentCardNumber: 'Write SEF resident card number',
            nifNumber: 'Write NIF number',
            taxReimbursementRuling:
              'Write if talent is eligible for so called 30% tax ruling',
            codiceFiscale: 'Write codice fiscale',
            peselNumber: 'Write PESEL number',
            healthInsuranceType: 'Write health insurance type',
            healthInsuranceProviderName: 'Write health insurance provider name',
            panCardNumber: 'Write PAN Card No.',
            uanNumber: 'Write UAN number',
            aadharCardNumber: 'Write AADHAR Card No.',
            oibPinNumber: 'Write OIB (PIN) number',
          },
        },
        countrySpecificInfo: {
          dniNumber: 'DNI/NIE',
          nationalInsuranceNumber: 'National insurance number',
          sefResidentCardNumber: 'SEF Resident Card Number',
          nifNumber: 'NIF number',
          taxReimbursementRuling: 'Eligible for the 30% tax ruling',
          codiceFiscale: 'Codice Fiscale',
          peselNumber: 'PESEL Number',
          healthInsuranceType: 'Type of Health Insurance',
          healthInsuranceProviderName: 'Health Insurance Provider',
          panCardNumber: 'PAN Card No.',
          uanNumber: 'UAN number',
          aadharCardNumber: 'AADHAR Card No.',
          oibPinNumber: 'OIB (PIN) number',
        },
      },
      bankDetails: {
        title: 'Beneficiary details',
        accountHolder: 'Account holder',
        bankName: 'Bank name',
        IBAN: 'IBAN / ABA routing number',
        accountNumber: 'Account number',

        swiftCode: 'Swift code',
        countrySpecificInfo: {
          bankAccountNumber: 'Bank Account Number',
          bankAddress: 'Complete address of the bank branch',
        },
        placeholders: {
          accountHolder: 'Write bank account holder',
          bankName: 'Write bank name',
          IBAN: 'Write IBAN',
          swiftCode: 'Write Swift code',
          accountNumber: 'Write bank account number',
        },
      },
      payments: {
        title: 'Payments',
        paymentsTable: {
          type: 'type',
          amount: 'annual amount',
          frequency: 'frequency',
          startMonth: 'start month',
          endMonth: 'end month',
        },
        noPaymentsInfoScreen: {
          title: 'No payments found',
          subtitle: 'Here where you will find payments list',
        },
        allowanceModal: {
          addHeader: 'Add Allowance',
          editHeader: 'Edit Allowance',
          fields: {
            allowanceTypeField: {
              name: 'allowanceType',
              label: 'What would you like to offer?',
              palcedholder: 'Select one',
            },
            annualAmountField: {
              name: 'amount',
              label: 'Gross annual amount',
              palcedholder: 'eg. 8.000',
            },
            allowancePayrollFrequencyField: {
              name: 'payrollFrequency',
              label: 'Payout frequency',
              palcedholder: 'Select one',
            },
            allowanceStartDateField: {
              name: 'payrollStartDate',
              label: 'Start month',
              palcedholder: 'DD-MM-YYYY',
            },
            allowanceEndDateField: {
              name: 'payrollEndDate',
              label: 'End month',
              palcedholder: 'DD-MM-YYYY',
            },
          },
        },
      },
      editSection: {
        successMessage: 'Talent data updated successfully',
        edit: 'Edit',
        submit: 'Submit',
        cancel: 'Cancel',
      },
      infoSidebar: {
        contractInfoSection: {
          noContractInfoScreen: {
            title: 'No contract info provided yet',
            subTitle:
              'You will find the contract info here once the employee completes their profile.',
          },
        },
        errorGetStatus: 'Failed to fetch employee statuses!',
        errorChangeStatus: 'Failed to update employee status!',
        errorOnboardingProductType:
          'Failed to change employee onboarding type!',
        employeeStatusChangedSuccessfully:
          'Employee status changed successfully',
        employeeOnboardingProductTypeChangedSuccessfully:
          'Employee onboarding product type changed successfully',
        platform: 'Platform',
        transfer: 'Transfer',
        noEmailMessage: 'No email to show',
        contractInfo: 'Contract Info',
        EmployeeInfo: 'Contractor Info',
        holidayLabel: 'Holiday',
        importedLabel: 'Imported',
        noticeLabel: 'Notice',
        requiredEmailMessage: 'Email is required',
        validEmailMessage: 'Enter a valid email',
        requiredDateMessage: 'Date is required',
        saveButton: 'Save',
        cancelButton: 'Cancel',
        endDateInfoSection: {
          endDateTitle: 'Employment end date',
          changeButton: 'Change',
          noDateMessage: 'No end date',
          updateEndDateSuccessMessage:
            'The employment end date has been updated successfully!',
          updateEndDateErrorMessage:
            'Failed to update the employment end date!',
        },
        contactInfoSection: {
          contactInfo: 'Contact Info',
          noPhoneMessage: 'No phone number to show',
          noEmailMessage: 'No email to show',
          changeButton: 'Change',
          cancelButton: 'Cancel',
          saveButton: 'Save',
          contactInfoPlaceholder: 'Contact email',
          updateContactSuccessMessage:
            "The employee's information has been updated successfully!",
          updateContactErrorMessage: 'Failed to update employee contact!',
          noContractInfoScreen: {
            title: 'No contract info provided yet',
            subTitle:
              'You will find the contract info here once the employee completes their profile.',
          },
        },
        partnerInfoSection: {
          partnerInfo: 'Partner Info',
          noEmailMessage: 'No email to show',
          noPartnerMessage: 'No partner to show',
          updatePartnerSuccessMessage:
            'Employee partner was updated successfully!',
          updatePartnerErrorMessage: 'Failed to update employee partner!',
          getPartnersErrorMessage: 'Failed to get employee partner!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
        },
        assignedHRInfoSection: {
          assignedHrManagerInfo: 'HR Manager',
          noEmailMessage: 'No email to show',
          noAssignedHrManagerMessage: 'No manager to show',
          updateAssignedHrSuccessMessage:
            'Employee assigned Hr manager has been updated successfully!',
          updateAssignedHrErrorMessage:
            'Failed to update employee assigned hr manager!',
          getCompanyHrManagersErrorMessage:
            'Failed to get company hr managers!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
        },
        assignedSubCompanySection: {
          assignedEntityInfo: 'Sub Entity',
          noEmailMessage: 'No sub entity to show',
          noAssignedEntityMessage: 'No sub entity to show',
          updateAssignedSubEntitySuccessMessage:
            'Employee assigned sub enitiy has been updated successfully!',
          updateAssignedSubEntityErrorMessage:
            'Failed to update employee assigned sub entity!',
          getCompanySubEntitesErrorMessage: 'Failed to get sub entites!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
          deleteButton: 'Delete',
        },
        managerInfoSection: {
          managerContact: 'Line Manager ',
          managerEmailPlaceholder: 'Manager email',
          updateManagerSuccessMessage:
            'Employee manager was updated successfully!',
          updateManagerErrorMessage: 'Failed to update employee manager!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
          deleteButton: 'Delete',
        },
        downloadsSection: {
          downloads: 'Downloads',
          partnerProjectAgreement: 'Partner Project Agreement',
          projectAgreementSummary: 'Project Agreement Summary',
          downloadDataSheetErrorMessage:
            'Failed to download the Application Data Sheet!',
          downloadSummarySuccessMessage:
            'The Application Summary is downloaded successfully',
          downloadDataSheetSuccessMessage:
            'The Application Data Sheet is downloaded successfully!',
          downloadSectionError: 'Failed to get downloads',
        },
        offboardingModal: {
          modalTitle: 'Talent offboarding',
          offboardingType: {
            label: 'Offboarding type',
            required: 'Offboarding type is required',
            invalid: 'Offboarding type is invalid',
            placeholder: 'Offboarding type',
          },
          offboardingDate: {
            label: 'Employment end date',
            required: 'Employment end date is required',
            invalid: 'Employment end date is invalid',
          },
          additionalComments: {
            label: 'Additional comments',
            placeholder: 'Additional comments',
          },
          messages: {
            submitError: 'Failed to update offboarding reason',
          },
          infoTipMessage: {
            futureDate:
              'To be able to change talent status to offboarding, please choose an employment end date in the future',
            pastDate:
              'To be able to change talent status to contract ended, please choose an employment end date in the past',
          },
        },
      },
      noBankDetailsScreen: {
        title: 'No bank details provided yet',
        subTitle:
          'You will find the bank details here once the employee completes their profile.',
        infoTip: {
          title: 'Editing/Changing Bank Info:',
          text: 'To edit/change your bank details please submit a ticket [here](https://helpdesk.workmotion.com/s/).',
        },
      },
      noBasicInfoScreen: {
        title: 'Your Employee has not provided their details yet',
        subTitle:
          'You will find the basic details once the employee creates their profile here.',
      },
    },
    deleteModal: {
      title: 'Delete confirmation',
      subtitle:
        'Are you sure you want to delete {{name}}? This process cannot be undone.',
      cancelButton: 'Discard',
      deleteButton: 'Confirm',
      deleteSuccessMessage: 'Deleted successfully!',
      deleteErrorMessage: enSomethingWentWrong,
    },
  },
};
