import React, { useCallback, useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { palette, SearchIcon } from 'workmotion-design-system';

import { UsePaginationReturn } from 'helpers/use-pagination';
import { en } from 'localization';
import { Input } from 'shared-components/contractor-search';

const {
  invoiceSubmission: {
    table: {
      invoiceNumberSearch: { placeHolder },
    },
  },
} = en;

const InvoiceNumberSearch: React.FC<{
  handleChangeFilters: UsePaginationReturn['handleChangeFilters'];
  removeFilters: UsePaginationReturn['removeFilters'];
}> = ({ handleChangeFilters, removeFilters }) => {
  const [value, setValue] = useState('');

  const debouncedOnChange = useDebouncedCallback(invoiceNumber => {
    handleChangeFilters({
      invoiceNumber,
    });
  }, 500);

  const onChange = useCallback(
    event => {
      setValue(event.target.value);
      debouncedOnChange(event.target.value);
    },
    [debouncedOnChange]
  );

  const resetSelectedInvoiceNumber = useCallback(() => {
    setValue('');
    removeFilters(['invoiceNumber']);
  }, [removeFilters]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: 40,
        width: '15.5rem',
      }}
    >
      <SearchIcon
        style={{
          position: 'absolute',
          top: 14,
          left: 18,
          fontSize: '.8rem',
          color: palette.greyscale.grainOfSalt,
        }}
      />

      <Input
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        data-testid="invoice-number-search-input"
      />

      {!!value.length && (
        <CloseRoundedIcon
          sx={{
            position: 'absolute',
            top: 11,
            right: 14,
            cursor: 'pointer',
            color: palette.greyscale.sharkMouth,
            fontSize: '1.2rem',
          }}
          onClick={resetSelectedInvoiceNumber}
          data-testid="invoice-number-search-reset-btn"
        />
      )}
    </Box>
  );
};

export default InvoiceNumberSearch;
