import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 412px;
  }
  .Toastify__toast {
    color: ${palette.greyscale.persianBlue};
  }
  .Toastify__toast-body {
    color: ${palette.greyscale.persianBlue};
    padding: 1rem;
    border-radius: 4px;
    font-weight: 400;
    font-size: 0.875rem;
    font-family: 'Work Sans';
    line-height: 1.4rem;
  }

  .Toastify__toast--success {
    background-color: ${palette.tertiary.aquaHaze};
  }

  .Toastify__toast--error {
    background-color: ${palette.semantic.sunsetOrangeSubtle};
  }
  .Toastify__progress-bar--success {
    background-color: ${palette.semantic.persianGreen};
  }
  .Toastify__progress-bar--error {
    background-color: ${palette.semantic.sunsetOrange};
  }
  .Toastify__close-button {
    color: ${palette.greyscale.sharkMouth};
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
    color: ${palette.greyscale.persianBlue};
  }
`;
