import React from 'react';

import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, palette } from 'workmotion-design-system';

import { en } from 'localization';
import { PaymentProfileStatus } from 'types/contractor-payment';

import ApproveIcon from './ApprovalIcon.svg';
import {
  BannerTextField,
  BannerTitle,
  BannerContainer,
  BannerContent,
  BannerIcon,
  BannerContentField,
} from './styled-components';

const {
  contractor: {
    paymentProfile: {
      customerPaymentProfile: {
        createPaymentProfileBanner: {
          title,
          canCreateQuestion,
          companyRepresentative,
          individualApplicant,
          canAccessQuestion,
          hrManager,
          hrAdmin,
          checkCompanyadmin,
          acceptTerms,
          termsAndConditions,
          createButtonLabel,
          resumeButtonLabel,
        },
      },
    },
  },
} = en;

const CreatePaymentProfileBanner: React.FC<{
  status: PaymentProfileStatus;
}> = ({ status }) => {
  const navigate = useNavigate();

  return (
    <BannerContainer>
      <BannerContent>
        <Typography sx={BannerTitle}>{title}</Typography>

        <div style={{ marginBottom: '1rem', lineHeight: '1.375rem' }}>
          <Typography sx={BannerContentField}>{canCreateQuestion}</Typography>

          <Typography sx={BannerTextField}>{companyRepresentative}</Typography>

          <Typography sx={BannerTextField}>
            <span>&emsp;• </span>

            <span>{individualApplicant}</span>
          </Typography>

          <Typography sx={BannerContentField}>{canAccessQuestion}</Typography>

          <Typography sx={BannerTextField}>
            <span>&emsp;• </span>

            <span>{hrManager}</span>
          </Typography>

          <Typography sx={BannerTextField}>
            <span>&emsp;• </span>

            <span>{hrAdmin}</span>
          </Typography>

          <Typography sx={BannerTextField}>
            <span>{checkCompanyadmin}</span>
          </Typography>

          <Typography style={{ marginTop: '1rem' }} sx={BannerTextField}>
            <span>{acceptTerms}</span>

            <span style={{ color: palette.primary.cerisePink }}>
              {termsAndConditions}
            </span>

            <span>{`.`}</span>
          </Typography>
        </div>

        <div>
          <Button
            style={{ borderRadius: '0.75rem' }}
            onClick={() => navigate('customer-beneficiary-onboarding/')}
            data-testid="payment-profile-banner-btn"
            color="primary"
          >
            {status === 'CREATION_STARTED'
              ? resumeButtonLabel
              : createButtonLabel}
          </Button>
        </div>
      </BannerContent>

      <BannerIcon>
        <ApproveIcon />
      </BannerIcon>
    </BannerContainer>
  );
};

export { CreatePaymentProfileBanner };
