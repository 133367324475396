import React, { useCallback, useState, useEffect } from 'react';

import { UsePaginationReturn } from 'helpers/use-pagination';
import { FiltersMenu } from 'shared-components/filters-menu';
import { QueryParameters } from 'types';

import { EndDueDateFilter } from './end-due-date-filter';
import { StartDueDateFilter } from './start-due-date-filter';
import { StatusFilter, StatusFilterEnum } from './status-filter';

interface FiltersMenuProps {
  handleChangeFilters: UsePaginationReturn['handleChangeFilters'];
  removeFilters: UsePaginationReturn['removeFilters'];
  requestParams: QueryParameters;
}

const Filters: React.FC<FiltersMenuProps> = ({
  handleChangeFilters,
  removeFilters,
  requestParams: {
    startDueDate: queryStartDueDate,
    endDueDate: queryEndDueDate,
    status: queryStatus,
  },
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [startDueDate, setStartDueDate] = useState('');

  const [endDueDate, setEndDueDate] = useState('');

  const [status, setStatus] = useState<StatusFilterEnum>();

  const applyFilters = useCallback(() => {
    let params = {};

    const additionalParamsToRemove = [];

    if (status === StatusFilterEnum.ALL) {
      additionalParamsToRemove.push('status');
    }

    if (startDueDate) {
      params = { ...params, startDueDate };
    }

    if (endDueDate) {
      params = { ...params, endDueDate };
    }

    if (status) {
      params = { ...params, status };
    }

    handleChangeFilters(params);

    removeFilters(additionalParamsToRemove);

    setIsMenuOpen(false);
  }, [status, startDueDate, endDueDate, handleChangeFilters, removeFilters]);

  const resetFilters = useCallback(() => {
    setStartDueDate('');
    setEndDueDate('');
    setStatus(null);
    setIsMenuOpen(false);

    removeFilters(['startDueDate', 'endDueDate', 'status']);
  }, [removeFilters]);

  const setSelectedValues = useCallback(() => {
    setStartDueDate(queryStartDueDate);
    setEndDueDate(queryEndDueDate);
    setStatus(queryStatus as StatusFilterEnum);
  }, [queryEndDueDate, queryStartDueDate, queryStatus]);

  useEffect(() => {
    setSelectedValues();
  }, [setSelectedValues]);

  return (
    <FiltersMenu
      isOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
      onReset={resetFilters}
      onApply={applyFilters}
      onClose={setSelectedValues}
    >
      <StatusFilter setStatus={setStatus} value={status} />

      <StartDueDateFilter
        setStartDueDate={setStartDueDate}
        value={startDueDate}
        endDueDate={endDueDate}
      />

      <EndDueDateFilter
        setEndDueDate={setEndDueDate}
        value={endDueDate}
        startDueDate={startDueDate}
      />
    </FiltersMenu>
  );
};

export default Filters;
