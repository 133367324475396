import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useAccessToken } from 'helpers/use-access-token';
import { QueryParameters } from 'types';
import { GetWorkStatementByIdParameters } from 'types/work-statement';

import {
  getAllContractorsClient,
  getContractorClient,
  getWorkStatementById,
  listAllContractorInvoices,
  listAllWorkStatements,
} from './contractor-client';

export const useListAllWorkStatements = () => {
  const { accessToken } = useAccessToken();

  const getAllWorkStatements = async (params: QueryParameters) =>
    listAllWorkStatements(accessToken?.accessToken, params);

  return { getAllWorkStatements };
};

export const useGetWorkStatementById = (selectedWorkStatement: string) => {
  const { accessToken } = useAccessToken();

  const getOneWorkStatement = async (params: GetWorkStatementByIdParameters) =>
    getWorkStatementById(accessToken?.accessToken, params);

  const { data, isLoading } = useQuery({
    queryKey: ['getWorkStatementById', selectedWorkStatement],
    queryFn: () =>
      getOneWorkStatement({ workStatementId: selectedWorkStatement }),
    enabled: !!selectedWorkStatement,
  });

  return { data, isLoading };
};

export const useGetAllContractors = () => {
  const { accessToken } = useAccessToken();

  const getAllContractors = useCallback(
    async (params: QueryParameters) =>
      getAllContractorsClient(accessToken?.accessToken, params),
    [accessToken?.accessToken]
  );

  return { getAllContractors };
};

export const useGetContractor = (contractorId: string) => {
  const { accessToken } = useAccessToken();

  const getContractor = async () =>
    getContractorClient(accessToken?.accessToken, contractorId);

  const { data, isLoading } = useQuery({
    queryKey: ['getContractor', contractorId],
    queryFn: getContractor,
    staleTime: 0,
  });

  return { data, isLoading };
};

export const useListAllInvoiceSubmission = getInvoiceSubmissionParams => {
  const { accessToken } = useAccessToken();

  const getAllInvoiceSubmissions = async (params: QueryParameters) =>
    listAllContractorInvoices(accessToken?.accessToken, params);

  const { data, isLoading } = useQuery({
    queryKey: ['invoiceSubmissionRecords', getInvoiceSubmissionParams],
    queryFn: () => getAllInvoiceSubmissions(getInvoiceSubmissionParams),
  });

  return { data, isLoading };
};
