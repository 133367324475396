import React, { lazy, Suspense } from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { Route, Routes } from 'react-router-dom';

import { PaymentProfile } from 'contractors/payment-profile';
import { Profile } from 'contractors/profile';
import { ViewContractorContextProvider } from 'contractors/view-contractor/view-contractor-context';
import InvoiceSubmission from 'invoice-submission';
import { PageLoadingSpinner } from 'shared-components/page-loading-spinner';

const WorkStatement = lazy(() => import('work-statement'));
const Contractors = lazy(() => import('contractors'));
const ViewContractor = lazy(() => import('contractors/view-contractor'));

const CustomerBeneficiaryOnboarding = lazy(
  () => import('contractors/payment-profile/customer-beneficiary-onboarding')
);

const AppRoutes: React.FC = () => {
  const {
    userIdentityInfo: { isHrManager, isAdmin, isCompanyAdmin, isContractor },
  } = useUserIdentity();

  return (
    <Routes>
      {(isHrManager || isAdmin || isCompanyAdmin) && (
        <>
          <Route
            path="/contractors"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <Contractors showContractorsType="ACTIVE" />
              </Suspense>
            }
          />

          <Route
            path="/contractors/onboardings"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <Contractors showContractorsType="ONBOARDING" />
              </Suspense>
            }
          />

          <Route
            path="contractors/:id/profile"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <ViewContractorContextProvider>
                  <Profile />
                </ViewContractorContextProvider>
              </Suspense>
            }
          />

          <Route
            path="contractors/:id/*"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <ViewContractor />
              </Suspense>
            }
          />

          <Route
            path="/contractors-work-statements"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <WorkStatement />
              </Suspense>
            }
          />

          <Route
            path="/contractors-invoice-submission"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <InvoiceSubmission />
              </Suspense>
            }
          />

          {isHrManager && (
            <>
              <Route
                path="/contractors-payment-profile"
                element={
                  <Suspense fallback={<PageLoadingSpinner />}>
                    <PaymentProfile />
                  </Suspense>
                }
              />

              <Route
                path="contractors-payment-profile/customer-beneficiary-onboarding/*"
                element={
                  <Suspense fallback={<PageLoadingSpinner />}>
                    <CustomerBeneficiaryOnboarding />
                  </Suspense>
                }
              />
            </>
          )}
        </>
      )}

      {isContractor && (
        <>
          <Route
            path="/profile/*"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <ViewContractor />
              </Suspense>
            }
          />

          <Route
            path="/work-statements"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <WorkStatement />
              </Suspense>
            }
          />

          <Route
            path="/invoice-submission"
            element={
              <Suspense fallback={<PageLoadingSpinner />}>
                <InvoiceSubmission />
              </Suspense>
            }
          />
        </>
      )}
    </Routes>
  );
};

export { AppRoutes };
