import React from 'react';

import { formatAmountWithCurrency } from 'helpers/format-amount-with-currency';
import { formatDateInUTC } from 'helpers/get-date-utils';
import { en } from 'localization';
import { InvoicePaymentDetails } from 'types/invoice-submission';
import {
  DetailsModalField,
  DetailsModalLabel,
  DetailsModalValue,
} from 'work-statement/styled-components';

import { SectionSubtitle, SectionTitle } from './styled-components';

const {
  invoiceSubmission: {
    transferPaymentModal: {
      fields: {
        projectName,
        saleDate,
        invoiceAmount,
        transferFrom,
        transferFee,
        totalAmountToBeReceived,
        totalAmountToBePaid,
        transferTo,
        invoiceId,
        milestoneTitle,
        workStatementAmount,
        lineItemsAmount,
        fxRate,
      },
      workStatementAndBeneficiaryTitle,
      workStatementAndBeneficiarySubtitle,
      senderDetailsTitle,
      senderDetailsSubtitle,
    },
  },
} = en;

const DetailsSection: React.FC<{
  data: InvoicePaymentDetails;
}> = ({ data }) => (
  <>
    <SectionTitle>{workStatementAndBeneficiaryTitle}</SectionTitle>

    <SectionSubtitle>{workStatementAndBeneficiarySubtitle}</SectionSubtitle>

    <DetailsModalField>
      <DetailsModalLabel>{transferTo}</DetailsModalLabel>

      <DetailsModalValue>{data?.contractorName}</DetailsModalValue>
    </DetailsModalField>

    <DetailsModalField>
      <DetailsModalLabel>{invoiceId}</DetailsModalLabel>

      <DetailsModalValue>{data?.invoiceNumber}</DetailsModalValue>
    </DetailsModalField>

    {!!data?.milestoneTitle ? (
      <DetailsModalField>
        <DetailsModalLabel>{milestoneTitle}</DetailsModalLabel>

        <DetailsModalValue>{data?.milestoneTitle}</DetailsModalValue>
      </DetailsModalField>
    ) : (
      <DetailsModalField>
        <DetailsModalLabel>{projectName}</DetailsModalLabel>

        <DetailsModalValue>{data?.projectName || '-'}</DetailsModalValue>
      </DetailsModalField>
    )}

    <DetailsModalField>
      <DetailsModalLabel>{saleDate}</DetailsModalLabel>

      <DetailsModalValue>
        {formatDateInUTC(data?.saleDate, 'DATE')}
      </DetailsModalValue>
    </DetailsModalField>

    {!!data?.lineItemsAmount?.amount && (
      <>
        <DetailsModalField>
          <DetailsModalLabel>{workStatementAmount}</DetailsModalLabel>

          <DetailsModalValue>
            {formatAmountWithCurrency(
              data?.workStatementAmount?.amount,
              data?.workStatementAmount?.currency
            )}
          </DetailsModalValue>
        </DetailsModalField>

        <DetailsModalField>
          <DetailsModalLabel>{lineItemsAmount}</DetailsModalLabel>

          <DetailsModalValue>
            {formatAmountWithCurrency(
              data?.lineItemsAmount?.amount,
              data?.lineItemsAmount?.currency
            )}
          </DetailsModalValue>
        </DetailsModalField>
      </>
    )}

    <DetailsModalField>
      <DetailsModalLabel>{invoiceAmount}</DetailsModalLabel>

      <DetailsModalValue data-is-amount>
        {formatAmountWithCurrency(
          data?.invoiceAmount?.amount,
          data?.invoiceAmount?.currency
        )}
      </DetailsModalValue>
    </DetailsModalField>

    {!!data?.fxRate && (
      <DetailsModalField>
        <DetailsModalLabel>{fxRate}</DetailsModalLabel>

        <DetailsModalValue>{`1 ${data?.invoiceAmount?.currency} = ${data?.fxRate} ${data?.totalAmountToBeReceived?.currency}`}</DetailsModalValue>
      </DetailsModalField>
    )}

    <DetailsModalField>
      <DetailsModalLabel>{totalAmountToBeReceived}</DetailsModalLabel>

      <DetailsModalValue data-is-amount>
        {formatAmountWithCurrency(
          data?.totalAmountToBeReceived?.amount,
          data?.totalAmountToBeReceived?.currency
        )}
      </DetailsModalValue>
    </DetailsModalField>

    <SectionTitle>{senderDetailsTitle}</SectionTitle>

    <SectionSubtitle>{senderDetailsSubtitle}</SectionSubtitle>

    <DetailsModalField>
      <DetailsModalLabel>{transferFrom}</DetailsModalLabel>

      <DetailsModalValue>{data?.transferFrom}</DetailsModalValue>
    </DetailsModalField>

    <DetailsModalField>
      <DetailsModalLabel>{invoiceAmount}</DetailsModalLabel>

      <DetailsModalValue>
        {formatAmountWithCurrency(
          data?.invoiceAmount?.amount,
          data?.invoiceAmount?.currency
        )}
      </DetailsModalValue>
    </DetailsModalField>

    <DetailsModalField>
      <DetailsModalLabel>{transferFee}</DetailsModalLabel>

      <DetailsModalValue>
        {formatAmountWithCurrency(
          data?.transferFeeAmount?.amount,
          data?.transferFeeAmount?.currency
        )}
      </DetailsModalValue>
    </DetailsModalField>

    <DetailsModalField>
      <DetailsModalLabel>{totalAmountToBePaid}</DetailsModalLabel>

      <DetailsModalValue data-is-amount>
        {formatAmountWithCurrency(
          data?.totalAmountToBePaid?.amount,
          data?.totalAmountToBePaid?.currency
        )}
      </DetailsModalValue>
    </DetailsModalField>
  </>
);

export default DetailsSection;
