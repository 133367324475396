import React from 'react';

import { Box, Skeleton } from '@mui/material';

import {
  DetailsModalField,
  DetailsModalLabel,
  DetailsModalValue,
} from 'work-statement/styled-components';

const getWidths = index => ({
  titleWidth: index % 2 === 0 ? 110 : 130,
  valueWidth: index % 2 !== 0 ? 110 : 130,
});

const ModalSkeletonLoader = ({
  fields = 8,
  dataTestid = 'modal-skeleton-loader',
}) => (
  <Box sx={{ paddingTop: '2rem' }} data-testid={dataTestid}>
    {Array.from({ length: fields }).map((_, index) => {
      const { titleWidth, valueWidth } = getWidths(index);

      return (
        <DetailsModalField key={index}>
          <DetailsModalLabel>
            <Skeleton width={titleWidth} height={30} />
          </DetailsModalLabel>

          <DetailsModalValue>
            <Skeleton width={valueWidth} height={30} />
          </DetailsModalValue>
        </DetailsModalField>
      );
    })}
  </Box>
);

export default ModalSkeletonLoader;
