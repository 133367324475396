import { formatNumber } from './number-formatting';

export const formatAmountWithCurrency = (
  amount?: number,
  currency?: string
): string => {
  if (amount == null || Number.isNaN(amount)) return '-';

  const formattedAmount = formatNumber(amount);
  if (formattedAmount === null) return '-';

  return currency ? `${formattedAmount} ${currency}` : formattedAmount;
};
