import { useCallback } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { handleRequestError } from 'helpers/handle-request-error';
import { useAccessToken } from 'helpers/use-access-token';
import { en } from 'localization';
import {
  AddBeneficiaryRequest,
  AdditionalInformationRequest,
  ApplicantDetailsRequest,
  BusinessDetailsRequest,
  CustomerOnboardingIndividualsDocumentUpload,
  KycResultRequest,
  StakeholdersDetailsResponse,
} from 'types/contractor-payment';

import {
  addAdditionalInformationDetailsClient,
  addApplicantDetailsClient,
  addBeneficiaryDetailsClient,
  addBusinessDetailsClient,
  addStakeHoldersDetailsClient,
  deleteApplicantDocumentClient,
  deleteCustomerBusinessDocumentClient,
  deleteStakeholderDocumentClient,
  handleKycResultClient,
  submitCustomerOnboardingClient,
  uploadApplicantDocumentClient,
  uploadBusinessDocumentClient,
  uploadStakeholderDocumentClient,
} from './contractor-payment-client';

const {
  generic: { somethingWentWrong },
} = en;

export const useAddBeneficiaryDetails = (contractorId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: AddBeneficiaryRequest) =>
      addBeneficiaryDetailsClient(accessToken?.accessToken, contractorId, data),
    [accessToken?.accessToken, contractorId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getContractor']);
      queryClient.invalidateQueries(['beneficiarySchema']);
    },
  });
};

export const useAddBusinessDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: BusinessDetailsRequest) =>
      addBusinessDetailsClient(accessToken?.accessToken, companyId, data),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessDetails']);
    },
  });
};

export const useAddApplicantDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: ApplicantDetailsRequest) =>
      addApplicantDetailsClient(accessToken?.accessToken, companyId, data),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getApplicantDetails']);
    },
  });
};

export const useAddStakeHoldersDetailstDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: StakeholdersDetailsResponse) =>
      addStakeHoldersDetailsClient(accessToken?.accessToken, companyId, data),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getStakeHoldersDetails']);
    },
  });
};

export const useAddAdditionalInformationDetails = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: AdditionalInformationRequest) =>
      addAdditionalInformationDetailsClient(
        accessToken?.accessToken,
        companyId,
        data
      ),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getAdditionalInformationDetails']);
    },
  });
};

export const useUploadApplicantDocument = () => {
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const uploadFn = useCallback(
    async ({
      applicantId,
      companyId,
      data,
    }: {
      applicantId: string;
      companyId: string;
      data: CustomerOnboardingIndividualsDocumentUpload;
    }) =>
      uploadApplicantDocumentClient(
        accessToken.accessToken,
        applicantId,
        companyId,
        data
      ),
    [accessToken]
  );

  return useMutation(uploadFn, {
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getDocumentsInformationDetails']);
    },
  });
};

export const useUploadBusinessDocument = () => {
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const uploadFn = useCallback(
    async ({
      companyId,
      data,
    }: {
      companyId: string;
      data: CustomerOnboardingIndividualsDocumentUpload;
    }) =>
      uploadBusinessDocumentClient(accessToken.accessToken, companyId, data),
    [accessToken]
  );

  return useMutation(uploadFn, {
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getDocumentsInformationDetails']);
    },
  });
};

export const useUploadStakeholderDocument = () => {
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const uploadFn = useCallback(
    async ({
      stakeholderId,
      companyId,
      data,
    }: {
      stakeholderId: string;
      companyId: string;
      data: CustomerOnboardingIndividualsDocumentUpload;
    }) =>
      uploadStakeholderDocumentClient(
        accessToken.accessToken,
        stakeholderId,
        companyId,
        data
      ),
    [accessToken]
  );

  return useMutation(uploadFn, {
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getDocumentsInformationDetails']);
    },
  });
};

export const useSubmitCustomerOnboarding = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: any) =>
      submitCustomerOnboardingClient(accessToken?.accessToken, companyId, data),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getPaymentProfileStatus']);
    },
  });
};

export const useDeleteCustomerBusinessDocument = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async ({ documentId, filekey }: { documentId: string; filekey: string }) =>
      deleteCustomerBusinessDocumentClient(
        accessToken?.accessToken,
        companyId,
        documentId,
        filekey
      ),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getDocumentsInformationDetails']);
    },
  });
};

export const useDeleteApplicantDocument = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async ({ documentId, filekey }: { documentId: string; filekey: string }) =>
      deleteApplicantDocumentClient(
        accessToken?.accessToken,
        companyId,
        documentId,
        filekey
      ),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getDocumentsInformationDetails']);
    },
  });
};

export const useDeleteStakeholderDocument = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async ({
      stakeholderId,
      documentId,
      filekey,
    }: {
      stakeholderId: string;
      documentId: string;
      filekey?: string;
    }) =>
      deleteStakeholderDocumentClient(
        accessToken?.accessToken,
        companyId,
        stakeholderId,
        documentId,
        filekey
      ),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getDocumentsInformationDetails']);
    },
  });
};

export const useSubmitKycResult = (companyId: string) => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: KycResultRequest) =>
      handleKycResultClient(accessToken?.accessToken, companyId, data),
    [accessToken?.accessToken, companyId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getPaymentProfileStatus']);
    },
  });
};
