import React from 'react';

import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query';

import { handleRequestError } from 'helpers/handle-request-error';
import { en } from 'localization';
import { RequestError } from 'types';

const {
  generic: { genricError },
} = en;

const onError = (error: RequestError) => {
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    window.location.href = '/';
  } else {
    handleRequestError(error, genricError);
  }
};

export const workmotionDefaultOptions = {
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
      onError,
    },
    mutations: {
      onError,
    },
  },
};

const queryClient = new QueryClient(
  workmotionDefaultOptions as QueryClientConfig
);

export const NetworkContext = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
