import React, { useState } from 'react';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DoneIcon from '@mui/icons-material/Done';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TagIcon from '@mui/icons-material/Tag';
import { Skeleton } from '@mui/material';
import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { CountryFlag, CurrencyFlag, palette } from 'workmotion-design-system';

import { en } from 'localization';
import { useGetVirtualAccountByCompanyId } from 'networking/contractor-api/contractor-network-requests';

import {
  CopyIconContainer,
  SideCard,
  SideInfoTitle,
  SideInfoTitleDetailsButton,
  VirtualAccountInfoItem,
  VirtualAccountInfoValue,
  VirtualAccountInfoValueStyles,
} from './styled-components';
import VirtualAccountDetailsModal from './virtual-account-details-modal';

const {
  contractor: {
    paymentProfile: {
      customerPaymentProfile: {
        virtualAccountInfoCard: { title, detailsButton },
      },
    },
  },
} = en;

export const VirtualAccountInfoCard: React.FC = () => {
  const {
    userIdentityInfo: { companyId },
  } = useUserIdentity();

  const { data, isLoading } = useGetVirtualAccountByCompanyId(companyId);

  const [isCopied, setIsCopied] = useState(false);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const handleCopy = (message: string) => {
    navigator.clipboard.writeText(message);
    setIsCopied(true);

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    return () => clearTimeout(timeout);
  };

  return (
    <>
      <SideCard data-testid="virtual-account-info-card">
        {isLoading ? (
          Array(5)
            .fill('')
            .map((_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                height={20}
                sx={{ marginBottom: '.5rem' }}
                data-testid="virtual-account-loading-skelton"
              />
            ))
        ) : (
          <>
            <SideInfoTitle>
              <b>{title}</b>

              <SideInfoTitleDetailsButton
                onClick={() => setIsDetailsModalOpen(true)}
              >
                <span>{detailsButton}</span>

                <ArrowForwardRoundedIcon
                  sx={{
                    color: palette.primary.genericViridian,
                    fontSize: '1rem',
                    marginInlineStart: '0.5rem',
                  }}
                />
              </SideInfoTitleDetailsButton>
            </SideInfoTitle>

            {!!data?.accountNumber && (
              <VirtualAccountInfoItem>
                <TagIcon
                  style={{
                    color: palette.greyscale.UCLABlue,
                    fontSize: '1rem',
                  }}
                />

                <VirtualAccountInfoValue>
                  {data?.accountNumber}
                </VirtualAccountInfoValue>

                <span>
                  {isCopied ? (
                    <CopyIconContainer>
                      <DoneIcon
                        style={{
                          color: palette.primary.genericViridian,
                        }}
                      />
                    </CopyIconContainer>
                  ) : (
                    <CopyIconContainer
                      onClick={() => handleCopy(data?.accountNumber)}
                    >
                      <FileCopyIcon
                        style={{
                          color: palette.primary.genericViridian,
                        }}
                      />
                    </CopyIconContainer>
                  )}
                </span>
              </VirtualAccountInfoItem>
            )}

            {!!data?.companyName && (
              <VirtualAccountInfoItem>
                <BusinessCenterIcon
                  style={{
                    color: palette.greyscale.UCLABlue,
                    fontSize: '1rem',
                  }}
                />

                <VirtualAccountInfoValue>
                  {data?.companyName}
                </VirtualAccountInfoValue>
              </VirtualAccountInfoItem>
            )}

            {!!data?.currency && (
              <VirtualAccountInfoItem>
                <CurrencyFlag currency={data?.currency} size={22} />

                <VirtualAccountInfoValue>
                  {data?.currency}
                </VirtualAccountInfoValue>
              </VirtualAccountInfoItem>
            )}

            {!!data?.countryIsoCode && (
              <VirtualAccountInfoItem>
                <CountryFlag
                  countryIsoCode={data?.countryIsoCode}
                  labelStyles={{
                    display: 'inline',
                    ...VirtualAccountInfoValueStyles,
                  }}
                  customStyledFlagIcon={{
                    width: '1.38rem',
                    height: '1.38rem',
                    borderRadius: '50%',
                  }}
                />
              </VirtualAccountInfoItem>
            )}
          </>
        )}
      </SideCard>

      <VirtualAccountDetailsModal
        data={data}
        isDetailsModalOpen={isDetailsModalOpen}
        setIsDetailsModalOpen={setIsDetailsModalOpen}
      />
    </>
  );
};
