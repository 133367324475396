import React, { useCallback } from 'react';

import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { Drawer, palette, Spinner } from 'workmotion-design-system';

import { formatAmountWithCurrency } from 'helpers/format-amount-with-currency';
import { formatDateInUTC } from 'helpers/get-date-utils';
import { en } from 'localization';
import { useGetInvoiceTransactionStatusHistory } from 'networking/contractor-api/contractor-network-requests';
import { TransferPaymentStatus } from 'types/invoice-submission';

import { PreviewStatusLabel } from './status-label';
import {
  DrawerContainer,
  DrawerSection,
  ListItem,
  ListItemKey,
  ListItemValue,
  NoTransactionsContainer,
  SpinnerContainer,
} from './styled-components';

type TransferHistoryDrawerProps = {
  isTransferHistoryDrawerOpen: boolean;
  setIsTransferHistoryDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceSubmissionId: string;
  setSelectedInvoiceSubmissionId: React.Dispatch<React.SetStateAction<string>>;
};

const {
  invoiceSubmission: {
    transferPaymentHistory: {
      title,
      fields: { status, transactionDate, transactionId, invoiceAmount },
      noTransactionsMessage,
    },
  },
} = en;

const TransferHistoryDrawer: React.FC<TransferHistoryDrawerProps> = ({
  isTransferHistoryDrawerOpen,
  setIsTransferHistoryDrawerOpen,
  selectedInvoiceSubmissionId,
  setSelectedInvoiceSubmissionId,
}) => {
  const { data, isLoading, isFetching } = useGetInvoiceTransactionStatusHistory(
    selectedInvoiceSubmissionId,
    isTransferHistoryDrawerOpen
  );

  const handelCloseDrawer = useCallback(() => {
    setIsTransferHistoryDrawerOpen(false);
    setSelectedInvoiceSubmissionId(null);
  }, [setIsTransferHistoryDrawerOpen, setSelectedInvoiceSubmissionId]);

  return (
    <Drawer
      anchor="right"
      open={isTransferHistoryDrawerOpen}
      onClose={handelCloseDrawer}
      title={title}
    >
      <DrawerContainer data-testid="transfer-history-drawer">
        {isLoading || isFetching ? (
          <SpinnerContainer data-testid="transfer-history-drawer-spinner">
            <Spinner />
          </SpinnerContainer>
        ) : !!data?.length ? (
          data.map(transaction => (
            <DrawerSection key={transaction?.id}>
              <ListItem>
                <ListItemKey>{status}</ListItemKey>

                <PreviewStatusLabel
                  status={transaction?.status as TransferPaymentStatus}
                />
              </ListItem>

              <ListItem>
                <ListItemKey>{transactionDate}</ListItemKey>

                <ListItemValue>
                  {formatDateInUTC(transaction?.createdAt, 'DATE')}
                </ListItemValue>
              </ListItem>

              <ListItem>
                <ListItemKey>{transactionId}</ListItemKey>

                <ListItemValue>
                  {transaction?.systemReferenceNumber || '-'}
                </ListItemValue>
              </ListItem>

              <ListItem>
                <ListItemKey>{invoiceAmount}</ListItemKey>

                <ListItemValue data-is-amount>
                  {formatAmountWithCurrency(
                    transaction?.amount,
                    transaction?.currency
                  )}
                </ListItemValue>
              </ListItem>
            </DrawerSection>
          ))
        ) : (
          <NoTransactionsContainer>
            <ReceiptLongRoundedIcon
              sx={{
                color: palette.greyscale.UCLABlue,
                fontSize: '1.5rem',
                marginInlineEnd: '0.5rem',
              }}
            />

            <span>{noTransactionsMessage}</span>
          </NoTransactionsContainer>
        )}
      </DrawerContainer>
    </Drawer>
  );
};

export default TransferHistoryDrawer;
