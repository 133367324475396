import React from 'react';

import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { TransferPaymentStatus } from 'types/invoice-submission';

export const StyledStatusLabel = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: 'none',
  width: 108,
  height: 24,
  borderRadius: 12,
  fontSize: 12,
  fontWeight: 400,
  background: palette.greyscale.ghostWhite,
  color: palette.greyscale.UCLABlue,
  '&[data-status="draft"], &[data-status="requested"]': {
    background: palette.greyscale.ghostWhite,
    color: palette.greyscale.UCLABlue,
  },
  '&[data-status="initiated"]': {
    background: palette.semantic.babyBlue,
    color: palette.semantic.toyBlue,
  },
  '&[data-status="sent_to_bank"]': {
    background: palette.secondary.ivoryWillow,
    color: palette.semantic.lemonCurry,
  },
  '&[data-status="paid"]': {
    background: palette.secondary.softMint,
    color: palette.semantic.persianGreen,
  },
  '&[data-status="expired"], &[data-status="cancelled"], &[data-status="returned"], &[data-status="error"], &[data-status="rfi_requested"]':
    {
      background: palette.semantic.fairPink,
      color: palette.semantic.sunsetOrange,
    },
});

export const PreviewStatusLabel: React.FC<{
  status: TransferPaymentStatus;
}> = ({ status }) => (
  <StyledStatusLabel
    data-status={status.toLocaleLowerCase()}
    as="div"
    style={{ cursor: 'auto' }}
  >
    {status.replaceAll('_', ' ')}
  </StyledStatusLabel>
);
