import * as ContractorApiClientTypes from 'networking/contractor-api/contractor-client-types';

export enum InvoiceSubmissionStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  INITIATED = 'INITIATED',
  PAID = 'PAID',
  FAILED = 'FAILED',
}

export enum LineItemTypesEnum {
  ALLOWANCE = 'ALLOWANCE',
  BONUS = 'BONUS',
  COMMISSION = 'COMMISSION',
}

export enum TransferPaymentStatus {
  INITIATED = 'INITIATED',
  SENT_TO_BANK = 'SENT_TO_BANK',
  RETURNED = 'RETURNED',
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  DRAFT = 'DRAFT',
  RFI_REQUESTED = 'RFI_REQUESTED',
  REQUESTED = 'REQUESTED',
}

export type InvoicesResponse =
  ContractorApiClientTypes.Components.Schemas.InvoicesResponse;

export type GetInvoiceByIdParameters =
  ContractorApiClientTypes.Paths.GetInvoiceById.PathParameters;

export type InvoicesDetailsResponse =
  ContractorApiClientTypes.Components.Schemas.InvoicesDetailsResponse;

export type AdjustInvoiceRequest =
  ContractorApiClientTypes.Components.Schemas.AdjustInvoiceRequest;

export type UpdateInvoiceStatusRequest =
  ContractorApiClientTypes.Components.Schemas.UpdateInvoiceStatusRequest;

export type GetInvoicePaymentDetailsParameters =
  ContractorApiClientTypes.Paths.GetInvoicePaymentDetails.PathParameters;

export type InvoicePaymentDetails =
  ContractorApiClientTypes.Components.Schemas.InvoicePaymentDetails;

export type TransactionStatusHistory =
  ContractorApiClientTypes.Components.Schemas.TransactionStatusHistory;

export type PayoutInvoiceParams =
  ContractorApiClientTypes.Paths.PayoutInvoice.PathParameters &
    ContractorApiClientTypes.Paths.PayoutInvoice.HeaderParameters;

export type VirtualAccountResponse =
  ContractorApiClientTypes.Components.Schemas.VirtualAccountResponse;
