import { SxProps } from '@mui/material';
import { MenuButton } from '@szhsin/react-menu';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const KYCBannerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '1rem',
  backgroundColor: palette.tertiary.aquaHaze,
  padding: '1.5rem',
  gap: '1rem',
  width: '100%',
});

export const KYCBannerContent = styled.div({
  width: 'fit-content',
});

export const KYCBannerTitle: SxProps = {
  fontSize: '1rem',
  fontFamily: 'Work Sans',
  fontWeight: 600,
  color: palette.primary.genericViridian,
  lineHeight: '1.5rem',
  marginBottom: '0.75rem',
};

export const KYCBannerTextField: SxProps = {
  fontSize: '0.875rem',
  fontWeight: 400,
  color: palette.greyscale.persianBlue,
  lineHeight: '1.375rem',
  fontFamily: 'Work Sans',
};

export const KYCBannerContentField: SxProps = {
  fontSize: '0.875rem',
  fontWeight: 600,
  color: palette.greyscale.persianBlue,
  lineHeight: '1.375rem',
  fontFamily: 'Work Sans',
};

export const KYCBannerIcon = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const StyledStatusLabel = styled(MenuButton)({
  width: '6.75rem',
  height: '1.5rem',
  borderRadius: '0.75rem',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '0.88rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: 'none',
  color: palette.primary.midnightGreen,
  '&[data-status="in_review"]': {
    background: palette.secondary.ivoryWillow,
    color: palette.semantic.lemonCurry,
  },
  '&[data-status="rejected"], &[data-status="rejected"]': {
    background: palette.semantic.fairPink,
    color: palette.semantic.sunsetOrange,
  },
});

export const StyledPaymentProfileStatusBannerContainer = styled.div({
  width: '100%',
  padding: '2rem',
  backgroundColor: palette.secondary.white,
  borderRadius: '1rem',
  gap: '1rem',

  '@media(max-width:600px)': {
    padding: '1rem',
  },
});

export const StyledTitleContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: 12,

  '@media(max-width:600px)': {
    flexDirection: 'column',
    marginBottom: '1.25rem',
  },
});

export const StyledItemTitleWrapper = styled.h4({
  fontSize: '1rem',
  lineHeight: '1.2rem',
  marginBottom: '1.25rem',
  maxWidth: '75%',

  '@media(max-width:600px)': {
    marginBottom: '0rem',
  },
});

export const StyledItemTitle = styled.span({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: '1.375rem',
  marginRight: '0.5rem',
});

export const StyledItemDescription = styled.p({
  color: palette.greyscale.persianBlue,
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  maxWidth: '60.9375 rem',
});
