import { QueryParameters } from 'types';
import {
  GetWorkStatementByIdParameters,
  SubmitHoursRequest,
  UpdateMilestonesStatusRequest,
  UpdateWorkStatementStatusRequest,
  WorkStatementIdAssigncontractorBody,
  WorkStatementRequest,
} from 'types/work-statement';

import { createClient } from './open-api-client';

export async function listAllWorkStatements(
  token: string,
  params?: QueryParameters
) {
  const client = await createClient(token);

  const response = await client.listAllWorkStatements(params as any);

  return response.data;
}

export async function createWorkStatement(
  data: WorkStatementRequest,
  token: string
) {
  const client = await createClient(token);

  const response = await client.createWorkStatement({}, { ...data });

  return response.data;
}

export async function updateWorkStatement(
  parameters: { workStatementId: string },
  data: WorkStatementRequest,
  token: string
) {
  const client = await createClient(token);

  const response = await client.updateWorkStatement(parameters, { ...data });

  return response.data;
}

export async function getWorkStatementById(
  token: string,
  params?: GetWorkStatementByIdParameters
) {
  const client = await createClient(token);

  const response = await client.getWorkStatementById(params);

  return response.data;
}

export async function getAllContractorsClient(
  token: string,
  params?: QueryParameters
) {
  const client = await createClient(token);

  const response = await client.getAllContractors(params as any);

  return response.data;
}

export async function getContractorClient(token: string, contractorId: string) {
  const client = await createClient(token);

  const response = await client.getContractor({
    contractorId,
  });

  return response.data;
}

export async function deleteContractorClient(
  token: string,
  contractorId: string
) {
  const client = await createClient(token);

  const response = await client.deleteContractor({
    contractorId,
  });

  return response.data;
}

export async function assignContractorToWorkStatement(
  parameters: { workStatementId: string },
  data: WorkStatementIdAssigncontractorBody,
  token: string
) {
  const client = await createClient(token);

  const response = await client.assignContractorToWorkStatement(parameters, {
    ...data,
  });

  return response.data;
}

export async function updateWorkStatementStatus(
  parameters: { workStatementId: string },
  data: UpdateWorkStatementStatusRequest,
  token: string
) {
  const client = await createClient(token);

  const response = await client.updateWorkStatementStatus(parameters, {
    ...data,
  });

  return response.data;
}

export async function updateMilestonesStatus(
  data: UpdateMilestonesStatusRequest,
  token: string
) {
  const client = await createClient(token);

  const response = await client.updateMilestonesStatus(null, {
    ...data,
  });

  return response.data;
}

export async function submitHours(
  parameters: { workStatementId: string },
  data: SubmitHoursRequest,
  token: string
) {
  const client = await createClient(token);

  const response = await client.submitHours(parameters, {
    ...data,
  });

  return response.data;
}

export async function listAllContractorInvoices(
  token: string,
  params?: QueryParameters
) {
  const client = await createClient(token);

  const response = await client.listAllContractorInvoices(params as any);

  return response.data;
}
