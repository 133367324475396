import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'constant';
import { getQueryParams } from 'helpers/url';
import { QueryParameters } from 'types';

interface MapFilter {
  [key: string]: string | Array<string>;
}

interface UsePaginationReturn {
  filter?: MapFilter;
  currentPage: string | null;
  requestParams: QueryParameters;
  currentPageSize: string | null;
  updateURL?: () => void;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  handleChangeFilter: (name: string, value: string) => void;
  handleChangeFilters?: (filters: MapFilter) => void;
  removeFilter?: (name: string) => void;
  removeFilters?: (name: string[]) => void;
  removeAllFilters?: () => void;
}

const usePagination = (
  supportedParamsArray?: Array<string>,
  pageSize?: number,
  entityName?: string,
  isMobile?: boolean,
  defaultPageSize?: number
): UsePaginationReturn => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<MapFilter>({});

  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const paramObject = Object.fromEntries(urlParams as any);

  const currentPageSize = useMemo(
    () =>
      String(
        urlParams.get('size') ||
          pageSize ||
          defaultPageSize ||
          DEFAULT_PAGE_SIZE
      ),
    [urlParams, pageSize, defaultPageSize]
  );

  const queryParams: QueryParameters & { unassigned?: boolean } = useMemo(
    () =>
      getQueryParams(urlParams, supportedParamsArray, Number(currentPageSize)),
    [urlParams, supportedParamsArray, currentPageSize]
  );

  const updateURL = useCallback(() => {
    // remove explicit page (if default) for cleaner url (getQueryParams() will default to page DEFAULT_CURRENT_PAGE)

    if (urlParams.get('page') === `${DEFAULT_CURRENT_PAGE}`) {
      urlParams.delete('page');
    }

    navigate({
      pathname: location.pathname,
      search: `?${urlParams}`,
    });
  }, [navigate, location.pathname, urlParams]);

  const currentPage = useMemo(
    () => urlParams.get('page') || DEFAULT_CURRENT_PAGE,
    [urlParams]
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      urlParams.set('page', newPage.toString());
      updateURL();
    },
    [updateURL, urlParams]
  );

  const handleChangePageSize = useCallback(
    (newPageSize: number) => {
      urlParams.set('size', newPageSize.toString());

      handleChangePage(1);
    },
    [handleChangePage, urlParams]
  );

  const handleChangeFilter = useCallback(
    (name: string, value: string) => {
      const selectedFilter = { [name]: value };
      setFilter(selectedFilter);
      handleChangePage(1);

      urlParams.set(name, value);

      updateURL();
    },
    [handleChangePage, urlParams, updateURL]
  );

  const handleChangeFilters = useCallback(
    (filters: MapFilter) => {
      if (filters) {
        setFilter(filters);
        handleChangePage(1);

        Object.keys(filters).forEach(key => {
          urlParams.set(key, filters[key] as string);
        });

        updateURL();
      }
    },
    [setFilter, handleChangePage, updateURL, urlParams]
  );

  const removeFilters = useCallback(
    (names: string[]) => {
      const currentFilters = { ...filter };

      if (names) {
        names.forEach(name => {
          delete currentFilters[name];
          urlParams.delete(name);
        });
      }

      setFilter(currentFilters);

      updateURL();
    },
    [filter, urlParams, updateURL]
  );

  const removeAllFilters = useCallback(() => {
    removeFilters(Object.keys(filter));
  }, [filter, removeFilters]);

  const removeFilter = useCallback(
    (name: string) => {
      removeFilters([name]);
    },
    [removeFilters]
  );

  // TODO think to enhance
  const getOrderByParam = useMemo(() => {
    if (entityName === 'action-items') {
      return 'state,updatedDate';
    } else if (entityName === 'work-statements') {
      return 'createdAt';
    }

    return queryParams?.orderBy || undefined;
  }, [entityName, queryParams?.orderBy]);

  const getDirectionParam = useMemo(() => {
    if (entityName === 'action-items') {
      return 'desc, desc';
    } else if (entityName === 'work-statements') {
      return 'desc';
    }

    return queryParams?.direction || undefined;
  }, [entityName, queryParams?.direction]);

  const requestParams: any = useMemo(() =>
    // handles pagination to be mapped as in Backend starts from index 0 so need to subtract 1
    {
      const params = {
        ...queryParams,
        orderBy: getOrderByParam,
        direction: getDirectionParam,
      };

      Object.keys(queryParams).forEach(key => {
        if (key === 'page') {
          params.page = queryParams?.page ? queryParams?.page - 1 : 0;
        }

        if (['assigneeId', 'dedicatedAdminId', 'states'].includes(key)) {
          if (queryParams[key as keyof QueryParameters] === 'unassigned') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            params[key as keyof QueryParameters] = undefined;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            params.unassigned = true;
          } else {
            (params[key as keyof QueryParameters] as string) = queryParams[
              key as keyof QueryParameters
            ] as string;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            params.unassigned = undefined;
          }
        }
      });

      return params;
    }, [getDirectionParam, getOrderByParam, queryParams]);

  useEffect(() => {
    Object.keys(paramObject).forEach(key => {
      if (filter[key] !== paramObject[key]) {
        setFilter({ ...filter, [key]: paramObject[key] });
      }
    });

    if (
      Object.keys(paramObject).length === 0 &&
      Object.keys(filter).length > 0
    ) {
      setFilter({});
    }
  }, [filter, paramObject, removeFilter]);

  useEffect(() => {
    if (isMobile) {
      urlParams.delete('page');

      navigate({
        pathname: location.pathname,
        search: `?${urlParams}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return {
    filter,
    currentPage,
    requestParams: requestParams as QueryParameters,
    currentPageSize,
    updateURL,
    handleChangePage,
    handleChangePageSize,
    handleChangeFilter,
    handleChangeFilters,
    removeFilter,
    removeFilters,
    removeAllFilters,
  };
};

export { usePagination };

export type { UsePaginationReturn };
