import {
  ALL_QUERY_PARAM,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
  SUPPORTED_LISTING_PARAMS,
} from 'constant';
import { QueryParameters } from 'types';

// since each entity has its own param filters so we can send supportedParamsArray or use the default of pagination
const getQueryParams = (
  urlParams: URLSearchParams,
  supportedParamsArray?: Array<string>,
  pageSize?: number
): QueryParameters => {
  const SUPPORTED_ENTITY_LISTING_PARAMS =
    supportedParamsArray || SUPPORTED_LISTING_PARAMS;

  const paramObject = Object.fromEntries(urlParams as any);

  const params: QueryParameters = Object.keys(paramObject)
    .filter(
      param =>
        SUPPORTED_ENTITY_LISTING_PARAMS.includes(param) &&
        paramObject[param] !== ALL_QUERY_PARAM
    )
    // make object as {page: 4}
    .map(param => ({
      [param]: paramObject[param],
    }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  const keys = Object.keys(params);

  if (!keys.includes('page')) {
    params.page = DEFAULT_CURRENT_PAGE as unknown as number;
  }

  if (!keys.includes('size')) {
    params.size = pageSize || DEFAULT_PAGE_SIZE;
  }

  return params;
};

export { getQueryParams };
