import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

const ActionsButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2rem',
  height: '2rem',
  border: 0,
  cursor: 'pointer',
  transition: 'background-color 450ms ease-in-out',
  backgroundColor: 'transparent',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#DFECEC',
  },

  '&[data-is-open="true"]': {
    backgroundColor: '#DFECEC',
  },
});

interface ItemActionsDropdownProps {
  menu: React.ReactElement;
  buttonDataTestId?: string;
}

const ItemActionsDropdown: React.FC<ItemActionsDropdownProps> = ({
  menu,
  buttonDataTestId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <ActionsButton
        data-is-open={open}
        type="button"
        onClick={event => setAnchorEl(event.currentTarget)}
        data-testid={buttonDataTestId}
      >
        <MoreVertIcon
          id="more"
          sx={{
            fontSize: '1.5rem',
            color: open
              ? palette.primary.genericViridian
              : palette.greyscale.UCLABlue,
          }}
        />
      </ActionsButton>

      <Menu
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        anchorEl={anchorEl}
        open={open}
        onClick={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            boxShadow: '#525f7f3d 0px 0px 5px',
            borderRadius: '0.6rem',
          },
        }}
      >
        {menu}
      </Menu>
    </div>
  );
};

export { ItemActionsDropdown };
