const enSomethingWentWrong =
  'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.';

export const en = {
  generic: {
    genricError:
      'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.',
    pageNotFoundScreen: {
      title: 'Page not found',
      subTitle1: 'The page you were looking for could not be found.',
      subTitle2:
        'It might have been removed, renamed, or did not exist in the first place.',
    },
    sharedComponents: {
      errorFallback: {
        somethingWentWrong: 'Hmm.. Something went wrong.',
        weHaveBeenNotified:
          'We have been notified with the problem and we will be investigating this as fast as we can',
        retry: 'Retry',
      },
      filtersMenu: {
        applyButton: 'APPLY',
        resetButton: 'RESET',
      },
      contractorSearch: {
        placeHolder: 'Search by contractor name',
        failureMessage: 'Failed to get contractor!',
        noContractorFound: 'No contractor found',
      },
    },
    somethingWentWrong: enSomethingWentWrong,

    yes: 'Yes',
    no: 'No',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    textTruncate: {
      showMore: 'Show more',
      showLess: 'Show less',
    },
  },
  workStatement: {
    table: {
      headers: {
        projectName: 'Project Name',
        paymentType: 'Payment Type',
        frequency: 'Frequency',
        startDate: 'Start Date',
        rate: 'Rate/Amount',
        contractor: 'Contractor',
        status: 'Status',
        actions: '',
      },
      perMilestoneTooltipText:
        'Please note that the amount displayed reflects the total amount of the work statement. The amount per milestone can be found in the preview.',
      actionItems: {
        preview: 'Preview',
        assignContractor: 'Assign contractor',
        edit: 'Edit',
        finishWork: 'Finish work',
        cancelWork: 'Cancel work',
        submitHours: 'Submit hours',
      },
      statusLegend: {
        legendTitle: 'Work Statement Statuses explanations',
        new: 'The work statement has been created and assigned to a contractor but has not yet started.',
        not_assigned:
          'The work statement has been created but has not been assigned to a contractor yet. It cannot be started until a contractor is assigned to it.',
        in_progress:
          'The work statement has been started and is actively being worked on by the contractor.',
        submitted:
          'The contractor has indicated that the work outlined in the work statement is finalized. It is now pending HR review to verify and mark it as completed.',
        canceled: 'The work statement has been canceled before any work began.',
        completed:
          'HR has reviewed the work statement, confirmed its completion, and officially marked it as completed.',
      },
      paymentTypeLegend: {
        legendTitle: 'Payment types explanations',
        fixed_term:
          'Payments for these work statements are done on a recurring basis. The cycle for payments can be weekly, after a calendar week is completed; bi-weekly, every two weeks; or monthly, at the end of each month. This payment type is based on the predefined agreement in the work statement.',
        hourly_based:
          'Payments are determined by the total hours worked submitted by the contractor, multiplied by the hourly rate agreed upon in the work statement. Contractors are required to submit the number of hours worked, and payments will be processed based on this data. The payment cycle can vary and may also be weekly, paid after each calendar week; bi-weekly, every two weeks; or monthly, at the end of each month.',
        project_based: `Payments can be of two types:
1. Standalone: Payments are tied to the completion of the project. The total amount specified in the work statement should be paid only after the complete scope of the project is finalized.
2. Per Milestone: Payments are made as the contractor completes specific milestones defined in the work statement.`,
      },
      noWorkStatementTitleMessage: 'No work statement added yet',
      noWorkStatementMessage:
        'You will find the list of Work statements here once we start creating statements',
      paginationLabel: 'work statements',
      createStatement: 'Create statement',
      paymentTypesFilter: {
        options: {
          allPaymentsTypes: 'All payment types',
          fixedTermPayments: 'Fixed-term',
          hourlyBasedPayments: 'Hourly-based',
          projectBasedPayments: 'Project-based',
        },
        placeHolder: 'Select payment type',
      },
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          newLabel: 'New',
          notAssigned: 'Not assigned',
          inProgress: 'In progress',
          completed: 'Completed',
          canceled: 'Canceled',
          submitted: 'Submitted',
        },
        placeHolder: 'Select status',
      },
    },
    createWorkStatement: {
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      createWorkStatementHeader: 'Create work statement',
      updateWorkStatementHeader: 'Update work statement',
      createWorkStatementSuccessMessage: 'Work statement created successfully',
      updateWorkStatementSuccessMessage: 'Work statement updated successfully',
      somethingWentWrong:
        'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.',
      milestoneDisclaimer:
        'Milestones provides a way to work through a large project in smaller steps & provides more payment flexibility.',
      paymentType: {
        fieldName: 'paymentType',
        label: 'Payment type',
        placeHolder: 'Select payment type',
        errorMessage: 'Please select a payment type',
        options: {
          fixedTermPayments: 'Fixed-term',
          hourlyBasedPayments: 'Hourly-based',
          projectBasedPayments: 'Project-based',
        },
      },
      paymentFrequency: {
        fieldName: 'frequency',
        label: 'Payment frequency',
        placeHolder: 'Select payment frequency',
        errorMessage: 'Please select a payment frequency',
        options: {
          weekly: 'Weekly',
          biweekly: 'Biweekly',
          monthly: 'Monthly',
        },
      },
      contractor: {
        fieldName: 'contractorId',
        label: 'Contractor name (optional)',
      },
      amount: {
        fieldName: 'amount',
        label: 'Amount',
        placeHolder: 'Enter the amount',
        errorMessage: 'Please enter the amount',
        positiveValueErrorMessage: 'Amount must be positive',
      },
      currency: {
        fieldName: 'currency',
        label: 'Currency',
        placeHolder: 'Enter the currency',
        errorMessage: 'Please enter the currency',
      },
      startDate: {
        fieldName: 'startDate',
        label: 'Start date',
        placeHolder: 'DD/MM/YYYY',
        errorMessage: 'Please enter the start date',
      },
      endDate: {
        fieldName: 'endDate',
        label: 'End date (optional)',
        placeHolder: 'DD/MM/YYYY',
      },
      rate: {
        fieldName: 'rate',
        label: 'Rate',
        placeHolder: 'Enter the rate',
        errorMessage: 'Please enter the rate',
        positiveValueErrorMessage: 'Rate must be positive',
      },
      projectName: {
        fieldName: 'projectName',
        label: 'Project name',
        placeHolder: 'Project name',
        errorMessage: 'Please enter the project name',
      },
      projectDescription: {
        fieldName: 'description',
        label: 'Project description',
        placeHolder: 'Project description',
        errorMessage: 'Please enter the project description',
      },
      isMilestones: {
        fieldName: 'isMilestone',
        label: 'Do you want to add milestones?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      milestones: {
        fieldName: 'milestones',
      },
      mileStoneTitle: {
        fieldName: 'title',
        label: 'Milestone title',
        placeHolder: 'Please enter the milestone title',
        errorMessage: 'Please enter the milestone title',
      },
      milestoneDescription: {
        fieldName: 'description',
        label: 'Milestone description',
        placeHolder: 'Please enter the milestone description',
        errorMessage: 'Please enter the milestone description',
      },
      addAnotherMilestoneButtonText: 'Add another milestone',
    },
    workStatementDetailsModal: {
      modalTitle: 'Work statement details',
      fields: {
        status: 'Status',
        milestoneStatus: 'Work statement status',
        requestDate: 'Request date',
        paymentType: 'Payment type',
        paymentFrequency: 'Payment frequency',
        startDate: 'Start date',
        endDate: 'End date',
        amount: 'Amount',
        hours: 'Hours',
        rate: 'Rate',
        ProjectName: 'Project name',
        ProjectDescription: 'Project description',
        isMilestones: 'Milestones',
        milestoneTitle: 'Milestone {{milestoneNumber}} title',
        milestoneDescription: 'Milestone {{milestoneNumber}} description',
        milestoneAmount: 'Milestone {{milestoneNumber}} amount',
      },
    },
    assignContractorModal: {
      modalTitle: 'Assign contractor',
      modalHeader: 'Please take a moment to assign the work to your contractor',
      modalDescription:
        'Please ensure the work is assigned to your contractor prior to the start date.',
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      successMessage: 'Contractor assigned successfully',
      contractor: {
        fieldName: 'contractorId',
        label: 'Contractor name',
        errorMessage: 'Please select a contractor',
      },
    },
    finishMilestonesModal: {
      modalTitle: 'Finish work',
      modalHeader: 'Select the completed milestones',
      modalDescription:
        'Please select which milestones you have already achieved. These will be sent for review to the HR.',
      modalDescriptionForHr:
        'Please select which milestones you want to complete.',
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      successMessage: 'Milestones finished successfully',
      noMilestonesAreReady: 'No milestones are ready to be finished',
    },
    finishWorkModal: {
      title: 'Confirmation',
      modalDescription:
        'Please confirm that the submitted work has been completed.',
      submitButtonLabel: 'YES, WORK IS DONE',
      cancelButtonLabel: 'BACK',
      successMessage: 'Work statement finished successfully',
    },
    cancelWorkModal: {
      title: 'Cancel confirmation',
      modalDescription: 'Are you sure you want to cancel this work statement?',
      submitButtonLabel: 'CANCEL WORK',
      cancelButtonLabel: 'BACK',
      successMessage: 'Work statement canceled successfully',
    },
    submitHoursModal: {
      modalTitle: 'Submit hours',
      modalHeader: 'Submit worked hours',
      modalDescription: `Please enter the number of hours you have worked during the current cycle so the appropriate calculations can be done for your invoice.  
If you have already logged hours in the current cycle, you will see the total amount below - you can add more hours to the total amount by submitting the extra hours. Please note the amount will be reset when a new payment cycle begins.`,
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      successMessage: 'Hours submitted successfully',
      totalSubmittedHours: 'Total submitted hours',
      hours: {
        fieldName: 'hours',
        label: 'Hours',
        placeholder: '0h',
        holeNumberErrorMessage: 'Hours must be a whole number',
        positiveErrorMessage: 'Hours must be a positive number',
        noFilledFieldsErrorMessage:
          'At least one of Hours or Minutes must be filled',
      },
      minutes: {
        fieldName: 'minutes',
        label: 'Minutes',
        placeholder: '0m',
        holeNumberErrorMessage: 'Minutes must be a whole number',
        positiveErrorMessage: 'Minutes must be a positive number',
        maxValueErrorMessage: 'Minutes must be less than 60',
      },
    },
    completePaymentProfileModal: {
      title: 'Action required',
      description:
        'Please note that you must submit your payment profile request before you can create a work statement.',
      submitButtonLabel: 'Go to payment profile',
      cancelButtonLabel: 'Back',
    },
  },
  invoiceSubmission: {
    table: {
      headers: {
        invoiceId: 'Invoice ID',
        requestDate: 'Request date',
        dueDate: 'Due date',
        contractor: 'Contractor',
        totalAmount: 'Total amount',
        status: 'Status',
        actions: '',
      },
      paginationLabel: 'Invoice submissions',
      actionItems: {
        preview: 'Preview',
        downloadInvoice: 'Download invoice',
        adjust: 'Adjust',
        transferPayment: 'Transfer payment',
        reinitiatePayment: 'Reinitiate payment',
        readyForReview: 'Ready for review',
        transferHistory: 'Transfer history',
      },
      statusLegend: {
        legendTitle: 'Invoice Statuses explanations',
        draft:
          'The invoice is automatically generated based on the details from the work statement and, if applicable, the hours submitted by the contractor. The contractor can make adjustments to the invoice during this stage. It remains in draft status for 48 hours from the time of generation unless the contractor marks it as ready for review earlier.',
        pending:
          'The contractor has finalized any necessary adjustments and marked the invoice as ready for review, or the 48-hour draft period has elapsed. The invoice is now awaiting HR review for approval or rejection.',
        approved:
          'HR has reviewed and approved the invoice. This status confirms that the invoice is valid and ready for payment processing.',
        rejected:
          'HR has reviewed and rejected the invoice - the reason for rejection can be found in the preview. The contractor can make further adjustments based on feedback and resubmit the invoice for review.',
        initiated:
          'The payment transaction for the approved invoice has been initiated, and the payment is currently in progress. ',
        paid: 'The payment for the invoice has been successfully completed. The funds have been transferred to the contractor.',
        failed: `The payment for the invoice was not successful. This could be due to various reasons, you can submit a ticket through the [Help Center](https://helpdesk.workmotion.com/s/) to receive more information on the reason for failure. Please reinitiate the payment to complete the transaction.`,
      },
      noInvoiceSubmissionTitleMessage: 'No invoice submission added yet',
      noInvoiceSubmissionMessage:
        'You will find the list of Invoice submission here once we start creating statements',
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          draftLabel: 'Draft',
          pendingLabel: 'Pending',
          approvedLabel: 'Approved',
          rejectedLabel: 'Rejected',
          initiatedLabel: 'Initiated',
          paidLabel: 'Paid',
          failedLabel: 'Failed',
        },
        placeHolder: 'Select status',
      },
      dateTypeFilter: {
        placeHolder: 'Date type',
      },
      startDateFilter: {
        placeHolder: 'Start date',
      },
      endDateFilter: {
        placeHolder: 'End date',
      },
      invoiceNumberSearch: {
        placeHolder: 'Search by invoice number',
      },
    },
    invoiceDetailsModal: {
      modalTitle: 'Invoice submission details',
      fields: {
        status: 'Status',
        invoiceId: 'Invoice ID',
        ProjectName: 'Project name',
        requestDate: 'Request date',
        submissionDate: 'Submission date',
        paymentType: 'Payment type',
        paymentCycle: 'Payment cycle',
        rate: 'Rate',
        workStatementAmount: 'Work statement amount',
        hours: 'Hours',
        vat: 'VAT',
        amount: 'Amount',
        invoice: 'Invoice',
        otherFiles: 'Other files',
        lineItemType: 'Line item type',
        lineItemTitle: 'Line item title',
        lineItemAmount: 'Line item amount',
        rejectionReason: 'Rejection reason',
      },
      modalFooter: {
        actionButtons: {
          adjust: 'Adjust',
          readyForReview: 'Ready for review',
          reject: 'Reject',
          approve: 'Approve',
          transferHistory: 'See transfer history',
          reinitiatePayment: 'Reinitiate payment',
          transferPayment: 'Transfer payment',
        },
        approveInvoiceSuccessMessage:
          'Invoice submission approved successfully',
      },
    },
    transferPaymentModal: {
      modalTitle: 'Transfer payment',
      successMessage: 'Transfer initiated successfully!',
      fields: {
        projectName: 'Project name',
        saleDate: 'Sale date',
        invoiceAmount: 'Invoice amount',
        transferFrom: 'Transfer from',
        transferFee: 'Transfer fee',
        totalAmountToBeReceived: 'Total amount to be received',
        totalAmountToBePaid: 'Total amount to be paid',
        transferTo: 'Transfer to',
        invoiceId: 'Invoice ID',
        milestoneTitle: 'Milestone title',
        workStatementAmount: 'Work statement amount',
        lineItemsAmount: 'Line items amount',
        fxRate: 'FX rate',
      },
      workStatementAndBeneficiaryTitle:
        'Work statement and beneficiary details',
      workStatementAndBeneficiarySubtitle:
        'Find below work statement and beneficiary details',
      senderDetailsTitle: 'Sender details',
      senderDetailsSubtitle: 'Find below the sender details',
      modalFooter: {
        actionButtons: {
          cancel: 'Cancel',
          transfer: 'Transfer',
        },
      },
    },
    adjustInvoice: {
      submitButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
      adjustInvoiceHeader: 'Invoice submission details edit',
      successMessage: 'Invoice submission adjusted successfully',
      amount: {
        fieldName: 'totalAmount',
        label: 'Gross amount',
        placeHolder: 'Enter the gross amount',
        errorMessage: 'Please enter the gross amount',
        positiveValueErrorMessage: 'Gross amount must be positive',
      },
      vat: {
        fieldName: 'vat',
        label: 'Vat',
        placeHolder: 'Enter the vat',
        positiveValueErrorMessage: 'Vat must be positive',
      },
      rate: {
        fieldName: 'rate',
        label: 'Rate',
        placeHolder: 'Enter the rate',
        errorMessage: 'Please enter the rate',
        positiveValueErrorMessage: 'Rate must be positive',
      },
      projectName: {
        fieldName: 'projectName',
        label: 'Project name',
        placeHolder: 'Project name',
        errorMessage: 'Please enter the project name',
      },
      mileStoneTitle: {
        fieldName: 'milestoneTitle',
        label: 'Milestone title',
        placeHolder: 'Please enter the milestone title',
        errorMessage: 'Please enter the milestone title',
      },
      lineItems: {
        fieldName: 'lineItems',
      },
      hasLineItem: {
        fieldName: 'hasLineItems',
        label: 'Do you want to add line item?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      lineItemType: {
        fieldName: 'type',
        label: 'Line item type',
        placeHolder: 'Please select the line item type',
        errorMessage: 'Please enter the line item type',
        options: {
          allowance: 'Allowance',
          bonus: 'Bonus',
          commission: 'Commission',
        },
      },
      lineItemTitle: {
        fieldName: 'title',
        label: 'Line item title',
        placeHolder: 'Please enter the line item title',
        errorMessage: 'Please enter the line item title',
      },
      lineItemAmount: {
        fieldName: 'amount',
        label: 'Line item amount',
        placeHolder: 'Please enter the line item amount',
        errorMessage: 'Please enter the line item amount',
        positiveValueErrorMessage: 'Line item amount must be positive',
      },
      hours: {
        fieldName: 'hours',
        label: 'Hours',
        placeholder: '0h',
        holeNumberErrorMessage: 'Hours must be a whole number',
        positiveErrorMessage: 'Hours must be a positive number',
        noFilledFieldsErrorMessage:
          'At least one of Hours or Minutes must be filled',
      },
      minutes: {
        fieldName: 'minutes',
        label: 'Minutes',
        placeholder: '0m',
        holeNumberErrorMessage: 'Minutes must be a whole number',
        positiveErrorMessage: 'Minutes must be a positive number',
        maxValueErrorMessage: 'Minutes must be less than 60',
      },
      externalInvoiceDocument: {
        fieldName: 'externalInvoiceDocument',
        uploaderTitle: 'UPLOAD INVOICE FILE (OPTIONAL)',
        uploaderDescription: 'PDF, DOC or XLS file. Maximum file size of 10 MB',
        errorMessage: 'Please upload the invoice file',
      },
      otherDocument: {
        fieldName: 'optionalDocuments',
        uploaderTitle: 'UPLOAD OTHER FILES (OPTIONAL)',
        uploaderDescription:
          'You can upload 2 files at once, Max file size of 10 MB',
      },
      invoiceNumber: {
        fieldName: 'externalInvoiceNumber',
        label: 'Add a specific ID to your invoice (optional)',
        placeHolder: 'Invoice number',
      },
      addAnotherLineItemButtonText: 'ADD ANOTHER LINE ITEM',
      invoiceFileDisclaimer:
        'Please be advised that if necessary, you may upload your invoice. Alternatively, an invoice will be automatically generated.',
      otherFilesDisclaimer:
        'For additional clarity, you may upload supporting documents such as activity reports or timesheets.',
    },
    transferPaymentHistory: {
      title: 'Transfer payment history',
      fields: {
        status: 'Status',
        transactionDate: 'Transaction date',
        transactionId: 'Transaction ID',
        invoiceAmount: 'Invoice amount',
      },
      noTransactionsMessage: 'No transactions found.',
    },
    conformReadyForReviewModal: {
      title: 'Confirmation',
      modalDescription:
        'Please confirm the details for the invoice provided are correct and the invoice can be sent to the HR for review.',
      submitButtonLabel: 'Confirm',
      cancelButtonLabel: 'Cancel',
      successMessage: 'Invoice submission is ready for review',
    },
    rejectionReasonModal: {
      modalTitle: 'Rejection reason',
      modalHeader: 'Provide the reason for rejection',
      modalDescription:
        'Please explain why the invoice was rejected. Your feedback will be shared with the contractor to help them make the necessary adjustments and resubmit it for review.',
      submitButtonLabel: 'Reject',
      cancelButtonLabel: 'Back',
      successMessage: 'Invoice submission rejected successfully',
      rejectedReasonField: {
        fieldName: 'rejectionReason',
        label: 'Rejection reason',
        placeholder: 'Please enter the rejection reason',
        errorMessage: 'Please enter the rejection reason',
      },
    },
  },
  contractor: {
    table: {
      tabs: {
        onboardingTab: 'Onboardings',
        activeTab: 'Active',
      },
      headers: {
        contractorName: 'Contractor',
        country: 'Country',
        jobTitle: 'Job Title',
        startDate: 'Start Date',
        endDate: 'End Date',
        onboardingtimeLine: 'Onboarding Timeline',
        actions: '',
      },
      actionItems: {
        viewProfile: 'View Profile',
        viewTimeline: 'View Timeline',
        continueAction: 'Continue',
        deleteAction: 'Delete',
      },
      activeContractors: {
        noActiveContractorsTitleMessage: 'No active contractors yet',
        noActiveContractorsMessage:
          'You will find the list of active contractors here once we finish onboarding',
      },
      onboardingContractors: {
        noOnboardingContractorsTitleMessage: 'No onboarding contractors yet',
        noOnboardingContractorsMessage:
          'You will find the list of onboarding contractors here once we start onboarding',
        sideDrawerTitle: 'Onboarding progress timeline',
      },

      paginationLabel: 'records',
      statusFilter: {
        options: {
          allStatusesTypes: 'All statuses',
          draft: 'Draft',
          onboarding: 'Onboarding',
          contarctorSigned: 'Contract signed',
        },
        placeHolder: 'Select status',
      },
    },
    paymentProfile: {
      paymentBanner: {
        title: 'Hello there,',
        firstSection: {
          title:
            'All payment services are exclusively provided by our trusted partner ',
          nium: 'NIUM',
          subTitle: ', to ensure a smooth and reliable experience.',
        },
        secondSection: {
          question: 'Who Can Create a Payment Profile?',
          the: 'The ',
          hrManager: 'HR Manager',
          title:
            ' creating the payment profile must represent the company as an  ',
          applicant: 'applicant',
          anApplicant: 'An applicant',
          isTheIndividual:
            ' is the individual responsible for completing the NIUM application process. They act as the representative of the customer, providing the required details and documentation for onboarding.',
        },
        thirdSection: {
          question: 'Who Can Access the Payment Profile?',
          the: 'The ',
          hrManager: 'HR Manager',
          companyHrAdmin: 'Company HR Admin',
          hasAccess: 'will also have access.',
          canCheck: 'You can check who the',
          navigate: 'is by navigating to: ',
          click: 'By clicking on',
          createPaymentProfile: 'Create Payment Profile',
          accept: ', you accept',
          niumTermsAndConditions: 'NIUM’s Terms and Conditions',

          title:
            ' who creates the payment profile will be the only person with access to its details in the future. ',
          applicant: 'applicant',
          anApplicant: 'An applicant',
          isTheIndividual:
            ' is the individual responsible for completing the NIUM application process. They act as the representative of the customer, providing the required details and documentation for onboarding.',
        },
        action: 'Create Payment Profile',
      },
      stepsProgress: {
        saveAsDraftLabel: 'Save as draft',
        logoutLabel: 'Logout',
        stepsProgress: 'Step {{currentStepNumber}} of {{totalStepsCount}}',
      },
      beneficiaryCustomerOnboarding: {
        layoutCaption: 'Payment profile creation process',
        steps: {
          businessDetails: {
            title: 'Business Details',
            sections: {
              businessDetails: {
                title: 'Business Details',
                subTitle:
                  'Please enter essential information about the company, including its official name, registration details, and online presence.',
                fields: {
                  businessName: {
                    fieldName: 'businessName',
                    label: 'Business name*',
                    placeholder: 'Business name',
                    helperText:
                      'Enter the official registered name of the company.',
                    errorMessage: 'Business name is required',
                  },
                  businessRegistrationNumber: {
                    fieldName: 'businessRegistrationNumber',
                    label: 'Business registration number*',
                    placeholder: 'Business registration number',
                    helperText:
                      'Provide the unique registration number assigned by the relevant authorities.',
                    errorMessage: 'Business registration number is required',
                  },
                  businessType: {
                    fieldName: 'businessType',
                    label: 'Business type*',
                    placeholder: 'Select business type',
                    helperText: 'Specify the legal structure of the company.',
                    errorMessage: 'Business type is required',
                  },
                  tradeName: {
                    fieldName: 'tradeName',
                    label: 'Trade name*',
                    placeholder: 'Trade name',
                    helperText:
                      'If the business operates under a different name, enter it here.',
                    errorMessage: 'Trade name is required',
                  },
                },
              },
              legalDetails: {
                title: 'Legal Details',
                subTitle:
                  ' Enter the date when the company was officially registered.',
                fields: {
                  registeredCountry: {
                    fieldName: 'registeredCountry',
                    label: 'Registered country*',
                    placeholder: 'Select country',
                    helperText:
                      'Select the country where the business is legally registered.',
                    errorMessage: 'Registered country is required',
                  },
                  registrationDate: {
                    fieldName: 'registrationDate',
                    label: 'Registration date*',
                    placeholder: 'DD/MM/YYYY',
                    helperText:
                      'Enter the date when the company was officially registered.',
                    errorMessage: 'Registration date is required',
                  },
                },
              },
              isSameBusinessAddress: {
                fieldName: 'isSameBusinessAddress',
                label:
                  'Is the business address identical to the registered address?',
              },
              registeredAddress: {
                title: 'Registered Address',
                subTitle:
                  'Please provide the official address where the business is legally registered.',
                fields: {
                  country: {
                    fieldName: 'country',
                    label: 'Country*',
                    placeholder: 'select country',
                    errorMessage: 'Country is required',
                  },
                  street: {
                    fieldName: 'street',
                    label: 'Street*',
                    placeholder: 'Street',
                    errorMessage: 'Street is required',
                  },
                  city: {
                    fieldName: 'city',
                    label: 'City*',
                    placeholder: 'City',
                    errorMessage: 'City is required',
                  },
                  state: {
                    fieldName: 'state',
                    label: 'State*',
                    placeholder: 'State',
                    errorMessage: 'State is required',
                  },
                  postalCode: {
                    fieldName: 'postalCode',
                    label: 'Postal code*',
                    placeholder: 'Postal code',
                    errorMessage: 'Postal code is required',
                  },
                },
              },
              businessAddressDetails: {
                title: 'Business Address',
                subTitle:
                  'Please provide the official address where the business is legally registered.',
                fields: {
                  country: {
                    fieldName: 'businessCountry',
                    label: 'Country*',
                    placeholder: 'select country',
                    helperText:
                      'Select the country where the business is located.',
                    errorMessage: 'Country is required',
                  },
                  street: {
                    fieldName: 'businessStreet',
                    label: 'Street*',
                    placeholder: 'Street',
                    errorMessage: 'Street is required',
                  },
                  city: {
                    fieldName: 'businessCity',
                    label: 'City*',
                    placeholder: 'City',
                    errorMessage: 'City is required',
                  },
                  state: {
                    fieldName: 'businessState',
                    label: 'State*',
                    placeholder: 'State',
                    errorMessage: 'State is required',
                  },
                  postalCode: {
                    fieldName: 'businessPostalCode',
                    label: 'Postal code*',
                    placeholder: 'Postal code',
                    errorMessage: 'Postal code is required',
                  },
                },
              },
              taxDetails: {
                title: 'Tax Details',
                subTitle: `Please enter the tax-related details to help verify the company's compliance with local tax laws.`,
                fields: {
                  taxNumber: {
                    fieldName: 'taxNumber',
                    label: 'Tax number*',
                    placeholder: 'Tax number',
                    errorMessage: 'Tax number is required',
                    helperText:
                      'Enter the tax identification number assigned to the business assigned by the relevant authorities.',
                  },
                  taxCountry: {
                    fieldName: 'taxCountry',
                    label: 'Country*',
                    placeholder: 'Select country',
                    errorMessage: 'Country is required',
                    helperText:
                      'Select the country where the business pays taxes.',
                  },
                },
              },
            },
          },
          stakeholderDetails: {
            title: 'Stakeholder Details',
            sectionKey: 'stakeholders',

            sections: {
              stakeholderDetails: {
                title: 'Individual Stakeholder',
                subTitle:
                  'Please provide details about individuals involved in the business.',
                fields: {
                  stakholderFirstName: {
                    fieldName: 'firstName',
                    label: 'First name*',
                    placeholder: 'first name',
                    helperText: 'Enter the first legal name of the individual',
                    errorMessage: 'First name is required',
                  },
                  stakholderLastName: {
                    fieldName: 'lastName',
                    label: 'Last name*',
                    placeholder: 'Last name',
                    helperText: 'Enter the last legal name of the individual',
                    errorMessage: 'Last name is required',
                  },
                  nationality: {
                    fieldName: 'nationality',
                    label: 'Nationality*',
                    placeholder: 'Nationality',
                    helperText:
                      'Select the individual’s country of citizenship',
                    errorMessage: 'Nationality is required',
                  },
                  dateOfBirth: {
                    fieldName: 'dateOfBirth',
                    label: 'Date of birth*',
                    placeholder: 'Date of birth',
                    helperText:
                      'Provide the birthdate for identity verification',
                    errorMessage: 'Date of birth is required',
                  },
                  birthCountry: {
                    fieldName: 'birthCountry',
                    label: 'Birth country',
                    placeholder: 'Birth country',
                    helperText: `Specify the individual’s country of birth`,
                    errorMessage: 'Birth country is required',
                  },
                },
              },
              applicantContactDetails: {
                title: 'Applicant Contact Details',
                subTitle:
                  ' Please provide contact information for communication and verification.',
                fields: {
                  countryCode: {
                    fieldName: 'countryCode',
                    label: 'Country code*',
                    placeholder: 'Country code',
                    helperText:
                      'Enter the correct country code for the phone number',
                    errorMessage: 'Country code is required',
                  },
                  phone: {
                    fieldName: 'phone',
                    label: 'Phone*',
                    placeholder: 'Phone',
                    helperText: '	Provide the applicant’s phone number',
                    errorMessage: 'Phone is required',
                  },
                  email: {
                    fieldName: 'email',
                    label: 'Email*',
                    placeholder: 'Email',
                    helperText: '	Enter a valid email address for communication',
                    errorMessage: 'Email is required',
                  },
                },
              },
              professionalDetails: {
                title: 'Professional Details',
                sectionKey: 'professionalDetails',
                subTitle:
                  'Specify the individual’s role in the company (e.g., Director, Investor)',
                fields: {
                  position: {
                    fieldName: 'position',
                    label: 'Position*',
                    placeholder: 'Position',
                    helperText:
                      'Specify the individual’s role in the company (e.g., Director, Investor)',
                    errorMessage: 'Position is required',
                  },
                  sharePercentage: {
                    fieldName: 'sharePercentage',
                    label: 'Share percentage',
                    placeholder: 'Share percentage',
                    helperText:
                      'Enter the percentage of shares owned by the individual',
                    errorMessage: 'Share percentage is required',
                  },
                  positionStartDate: {
                    fieldName: 'positionStartDate',
                    label: 'Position start date*',
                    placeholder: 'DD/MM/YYYY',
                    helperText:
                      'Enter the date when the individual started the current role',
                    errorMessage: 'Position start date is required',
                  },
                  isPep: {
                    fieldName: 'isPep',
                    label: 'Politically Exposed Person (PEP) Status*',
                    placeholder: 'Politically Exposed Person (PEP) Status',
                    helperText: 'Specify the applicant’s role in the company',
                    errorMessage:
                      'Politically Exposed Person (PEP) Status is required',
                  },
                },
              },
              taxDetails: {
                title: 'Tax Details',
                sectionKey: 'taxDetails',
                subTitle: `Please enter the tax-related details to help verify the individual’s compliance with local tax laws.`,
                fields: {
                  taxNumber: {
                    fieldName: 'taxNumber',
                    label: 'Tax number*',
                    placeholder: 'Tax number',
                    errorMessage: 'Tax number is required',
                    helperText:
                      'Enter the person’s tax identification number assigned by the relevant authorities',
                  },
                  taxCountry: {
                    fieldName: 'taxCountry',
                    label: 'Country*',
                    placeholder: 'Select country',
                    errorMessage: 'Country is required',
                    helperText:
                      'Select the country where the individual pays taxes',
                  },
                },
              },
              applicantAddress: {
                title: 'Individual Stakeholder Address',
                sectionKey: 'address',
                subTitle:
                  'Please provide the personal or professional address of the individual.',
                fields: {
                  country: {
                    fieldName: 'country',
                    label: 'Country*',
                    placeholder: 'select country',
                    errorMessage: 'Country is required',
                    helperText: '',
                  },
                  street: {
                    fieldName: 'address',
                    label: 'Street*',
                    placeholder: 'Street',
                    errorMessage: 'Street is required',
                  },
                  city: {
                    fieldName: 'city',
                    label: 'City*',
                    placeholder: 'City',
                    errorMessage: 'City is required',
                  },
                  state: {
                    fieldName: 'state',
                    label: 'State*',
                    placeholder: 'State',
                    errorMessage: 'State is required',
                  },
                  postalCode: {
                    fieldName: 'postalCode',
                    label: 'Postal code*',
                    placeholder: 'Postal code',
                    errorMessage: 'Postal code is required',
                  },
                },
              },
              isPep: {
                fieldName: 'isPep',
                label: 'Politically Exposed Person (PEP) Status*',
                placeholder: 'Politically Exposed Person (PEP) Status',
                helperText:
                  'Indicate whether the person holds or has held a high-profile public role',
                errorMessage:
                  'Politically Exposed Person (PEP) Status is required',
              },
              businessDetails: {
                title: 'Business Partner (Shareholder)',
                sectionParentKey: 'businessPartner',
                sectionKey: 'businessDetails',
                subTitle:
                  'Please enter essential information about the company, including its official name, registration details, and online presence.',
                fields: {
                  businessName: {
                    fieldName: 'businessName',
                    label: 'Business name*',
                    placeholder: 'Business name',
                    helperText:
                      'Enter the registered name of the business partner.',
                    errorMessage: 'Business name is required',
                  },
                  businessRegistrationNumber: {
                    fieldName: 'businessRegistrationNumber',
                    label: 'Business registration number*',
                    placeholder: 'Business registration number',
                    helperText:
                      'Provide the registration number of the partner company.',
                    errorMessage: 'Business registration number is required',
                  },
                  businessEntityType: {
                    fieldName: 'businessEntityType',
                    label: 'Business entity type*',
                    placeholder: 'Select business entity type',
                    helperText:
                      'Specify the entity type, if the entity is a shareholder, partner, or other stakeholder.',
                    errorMessage: 'Business type is required',
                  },
                  sharePercentage: {
                    fieldName: 'sharePercentage',
                    label: 'Share percentage',
                    placeholder: 'Share percentage',
                    helperText:
                      'Please indicate how much of the company this partner owns.',
                    errorMessage: 'Share percentage is required',
                  },
                  registrationCountry: {
                    fieldName: 'registrationCountry',
                    label: 'Registered country*',
                    placeholder: 'Select country',
                    helperText:
                      'Select the country where the business is legally registered.',
                    errorMessage: 'Registered country is required',
                  },
                },
              },
            },
          },
          applicantDetails: {
            title: 'Applicant Details',
            sections: {
              applicantDetails: {
                title: 'Applicant Details',
                subTitle:
                  'The applicant is the individual responsible for completing the NIUM application process. They act as the representative of the business, providing the required details and documentation for onboarding. Please provide details of the person submitting this application on behalf of the business.',
                fields: {
                  applicantFirstName: {
                    fieldName: 'firstName',
                    label: 'First name*',
                    placeholder: 'first name',
                    helperText: 'Enter the first legal name of the applicant',
                    errorMessage: 'First name is required',
                  },
                  applicantLastName: {
                    fieldName: 'lastName',
                    label: 'Last name*',
                    placeholder: 'Last name',
                    helperText: 'Enter the last legal name of the applicant',
                    errorMessage: 'Last name is required',
                  },
                  nationality: {
                    fieldName: 'nationality',
                    label: 'Nationality*',
                    placeholder: 'Nationality',
                    helperText: 'Select the applicant’s country of citizenship',
                    errorMessage: 'Nationality is required',
                  },
                  dateOfBirth: {
                    fieldName: 'dateOfBirth',
                    label: 'Date of birth*',
                    placeholder: 'Date of birth',
                    helperText:
                      'Provide the birth date for identity verification',
                    errorMessage: 'Date of birth is required',
                  },
                  birthCountry: {
                    fieldName: 'birthCountry',
                    label: 'Birth country',
                    placeholder: 'Birth country',
                    helperText: `Specify the applicant’s country of birth`,
                    errorMessage: 'Birth country is required',
                  },
                },
              },
              applicantContactDetails: {
                title: 'Applicant Contact Details',
                subTitle:
                  ' Please provide contact information for communication and verification.',
                fields: {
                  countryCode: {
                    fieldName: 'countryCode',
                    label: 'Country code*',
                    placeholder: 'Country code',
                    helperText:
                      'Enter the correct country code for the phone number',
                    errorMessage: 'Country code is required',
                  },
                  phone: {
                    fieldName: 'phone',
                    label: 'Phone*',
                    placeholder: 'Phone',
                    helperText: '	Provide the applicant’s phone number',
                    errorMessage: 'Phone is required',
                  },
                  email: {
                    fieldName: 'email',
                    label: 'Email*',
                    placeholder: 'Email',
                    helperText: '	Enter a valid email address for communication',
                    errorMessage: 'Email is required',
                  },
                },
              },
              applicantProfessionalDetails: {
                title: 'Applicant Professional Details',
                subTitle:
                  ' Please provide professional information about the applicant.',
                fields: {
                  position: {
                    fieldName: 'position',
                    label: 'Position*',
                    placeholder: 'Position',
                    helperText: 'Specify the applicant’s role in the company',
                    errorMessage: 'Position is required',
                  },
                  sharePercentage: {
                    fieldName: 'sharePercentage',
                    label: 'Share percentage',
                    placeholder: 'Share percentage',
                    helperText:
                      'Enter the percentage of shares owned by the applicant',
                    errorMessage: 'Share percentage is required',
                  },
                  positionStartDate: {
                    fieldName: 'positionStartDate',
                    label: 'Position start date*',
                    placeholder: 'DD/MM/YYYY',
                    helperText:
                      'Enter the date when the applicant started the current role',
                    errorMessage: 'Position start date is required',
                  },
                  isPep: {
                    fieldName: 'isPep',
                    label: 'Politically Exposed Person (PEP) Status*',
                    placeholder: 'Politically Exposed Person (PEP) Status',
                    helperText: '',
                    errorMessage:
                      'Politically Exposed Person (PEP) Status is required',
                  },
                },
              },
              applicantAddress: {
                title: 'Applicant Address',
                subTitle: 'Please provide the address of the applicant.',
                fields: {
                  country: {
                    fieldName: 'country',
                    label: 'Country*',
                    placeholder: 'select country',
                    errorMessage: 'Country is required',
                  },
                  street: {
                    fieldName: 'street',
                    label: 'Street*',
                    placeholder: 'Street',
                    errorMessage: 'Street is required',
                  },
                  city: {
                    fieldName: 'city',
                    label: 'City*',
                    placeholder: 'City',
                    errorMessage: 'City is required',
                  },
                  state: {
                    fieldName: 'state',
                    label: 'State*',
                    placeholder: 'State',
                    errorMessage: 'State is required',
                  },
                  postalCode: {
                    fieldName: 'postalCode',
                    label: 'Postal code*',
                    placeholder: 'Postal code',
                    errorMessage: 'Postal code is required',
                  },
                },
              },
            },
          },
          additionalDetails: {
            title: 'Additional Details',
            subtitle:
              'This section captures extra details about the business that may be required for verification.',
            sections: {
              riskAssesment: {
                title: 'Risk Assessment Information',
                subTitle:
                  'Provide key metrics about the company’s size, revenue, and industry for risk evaluation.',
                fields: {
                  totalEmployees: {
                    fieldName: 'riskAssessmentInfo.totalEmployees',
                    label: 'Total Employees*',
                    placeholder: 'Select total employees',
                    helperText:
                      'Enter the total number of employees in the company',
                    errorMessage: 'Total employess is required',
                  },
                  annualTurnover: {
                    fieldName: 'riskAssessmentInfo.annualTurnover',
                    label: 'Annual Turnover*',
                    placeholder: 'Select annual turnover',
                    helperText:
                      'Provide the company’s total revenue for the past year',
                    errorMessage: 'Annual turnover is required',
                  },
                  countryOfOperation: {
                    fieldName: 'riskAssessmentInfo.countriesOfOperations',
                    label: 'Country of operation*',
                    placeholder: 'Select country of operation',
                    helperText:
                      'List the countries where the business actively operates.',
                    errorMessage: 'Country of operation is required',
                  },
                  restrictedCounty: {
                    fieldName: 'riskAssessmentInfo.restrictedCountries',
                    label: 'Restricted country*',
                    placeholder: 'Select restricted country',
                    helperText: '',
                    errorMessage: 'Restricted country is required',
                  },
                  transactionCountry: {
                    fieldName: 'riskAssessmentInfo.transactionCountries',
                    label: 'Transaction country*',
                    placeholder: 'choose transaction country',
                    helperText: '',
                    errorMessage: 'Transaction country is required',
                  },
                  industrySector: {
                    fieldName: 'riskAssessmentInfo.industrySector',
                    label: 'Industry sector*',
                    placeholder: 'Industry sector',
                    helperText: '',
                    errorMessage: 'Industry sector is required',
                  },
                  travelRestrictedCountry: {
                    fieldName: 'riskAssessmentInfo.travelRestrictedCountry',
                    label: 'Travel restricted country*',
                    placeholder: 'choose travel restricted country',
                    helperText: '',
                    errorMessage: 'Travel restricted country is required',
                  },
                  ofacLicensePresent: {
                    fieldName: 'riskAssessmentInfo.ofacLicencePresent',
                    label: 'OFAC license present*',
                    placeholder: 'OFAC license present',
                    helperText: '',
                    errorMessage: 'OFAC license present is required',
                  },
                  intendedUseOfAccount: {
                    fieldName: 'riskAssessmentInfo.intendedUseOfAccount',
                    label: 'Intended use of account*',
                    placeholder: 'Intended use of account',
                    helperText: '',
                    errorMessage: 'Intended use of account is required',
                  },
                },
              },
              expectedAccountUsage: {
                title: 'Expected Account Usage',
                subTitle:
                  'Please provide the official address where the business is legally registered.',
                section: {
                  debit: {
                    title: 'Debit',
                    monthlyTransactionVolume: {
                      fieldName:
                        'expectedAccountUsage.debit.monthlyTransactionVolume',
                      label: 'Monthly transaction Volume*',
                      placeholder: 'Select monthly transaction Volume',
                      errorMessage: 'Monthly transaction volume is required',
                    },
                    monthlyTransactions: {
                      fieldName:
                        'expectedAccountUsage.debit.monthlyTransactions',
                      label: 'Monthly transactions*',
                      placeholder: 'Select monthly transactions',
                      errorMessage: 'Monthly transactions is required',
                    },
                    averageTransactionValue: {
                      fieldName:
                        'expectedAccountUsage.debit.averageTransactionValue',
                      label: 'Average transaction value*',
                      placeholder: 'Select average transaction value',
                      errorMessage: 'Average transaction value is required',
                    },
                    topTransactionCountries: {
                      fieldName:
                        'expectedAccountUsage.debit.topTransactionCountries',
                      label: 'Top transaction country*',
                      placeholder: 'Select country',
                      errorMessage: 'Top transaction country is required',
                    },
                    topBeneficiaries: {
                      fieldName: 'expectedAccountUsage.debit.topBeneficiaries',
                      label: 'Top beneficiary*',
                      placeholder: 'Top beneficiary',
                      errorMessage: 'Top beneficiary is required',
                    },
                  },
                  credit: {
                    title: 'Credit',
                    monthlyTransactionVolume: {
                      fieldName:
                        'expectedAccountUsage.credit.monthlyTransactionVolume',
                    },
                    monthlyTransactions: {
                      fieldName:
                        'expectedAccountUsage.credit.monthlyTransactions',
                    },
                    averageTransactionValue: {
                      fieldName:
                        'expectedAccountUsage.credit.averageTransactionValue',
                    },
                    topTransactionCountries: {
                      fieldName:
                        'expectedAccountUsage.credit.topTransactionCountries',
                    },
                    topRemitters: {
                      fieldName: 'expectedAccountUsage.credit.topRemitters',
                      label: 'Top remitter*',
                      placeholder: 'top remitter',
                      errorMessage: 'Top remitter is required',
                    },
                  },
                  intendedUses: {
                    fieldName: 'expectedAccountUsage.intendedUses',
                    label: 'Intended uses*',
                    placeholder: 'Select intended uses',
                    errorMessage: 'Intended uses is required',
                  },
                  intendedUsesDescription: {
                    fieldName: 'expectedAccountUsage.intendedUsesDescription',
                    label: 'Intended uses description*',
                    placeholder: 'Intended uses description',
                    errorMessage: 'Intended uses description is required',
                  },
                },
              },
              natureOfBusiness: {
                title: 'Nature Of Business',
                section: {
                  industryCodes: {
                    fieldName: 'natureOfBusiness.industryCodes',
                    label: 'Industry code*',
                    placeholder: 'Select industry code',
                    errorMessage: 'Industyr code is required',
                  },
                  industryDescription: {
                    fieldName: 'natureOfBusiness.industryDescription',
                    label: 'Industry description*',
                    placeholder: 'Industry description',
                    errorMessage: 'Industry description is required',
                  },
                },
              },
              professionalDetails: {
                title: 'Professional Details',
                sectionKey: 'professionalDetails',
                subTitle:
                  'Specify the individual’s role in the company (e.g., Director, Investor)',
                fields: {
                  position: {
                    fieldName: 'position',
                    label: 'Position*',
                    placeholder: 'Position',
                    helperText:
                      'Specify the individual’s role in the company (e.g., Director, Investor)',
                    errorMessage: 'Position is required',
                  },
                  sharePercentage: {
                    fieldName: 'sharePercentage',
                    label: 'Share percentage',
                    placeholder: 'Share percentage',
                    helperText:
                      'Enter the percentage of shares owned by the individual',
                    errorMessage: 'Share percentage is required',
                  },
                  positionStartDate: {
                    fieldName: 'positionStartDate',
                    label: 'Position start date*',
                    placeholder: 'DD/MM/YYYY',
                    helperText:
                      'Enter the date when the individual started the current role',
                    errorMessage: 'Position start date is required',
                  },
                  isPep: {
                    fieldName: 'isPep',
                    label: 'Politically Exposed Person (PEP) Status*',
                    placeholder: 'Politically Exposed Person (PEP) Status',
                    helperText: 'Specify the applicant’s role in the company',
                    errorMessage:
                      'Politically Exposed Person (PEP) Status is required',
                  },
                },
              },
              taxDetails: {
                title: 'Tax Details',
                sectionKey: 'taxDetails',
                subTitle: `Please enter the tax-related details to help verify the individual’s compliance with local tax laws.`,
                fields: {
                  taxNumber: {
                    fieldName: 'taxNumber',
                    label: 'Tax number*',
                    placeholder: 'Tax number',
                    errorMessage: 'Tax number is required',
                    helperText:
                      'Enter the person’s tax identification number assigned by the relevant authorities',
                  },
                  taxCountry: {
                    fieldName: 'taxCountry',
                    label: 'Country*',
                    placeholder: 'Select country',
                    errorMessage: 'Country is required',
                    helperText:
                      'Select the country where the individual pays taxes',
                  },
                },
              },
              applicantAddress: {
                title: 'Individual Stakeholder Address',
                sectionKey: 'address',
                subTitle:
                  'Please provide the personal or professional address of the individual.',
                fields: {
                  country: {
                    fieldName: 'country',
                    label: 'Country*',
                    placeholder: 'select country',
                    errorMessage: 'Country is required',
                    helperText: '',
                  },
                  street: {
                    fieldName: 'address',
                    label: 'Street*',
                    placeholder: 'Street',
                    errorMessage: 'Street is required',
                  },
                  city: {
                    fieldName: 'city',
                    label: 'City*',
                    placeholder: 'City',
                    errorMessage: 'City is required',
                  },
                  state: {
                    fieldName: 'state',
                    label: 'State*',
                    placeholder: 'State',
                    errorMessage: 'State is required',
                  },
                  postalCode: {
                    fieldName: 'postalCode',
                    label: 'Postal code*',
                    placeholder: 'Postal code',
                    errorMessage: 'Postal code is required',
                  },
                },
              },
              isPep: {
                fieldName: 'isPep',
                label: 'Politically Exposed Person (PEP) Status*',
                placeholder: 'Politically Exposed Person (PEP) Status',
                helperText:
                  'Indicate whether the person holds or has held a high-profile public role',
                errorMessage:
                  'Politically Exposed Person (PEP) Status is required',
              },
              businessDetails: {
                title: 'Business Partner (Shareholder)',
                sectionParentKey: 'businessPartner',
                sectionKey: 'businessDetails',
                subTitle:
                  'Please enter essential information about the company, including its official name, registration details, and online presence.',
                fields: {
                  businessName: {
                    fieldName: 'businessName',
                    label: 'Business name*',
                    placeholder: 'Business name',
                    helperText:
                      'Enter the registered name of the business partner.',
                    errorMessage: 'Business name is required',
                  },
                  businessRegistrationNumber: {
                    fieldName: 'businessRegistrationNumber',
                    label: 'Business registration number*',
                    placeholder: 'Business registration number',
                    helperText:
                      'Provide the registration number of the partner company.',
                    errorMessage: 'Business registration number is required',
                  },
                  businessEntityType: {
                    fieldName: 'businessEntityType',
                    label: 'Business entity type*',
                    placeholder: 'Select business entity type',
                    helperText:
                      'Specify the entity type, if the entity is a shareholder, partner, or other stakeholder.',
                    errorMessage: 'Business type is required',
                  },
                  sharePercentage: {
                    fieldName: 'sharePercentage',
                    label: 'Share percentage',
                    placeholder: 'Share percentage',
                    helperText:
                      'Please indicate how much of the company this partner owns.',
                    errorMessage: 'Share percentage is required',
                  },
                  registrationCountry: {
                    fieldName: 'registrationCountry',
                    label: 'Registered country*',
                    placeholder: 'Select country',
                    helperText:
                      'Select the country where the business is legally registered.',
                    errorMessage: 'Registered country is required',
                  },
                },
              },
            },
          },
          summaryReview: {
            title: 'Summary of Payment Profile',
            subTitle:
              'Please review and confirm all the details in the summary below.',
            actionItems: {
              reviewSummaryScreen: {
                approveLabel1: `By submitting this form, I confirm that I am authorized by the named applicant company and can act on behalf of the company to enter into an agreement with NIUM and open, manage the allocated Business account with NIUM. I confirm that I have read, understood and accept the NIUM Terms & Conditions, Privacy Policy and applicable disclosure documents & agree on behalf of the company to abide by these Terms & Conditions.`,
                approveLabel2:
                  'By submitting this form, I hereby confirm the accuracy of the data provided.',
                approveRequiredError: 'You must agree on service agreement',
                submitLabel: 'Submit',
                backLabel: 'Back',
                approvalTitle: 'Summary of employment agreement',
                approvalSubtitle:
                  'Please review and confirm all the details below.',
              },
            },
          },
          successfulOnboarding: {
            title:
              'Thank You! Your Payment Profile Application Has Been Submitted Successfully',
            subTitle: `Thank you for completing the application process! Your payment profile application has been successfully submitted.\n
**Next Steps: Complete the Verification Process (KYB)**`,
            actionItems: {
              continueLabel: 'Continue',
            },
          },
          actionsButtons: {
            next: {
              label: 'NEXT STEP',
              name: 'submit',
            },
            back: {
              label: 'BACK',
              name: 'back',
            },
            addStakeholder: {
              label: 'ADD STAKEHOLDER',
              name: 'addStakeholder',
            },
            addAnotherStakeholder: {
              label: 'ADD ANOTHER STAKEHOLDER',
              name: 'addAnotherStakeholder',
            },
            addBussinessPartner: {
              label: 'ADD BUSINESS PARTNER',
              name: 'addBussinessPartner',
            },
            addAnotherBussinessPartner: {
              label: 'ADD ANOTHER BUSINESS PARTNER',
              name: 'addAnotherBussinessPartner',
            },
            addProfessionalDetails: {
              label: 'ADD ANOTHER PROFESSIONAL DETAILS',
              name: 'addProfessionalDetails',
            },
            addTaxDetails: {
              label: 'ADD ANOTHER TAX DETAILS',
              name: 'addProfessionalDetails',
            },
            addAnotherCountry: {
              label: 'ADD ANOTHER COUNTRY',
              name: 'addAnotherCountry',
            },
            addAnotherRemitter: {
              label: 'ADD ANOTHER Remitter',
              name: 'addAnotherRemitter',
            },
            addIntendedUse: {
              label: 'ADD ANOTHER INTENDED USE',
              name: 'addIntendedUse',
            },
            addAnotherBeneficiary: {
              label: 'ADD ANOTHER BENEFICIARY',
              name: 'addAnotherBeneficiary',
            },
            addAnotherIndustryCode: {
              label: 'ADD ANOTHER INDUSTRY CODE',
              name: 'addAnotherIndustryCode',
            },
          },
        },
      },
      customerPaymentProfile: {
        walletBalanceTitle: 'Wallet balance',
        feedYourWalletBanner: {
          title: 'Unsure about how to feed your wallet?',
          beginStepsText:
            'To add funds to your virtual account, follow these steps:',
          endStepsText:
            'Once the funds are credited, your account balance will update automatically.',
        },
        infoTipSection: {
          title: 'Why is my balance not updated',
          text: `If you've made a top-up, it may not appear immediately due to transfer processing times. Your balance will be automatically updated once the funds are received and processed.`,
        },
        virtualAccountInfoCard: {
          title: 'Virtual account',
          detailsButton: 'DETAILS',
        },
        virtualAccountDetailsModal: {
          title: 'Virtual account details',
          modalFields: {
            currency: 'Currency',
            virtualNumber: 'Virtual number',
            accountName: 'Account name',
            accountType: 'Account type',
            bankAddress: 'Bank address',
            bankName: 'Bank name',
            routingCodeTitle: 'Routing codes',
          },
        },
        createPaymentProfileBanner: {
          title:
            'Hello there, All payment services are exclusively provided by our trusted partner, NIUM, to ensure a smooth and reliable experience',
          canCreateQuestion: `Who Can Create a Payment Profile?`,
          companyRepresentative: `The HR Manager creating the payment profile must represent the company as an applicant.`,
          individualApplicant: `An applicant is the individual responsible for completing the NIUM application process. They act as the representative of the customer, providing the required details and documentation for onboarding.`,
          canAccessQuestion: 'Who Can Access the Payment Profile?',
          hrManager: `The HR Manager who creates the payment profile will be the only person with access to its details in the future.`,
          hrAdmin: `The Company HR Admin will also have access.`,
          checkCompanyadmin: `You can check who the Company HR Admin is by navigating to: Settings > Users`,
          acceptTerms: `By clicking on Create Payment Profile, you accept `,
          termsAndConditions: `NIUM's Terms & Conditions`,
          createButtonLabel: 'Create Payment Profile',
          resumeButtonLabel: 'Resume Payment Profile Creation',
        },
        paymentProfileStatusBanner: {
          title: 'Payment profile request',
          reviewSubTitle: `Your application is currently under review with NIUM, our payment provider. You will be notified once the review is complete, and your payment profile is created successfully. If any additional information is required, NIUM or our support team will reach out to you via email.`,
          rejectedSubTitle: `Please be aware that creating your payment profile is necessary before you can generate your initial work statement.`,
          companyRepresentative: `The HR Manager creating the payment profile must represent the company as an applicant.`,
        },
        KYCBanner: {
          title:
            'Thank You! Your Payment Profile Application Has Been Sent Successfully',
          subTitle: `Thank you for completing the application process! Your payment profile application has been successfully submitted.`,
          nextStep: `Next Steps: Complete the Verification Process (KYB)`,
          finalizeStep: `To finalize your setup, the applicant must now complete the Know Your Business (KYB) verification.`,
          processTakes: `This process takes `,
          period: '10-15 minutes ',
          whatsHappen: `and happens outside the `,
          provideDocuments: `The applicant (the person who submitted the NIUM application) is responsible for providing the required details and documentation.`,
          submitButtonLabel: 'Complete Verification',
        },
        successfulCreationBanner: {
          title: 'Payment Profile Created Successfully',
          subTitle: `Your payment profile has been successfully created. However, you do not have access to view its details.`,
          accessQuestion: `Who Can Access the Payment Profile?`,
          hrManager: `The HR Manager who created the payment profile.`,
          hrAdmin: `The Company HR Admin.`,
          needAssistance:
            'If you need assistance or access, please contact your Company HR Admin.',
          checkHrAdmin: `You can check who the Company HR Admin is by navigating to: Settings > Users`,
          furtherQuestions: `For further questions, please reach out to us `,
        },
      },
    },
    viewContractor: {
      signatureBanner: {
        title: 'Hello there,',
        content: `To avoid delays, ensure to upload a signed contract with your contractor before the contract's start date, ensuring both parties sign it beforehand.`,
        action: 'UPLOAD NOW',
      },
      tabs: {
        contractInfoLabel: 'Contract info',
        basicInfoLabel: 'Basic info',
        loginInfoLabel: 'Login info',
        bankDetailsLabel: 'Bank info',
        documentsLabel: 'Documents',
        workStatementsLabel: 'Work statements',
        paymentsLabel: 'Payments',
        historyLabel: 'History',
        expensesLabel: 'Expenses',
        holidaysLabel: 'Time Off',
        payslips: 'Payslips',
        Invoices: 'Invoices',
        onboardingData: 'Onboarding Data',
      },
      contractInfo: {
        contractDetails: {
          title: 'contract details',
          contractStartDate: 'Contract start date',
          employmentDuration: 'Employment duration',
          contractEndDate: 'Contract end date',
          contractType: 'Contract Type',
          eligibleForReimbursements: 'Is talent eligible for reimbursements?',
          eligibleToWorkFromHome: 'Is talent eligible to work from home?',
          employmentType: 'Employment type',
          jobDescription: 'Job description',
          jobTitle: 'Job title',
          scopeOfWork: 'Scope of work',
          areaOfExpertise: 'Contractor area of expertise',
          clientNoticePeriod: 'Client notice period',
          contractorNoticePeriod: 'Contractor notice period ',
          serviceSector: 'Service sector',
          talentIsLocal: 'Is talent local?',
          talentIsSenior: 'Is talent senior?',
          workingHoursPerWeek: 'Working hours per week',
          workAddress: 'The address the talent will work from',
          costCenter: 'Cost Center/Invoice Reference',
          fullTime: 'Full time',
          partTime: 'Part time',
          permanent: 'Permanent',
          fixedTerm: 'Fixed-term',
          placeHolders: {
            jobDescription: 'Write job Description',
            jobTitle: 'Write job Title',
            workingHoursPerWeek: 'Write working hours',
            workAddress: 'Write address the talent will work from',
            costCenter: 'Write cost Center/Invoice Reference',
            contractStartDate: 'Start Date',
            contractEndDate: 'End Date',
          },
          payoutFrequencyOptions: {
            yearly: 'YEARLY',
            halfYearly: 'Half-Yearly',
            quarterly: 'Quarterly',
            monthly: 'Monthly',
          },
          healthInsurancePlans: {
            standard: 'STANDARD',
            premium: 'PREMIUM',
            premiumPlusMaternity: 'PREMIUM PLUS MATERNITY',
          },
          salaryFrequencyOptions: {
            monthly: 'MONTHLY',
            annual: 'ANNUAL',
          },
        },
        contractSignatoryInfo: {
          title: 'contract signatory info',
          signatoryName: 'Signatory name',
          signatoryEmail: 'Signatory email',
          hasProbationPeriod: 'Talent has a probation period?',
          probationPeriod: 'Probation period',
          probationPeriodFrequency: 'Probation Period frequency',
          terminationNoticePeriod: 'Termination notice period',
          terminationNoticePeriodFrequency:
            'Termination Notice Period frequency',
          anythingElse: 'Other details',
          placeHolders: {
            signatoryName: 'write signatory name',
            signatoryEmail: 'write signatory email',
            terminationNoticePeriod: 'Write Termination Notice Period',
          },
        },
        localVatRegestirationInfo: {
          title: 'Local VAT Registration Info',
          loaclVatRegistrationNumber: 'Local VAT registration Number/ID',
          localVatDocument: 'Local vat registration document',
        },
        salaryData: {
          title: 'salary calculator',
          employeeGrossSalary: 'Base salary',
          grossSalaryType: 'Gross salary frequency',
          variableBonus: 'Variable bonus',
          variableBonusFrequency: 'Variable bonus frequency',
          variableBonusStartDate: 'Variable bonus start date',
          annualBonus: 'Annual bonus',
          annualBonusFrequency: 'Annual bonus frequency',
          signOnBonus: 'Sign on bonus',
          allowances: 'Annual allowances',
          allowancesFrequency: 'Annual allowances frequency',
          allowancesStartDate: 'Allowances payroll start date',
          bonusAnythingElse: 'Other Bonus',
          ageRange: 'Talent Age Range (Years)',
          insurancePlan: 'Insurance Plan',
          employeeStockOwnershipPlanProvided:
            'Does the talent has stock ownership plan provided?',
          talentHasPrivateHealthInsurance:
            'Talent has private health insurance',
          currency: 'Currency',
          placeholders: {
            currency: 'Write currency',
            grossSalary: 'Write gross salary',
            annualBonus: 'Write annual bonus',
            variableBonus: 'Write variable bonus',
            signOnBonus: 'Write sign on bonus',
            allowances: 'Write annual allowances',
          },
        },
      },
      basicInfo: {
        title: 'Basic Info',
        personalDetails: {
          title: 'personal details',
          firstName: 'First name',
          lastName: 'Last name',
          mobileNumber: 'Mobile number',
          email: 'Email',
          gender: 'Gender',
          dateOfBirth: 'Date of birth',
          nationality: 'Nationality',
          maritalStatus: 'Marital status',
          numberOfChildren: 'Number of children',
          emergencyContact: {
            fullName: 'Emergency contact full name',
            mobileNumber: 'Emergency contact Mobile number',
            relation: 'Emergency contact relation',
          },
          placeholders: {
            firstName: 'Write first name',
            lastName: 'Write last name',
            mobileNumber: 'write mobile number',
            email: 'Write email',
            nationality: 'Write nationality',
            numberOfChildren: 'Write number of children',
            emergencyContact: {
              fullName: 'Write emergency contact full name',
              mobileNumber: 'Write emergency contact Mobile number',
              relation: 'Write emergency contact relation',
            },
            dateOfBirth: 'Write date of birth',
          },
          genderOptions: {
            male: 'Male',
            female: 'Female',
            other: 'Other',
          },
          martialStatusOptions: {
            married: 'Married',
            single: 'Single',
            divorced: 'Divorced',
            registeredPartnership: 'Registered Partnership',
          },
        },
        residingAddress: {
          title: 'Residing address',
          addressLine1: 'Address line 1',
          addressLine2: 'Address line 2',
          cityState: 'City / State',
          countryName: 'Country',
          postalCode: 'Postal code',
          placeholders: {
            title: 'Write residing address',
            addressLine1: 'Write Address line 1',
            addressLine2: 'Write address line 2',
            cityState: 'Write City / State',
            countryName: 'Write country',
            postalCode: 'Write postal code',
          },
        },
        officialInfo: {
          title: 'Official info',
          passportNumber: 'Passport / ID number',
          highestQualificationLevel: 'Highest qualification level',
          passportIssueDate: 'Passport issue date',
          countryOfResidence: 'Country of residence',
          taxIdNumber: 'Tax ID number',
          workPermit: {
            expiryDate: 'Work permit expiry date',
            document: 'Work permit document',
          },
          highestQualificationLevelOptions: {
            primaryEducation: 'Primary Education',
            secondaryEduction: 'Secondary Education',
            vocationalEducation: 'Vocational Education',
            bachelor: 'Bachelor',
            master: 'Master',
            doctorate: 'Doctorate',
          },
          healthInsuranceType: {
            statutory: 'Statutory',
            private: 'Private',
          },
          placeholders: {
            title: 'Write official info',
            passportNumber: 'Write passport number',
            highestQualificationMajor: 'Write highest qualification major',
            socialSecurityNumber: 'Write social security number',
            taxIdNumber: 'Write tax ID number',
            dniNumber: 'Write DNI/NIE',
            nationalInsuranceNumber: 'Write national insurance number',
            sefResidentCardNumber: 'Write SEF resident card number',
            nifNumber: 'Write NIF number',
            taxReimbursementRuling:
              'Write if talent is eligible for so called 30% tax ruling',
            codiceFiscale: 'Write codice fiscale',
            peselNumber: 'Write PESEL number',
            healthInsuranceType: 'Write health insurance type',
            healthInsuranceProviderName: 'Write health insurance provider name',
            panCardNumber: 'Write PAN Card No.',
            uanNumber: 'Write UAN number',
            aadharCardNumber: 'Write AADHAR Card No.',
            oibPinNumber: 'Write OIB (PIN) number',
          },
        },
        countrySpecificInfo: {
          dniNumber: 'DNI/NIE',
          nationalInsuranceNumber: 'National insurance number',
          sefResidentCardNumber: 'SEF Resident Card Number',
          nifNumber: 'NIF number',
          taxReimbursementRuling: 'Eligible for the 30% tax ruling',
          codiceFiscale: 'Codice Fiscale',
          peselNumber: 'PESEL Number',
          healthInsuranceType: 'Type of Health Insurance',
          healthInsuranceProviderName: 'Health Insurance Provider',
          panCardNumber: 'PAN Card No.',
          uanNumber: 'UAN number',
          aadharCardNumber: 'AADHAR Card No.',
          oibPinNumber: 'OIB (PIN) number',
        },
        emeregencyContact: {
          title: 'Emergency contact',
          fullName: 'Emergency contact full name',
          mobileNumber: 'Emergency contact phone number',
          relation: 'Relation to emergency contact person',
        },
      },
      bankDetails: {
        title: 'Beneficiary details',
        actions: {
          add: 'Add',
          edit: 'Edit',
        },
        infoTip: {
          text: 'Please make sure to provide us your beneficiary details for payments & transactions.',
        },
        accountHolder: 'Account holder',
        bankName: 'Bank name',
        IBAN: 'IBAN / ABA routing number',
        accountNumber: 'Account number',
        addBeneficiaryModal: {
          header: 'Add beneficiary details',
          buttons: {
            submit: 'Submit',
            cancel: 'Cancel',
          },
          addBeneficiarySuccessMessage:
            'Beneficiary details added successfully',
          addBeneficiaryErrorMessage: 'Failed to add beneficiary details',
          fields: {
            payoutMethod: {
              name: 'payoutMethod',
              label: 'Payout method*',
              placeholder: 'Select payout method',
            },
            paymentCurrency: {
              name: 'currencyCode',
              label: 'Destination currency*',
              placeholder: 'Select payment currency',
            },
            destinationCountry: {
              name: 'destinationCountry',
              label: 'Destination country*',
              placeholder: 'Select destination country',
            },
          },
        },

        swiftCode: 'Swift code',
        countrySpecificInfo: {
          bankAccountNumber: 'Bank Account Number',
          bankAddress: 'Complete address of the bank branch',
        },
        placeholders: {
          accountHolder: 'Write bank account holder',
          bankName: 'Write bank name',
          IBAN: 'Write IBAN',
          swiftCode: 'Write Swift code',
          accountNumber: 'Write bank account number',
        },
      },
      documents: {
        contractTitle: 'Contract',
        otherDocumentsTitle: 'Other documents',
        localVATDocumentsTitle: 'Local VAT registration document',
        uploadAction: 'UPLOAD',
        noDocumentScreen: {
          title: 'No documents found',
        },
        uploadDocumentSuccessMessage: 'Document uploaded successfully',
        uploadDocumentErrorMessage: 'Uploading document failed!',
        uploadDocumentSizeErrorMessage:
          'Something went wrong. Check file type and size (max. 10 MB)',
      },
      payments: {
        title: 'Payments',
        paymentsTable: {
          type: 'type',
          amount: 'annual amount',
          frequency: 'frequency',
          startMonth: 'start month',
          endMonth: 'end month',
        },
        noPaymentsInfoScreen: {
          title: 'No payments found',
          subtitle: 'Here where you will find payments list',
        },
      },
      editSection: {
        successMessage: 'Talent data updated successfully',
        edit: 'Edit',
        submit: 'Submit',
        cancel: 'Cancel',
      },
      infoSidebar: {
        contractInfoSection: {
          noContractInfoScreen: {
            title: 'No contract info provided yet',
            subTitle:
              'You will find the contract info here once the employee completes their profile.',
          },
        },
        errorGetStatus: 'Failed to fetch employee statuses!',
        errorChangeStatus: 'Failed to update employee status!',
        errorOnboardingProductType:
          'Failed to change employee onboarding type!',
        employeeStatusChangedSuccessfully:
          'Employee status changed successfully',
        employeeOnboardingProductTypeChangedSuccessfully:
          'Employee onboarding product type changed successfully',
        platform: 'Platform',
        transfer: 'Transfer',
        noEmailMessage: 'No email to show',
        contractInfo: 'Contract info',
        ContractorInfo: 'Contractor info',
        holidayLabel: 'Holiday',
        importedLabel: 'Imported',
        noticeLabel: 'Notice',
        requiredEmailMessage: 'Email is required',
        validEmailMessage: 'Enter a valid email',
        requiredDateMessage: 'Date is required',
        saveButton: 'Save',
        cancelButton: 'Cancel',
        endDateInfoSection: {
          endDateTitle: 'Employment end date',
          changeButton: 'Change',
          noDateMessage: 'No end date',
          updateEndDateSuccessMessage:
            'The employment end date has been updated successfully!',
          updateEndDateErrorMessage:
            'Failed to update the employment end date!',
        },
        contactInfoSection: {
          contactInfo: 'Contact Info',
          noPhoneMessage: 'No phone number to show',
          noEmailMessage: 'No email to show',
          changeButton: 'Change',
          cancelButton: 'Cancel',
          saveButton: 'Save',
          contactInfoPlaceholder: 'Contact email',
          updateContactSuccessMessage:
            "The employee's information has been updated successfully!",
          updateContactErrorMessage: 'Failed to update employee contact!',
          noContractInfoScreen: {
            title: 'No contract info provided yet',
            subTitle:
              'You will find the contract info here once the employee completes their profile.',
          },
        },
        partnerInfoSection: {
          partnerInfo: 'Partner Info',
          noEmailMessage: 'No email to show',
          noPartnerMessage: 'No partner to show',
          updatePartnerSuccessMessage:
            'Employee partner was updated successfully!',
          updatePartnerErrorMessage: 'Failed to update employee partner!',
          getPartnersErrorMessage: 'Failed to get employee partner!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
        },
        assignedHRInfoSection: {
          assignedHrManagerInfo: 'HR manager info',
          noEmailMessage: 'No email to show',
          noAssignedHrManagerMessage: 'No manager to show',
          updateAssignedHrSuccessMessage:
            'Employee assigned Hr manager has been updated successfully!',
          updateAssignedHrErrorMessage:
            'Failed to update employee assigned hr manager!',
          getCompanyHrManagersErrorMessage:
            'Failed to get company hr managers!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
        },
        assignedSubCompanySection: {
          assignedEntityInfo: 'Sub Entity',
          noEmailMessage: 'No sub entity to show',
          noAssignedEntityMessage: 'No sub entity to show',
          updateAssignedSubEntitySuccessMessage:
            'Employee assigned sub enitiy has been updated successfully!',
          updateAssignedSubEntityErrorMessage:
            'Failed to update employee assigned sub entity!',
          getCompanySubEntitesErrorMessage: 'Failed to get sub entites!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
          deleteButton: 'Delete',
        },
        managerInfoSection: {
          managerContact: 'Line Manager ',
          managerEmailPlaceholder: 'Manager email',
          updateManagerSuccessMessage:
            'Employee manager was updated successfully!',
          updateManagerErrorMessage: 'Failed to update employee manager!',
          saveButton: 'Save',
          changeButton: 'Change',
          cancelButton: 'Cancel',
          deleteButton: 'Delete',
        },
        downloadsSection: {
          downloads: 'Downloads',
          partnerProjectAgreement: 'Partner Project Agreement',
          projectAgreementSummary: 'Project Agreement Summary',
          downloadDataSheetErrorMessage:
            'Failed to download the Application Data Sheet!',
          downloadSummarySuccessMessage:
            'The Application Summary is downloaded successfully',
          downloadDataSheetSuccessMessage:
            'The Application Data Sheet is downloaded successfully!',
          downloadSectionError: 'Failed to get downloads',
        },
        offboardingModal: {
          modalTitle: 'Talent offboarding',
          offboardingType: {
            label: 'Offboarding type',
            required: 'Offboarding type is required',
            invalid: 'Offboarding type is invalid',
            placeholder: 'Offboarding type',
          },
          offboardingDate: {
            label: 'Employment end date',
            required: 'Employment end date is required',
            invalid: 'Employment end date is invalid',
          },
          additionalComments: {
            label: 'Additional comments',
            placeholder: 'Additional comments',
          },
          messages: {
            submitError: 'Failed to update offboarding reason',
          },
          infoTipMessage: {
            futureDate:
              'To be able to change talent status to offboarding, please choose an employment end date in the future',
            pastDate:
              'To be able to change talent status to contract ended, please choose an employment end date in the past',
          },
        },
      },
      noBankDetailsScreen: {
        title: 'No bank details provided yet',
        subTitle:
          'You will find the bank details here once the employee completes their profile.',
        infoTip: {
          title: 'Editing/Changing Bank Info:',
          text: 'To edit/change your bank details please submit a ticket [here](https://helpdesk.workmotion.com/s/).',
        },
      },
      noBasicInfoScreen: {
        title: 'Your Employee has not provided their details yet',
        subTitle:
          'You will find the basic details once the employee creates their profile here.',
      },
    },
    deleteModal: {
      title: 'Delete confirmation',
      subtitle:
        'Are you sure you want to delete {{name}}? This process cannot be undone.',
      cancelButton: 'Discard',
      deleteButton: 'Confirm',
      deleteSuccessMessage: 'Deleted successfully!',
      deleteErrorMessage: enSomethingWentWrong,
    },
  },
};
