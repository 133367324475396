import React, { InputHTMLAttributes, useCallback } from 'react';

import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  placeholder?: string;
  hasError?: boolean;
  inputAddon?: string;
  disableScroll?: boolean;
  newTheme?: boolean;
  disabled?: boolean;
}

const defaultProps: InputProps = {
  type: 'text',
  placeholder: '',
  hasError: false,
  inputAddon: undefined,
};

const StyledInput = styled.input<InputProps>`
  height: 2.5rem;
  background: #fff;
  border: 1px solid #acb9c6;
  border-radius: 5px;
  flex: 1;
  width: 100%;
  padding: 12px 16px;
  outline: 0;
  color: #00234b;
  font-size: 0.75rem;

  :hover,
  :focus,
  :active {
    border-color: ${palette.primary.genericViridian};
  }

  &[data-has-error='true'] {
    :hover,
    :focus,
    :active {
      border-color: #ff1744;
    }

    border-color: #ff1744;
  }

  ::placeholder {
    color: ${palette.greyscale.sharkMouth};
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const LeftAddon = styled.span`
  font-size: 16px;
  color: #9da4be;
  display: inline-flex;
  align-items: center;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 12px;
`;

const AddonInputContainer = styled.div`
  position: relative;
`;

const InputField: React.FC<InputProps> = ({
  name,
  type,
  hasError,
  disableScroll = true,
  newTheme,

  ...props
}: InputProps) => {
  const onWheel = useCallback(
    event => {
      if (disableScroll) {
        event.currentTarget.blur();
      }
    },
    [disableScroll]
  );

  const { inputAddon } = props;

  if (inputAddon && type === 'number') {
    return (
      <AddonInputContainer>
        <LeftAddon>{inputAddon}</LeftAddon>

        <StyledInput
          name={name}
          type={type || 'text'}
          data-has-error={hasError}
          onWheel={onWheel}
          onScroll={onWheel}
          {...props}
          style={{ paddingLeft: '54px' }}
        />
      </AddonInputContainer>
    );
  }

  return (
    <StyledInput
      name={name}
      onWheel={onWheel}
      onScroll={onWheel}
      type={type || 'text'}
      data-has-error={hasError}
      data-update-style={newTheme}
      {...props}
    />
  );
};

InputField.defaultProps = defaultProps;

const StyledFieldContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: 40,
});

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const StyledActionButtons = styled(StyledFieldContainer)`
  padding-top: 41px;
  border-top: 1px solid #dee1e4;
  justify-content: center;
  margin: 0;
`;

export {
  InputField,
  StyledFieldContainer,
  StyledContainer,
  StyledActionButtons,
};
