import React, { useEffect, useMemo } from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { useSearchParams } from 'react-router-dom';

import { useSubmitKycResult } from 'networking/contractor-payment-api/contractor-payment-mutations';
import { useGetPaymentProfileStatus } from 'networking/contractor-payment-api/contractor-payment-network-requests';
import { PageLoadingSpinner } from 'shared-components/page-loading-spinner';
import { KycResultRequest } from 'types/contractor-payment';

import { CustomerPaymentProfile } from './customer-payment-profile';
import { CreatePaymentProfileBanner } from './payment-banner/create-payment-profile-banner';
import { KYCBanner } from './payment-banner/kyc-banner';
import { PaymentProfileStatusBanner } from './payment-banner/payment-profile-status-banner';

export const PaymentProfile = () => {
  const {
    userIdentityInfo: { companyId },
  } = useUserIdentity();

  const [searchParams] = useSearchParams();

  const queryParams: KycResultRequest = useMemo(
    () =>
      searchParams.toString()
        ? {
            clientId: searchParams.get('clientId'),
            caseId: searchParams.get('caseId'),
            isSuccess: searchParams.get('isSuccess') === 'true',
            errorCode: searchParams.get('errorCode'),
            errorMessage: searchParams.get('errorMessage'),
          }
        : undefined,
    [searchParams]
  );

  const { mutate: submitKycResult, isLoading: isSubmitKycResultLoading } =
    useSubmitKycResult(companyId);

  const {
    data: paymentProfileStatus,
    isLoading: isPaymentProfileStatusLoading,
    isFetching,
  } = useGetPaymentProfileStatus(companyId);

  const status = paymentProfileStatus?.status || 'NOT_CREATED';

  useEffect(() => {
    if (
      queryParams?.clientId &&
      queryParams?.caseId &&
      queryParams?.isSuccess !== undefined
    ) {
      const filteredQueryParams = Object.fromEntries(
        Object.entries(queryParams).filter(([_, value]) => value !== null)
      );

      submitKycResult(filteredQueryParams as KycResultRequest, {
        onSuccess: () => {
          const newUrl = window.location.origin + window.location.pathname;
          window.history.replaceState({}, document.title, newUrl);
        },
      });
    }
  }, [queryParams, submitKycResult]);

  if (isPaymentProfileStatusLoading || isFetching || isSubmitKycResultLoading) {
    return <PageLoadingSpinner />;
  }

  return (
    <>
      {(status === 'NOT_CREATED' || status === 'CREATION_STARTED') && (
        <CreatePaymentProfileBanner status={status} />
      )}

      {status === 'SUBMITTED' && <KYCBanner companyId={companyId} />}

      {status === 'COMPLETED' && <CustomerPaymentProfile />}

      {(status === 'REJECTED' || status === 'IN_REVIEW') && (
        <PaymentProfileStatusBanner status={status} />
      )}
    </>
  );
};
