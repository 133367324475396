import React, { RefObject, useEffect, useRef } from 'react';

import { Box, SxProps } from '@mui/material';
import { ControlledMenu, MenuItem, MenuState } from '@szhsin/react-menu';
import { menuSelector, menuItemSelector } from '@szhsin/react-menu/style-utils';
import styled from 'styled-components';
import { palette, Spinner } from 'workmotion-design-system';

import { useElementOnScreen } from 'helpers/use-element-on-screen';
import { en } from 'localization';
import { ContractorBaseResponse } from 'types/contractor';

import { ContractorMenuItem } from './contractor-menu-item';

const Menu = styled(ControlledMenu)({
  [menuSelector.name]: {
    minHeight: 40,
    maxHeight: 240,
    overflowY: 'auto',
  },
  [menuItemSelector.name]: {
    height: 40,
  },
});

export const menuItemStyles = {
  fontSize: '0.75rem',
  fontFamily: 'Work Sans',
  color: palette.greyscale.UCLABlue,
  padding: '.68rem 1rem',
  width: '15.5rem',
};

const spinnerContainer: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '15.5rem',
  padding: '1rem',
};

interface SearchResultsMenuProps {
  anchorRef: RefObject<HTMLDivElement> | null;
  menuState: MenuState;
  results: ContractorBaseResponse[] | undefined;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  handleSelectContractor: (contractor: ContractorBaseResponse) => void;
  hasNextPage?: boolean;
  getNextPage?: () => void;
}

const SearchResultsMenu: React.FC<SearchResultsMenuProps> = ({
  anchorRef,
  menuState,
  results,
  isLoading,
  isFetchingNextPage,
  handleSelectContractor,
  hasNextPage,
  getNextPage,
}) => {
  const {
    generic: {
      sharedComponents: {
        contractorSearch: { noContractorFound },
      },
    },
  } = en;

  const menuBoxRef = useRef();

  const { elementRef: lastContractorMenuItemRef, isVisible } =
    useElementOnScreen<HTMLDivElement>({
      root: menuBoxRef?.current,
    });

  useEffect(() => {
    if (isVisible && hasNextPage) {
      getNextPage();
    }
  }, [getNextPage, hasNextPage, isVisible]);

  return (
    <Menu
      anchorRef={anchorRef}
      state={menuState}
      ref={menuBoxRef}
      captureFocus={false}
    >
      {!!results && !results.length && !isLoading && (
        <MenuItem disabled styles={menuItemStyles}>
          {noContractorFound}
        </MenuItem>
      )}

      {!!results?.length &&
        results.map((el, index, arr) => (
          <ContractorMenuItem
            itemRef={
              index === arr.length - 1 ? lastContractorMenuItemRef : null
            }
            key={el.id}
            contractor={el}
            handleSelectContractor={handleSelectContractor}
          />
        ))}

      {(isLoading || isFetchingNextPage) && (
        <Box sx={spinnerContainer}>
          <Spinner data-testid="contractors-loading" size={32} />
        </Box>
      )}
    </Menu>
  );
};

export { SearchResultsMenu };
