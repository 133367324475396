import React, { HTMLAttributes } from 'react';

import styled from 'styled-components';
import { Spinner } from 'workmotion-design-system';

export const StyledLoadingSpinnerContainer = styled.div({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const PageLoadingSpinner: React.FC<
  HTMLAttributes<HTMLDivElement>
> = props => (
  <StyledLoadingSpinnerContainer {...props}>
    <Spinner />
  </StyledLoadingSpinnerContainer>
);
