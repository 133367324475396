import React from 'react';

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ErrorFallback } from 'workmotion-design-system';

const ErrorBoundaryWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <SentryErrorBoundary fallback={<ErrorFallback />}>
    {children}
  </SentryErrorBoundary>
);

export { ErrorBoundaryWrapper };
