import { RawAxiosRequestHeaders } from 'axios';
import { Document, OpenAPIClientAxios } from 'openapi-client-axios';

import { Client } from 'networking/contractor-payment-api/contractor-payment-client-types';

import ClientDefinition from './contractor-payment-contract.json';

export async function createClient(
  accessToken: string,
  { isMultiPart = false } = {}
) {
  if (accessToken == null) {
    return;
  }
  let headers: RawAxiosRequestHeaders = {
    'Content-Type': 'application/json',
  };

  headers = {
    ...headers,
    Authorization: `Bearer ${accessToken}`,
  };

  if (isMultiPart) {
    headers = {
      ...headers,
      'Content-Type': 'multipart/form-data',
    };
  }
  const virtualServerBaseURL = process.env.VIRTUAL_SERVER_URL;

  const api = new OpenAPIClientAxios({
    definition: ClientDefinition as Document,
    axiosConfigDefaults: {
      headers,
      baseURL: window.env.API_BASE_URL || virtualServerBaseURL,
      /**
       * We need these parameters to solve internal library issues.
       * Check for version updates in order to fix it
       */
      transitional: {},
    },
  });

  await api.init();

  return api.getClient<Client>();
}
