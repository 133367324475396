import React, { useCallback } from 'react';

import { Select } from 'workmotion-design-system';

import { en } from 'localization';
import { filtersSelectStyles } from 'work-statement/styled-components';

interface StatusFilterProps {
  value: string;
  setStatus: React.Dispatch<React.SetStateAction<StatusFilterEnum>>;
}

export enum StatusFilterEnum {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  INITIATED = 'INITIATED',
  PAID = 'PAID',
  FAILED = 'FAILED',
}

const {
  invoiceSubmission: {
    table: {
      statusFilter: {
        placeHolder,
        options: {
          allStatusesTypes,
          draftLabel,
          pendingLabel,
          approvedLabel,
          rejectedLabel,
          initiatedLabel,
          paidLabel,
          failedLabel,
        },
      },
    },
  },
} = en;

const statusOptions = [
  {
    label: allStatusesTypes,
    value: StatusFilterEnum.ALL,
  },
  {
    label: draftLabel,
    value: StatusFilterEnum.DRAFT,
  },
  {
    label: pendingLabel,
    value: StatusFilterEnum.PENDING,
  },
  {
    label: approvedLabel,
    value: StatusFilterEnum.APPROVED,
  },
  {
    label: rejectedLabel,
    value: StatusFilterEnum.REJECTED,
  },
  {
    label: initiatedLabel,
    value: StatusFilterEnum.INITIATED,
  },
  {
    label: paidLabel,
    value: StatusFilterEnum.PAID,
  },
  {
    label: failedLabel,
    value: StatusFilterEnum.FAILED,
  },
];

const StatusFilter: React.FC<StatusFilterProps> = ({ value, setStatus }) => {
  const onChange = useCallback(
    option => {
      setStatus(option as StatusFilterEnum);
    },
    [setStatus]
  );

  return (
    <Select
      sx={{ ...filtersSelectStyles, marginBottom: '1rem' }}
      options={statusOptions}
      value={value}
      onChange={onChange}
      placeholder={placeHolder}
      attrs={{
        select: { id: 'invoice-submission-status-filter-select' },
        input: {
          id: 'invoice-submission-status-filter-input',
        },
      }}
    />
  );
};

export { StatusFilter };
